import React from "react";
import isEmpty from "lodash/isEmpty";
import styled from "@emotion/styled";

import media from "utils/media";

import Markdown from "components/elements/Markdown";
import { Container, Row, Column } from "components/common/Grid";

import Section from "./Section";
import Feature from "./Feature";

export default function Features({ data }) {
  const { heading, lead, content, features = [] } = data;
  return (
    <Section>
      <Container>
        <Row>
          <Title>
            <h1>{heading}</h1>
            <p className="lead">{lead}</p>
          </Title>
          <StyledFeatures>
            {!isEmpty(features) &&
              features.map((item) => <Feature {...item} />)}
          </StyledFeatures>
          {!isEmpty(content) && (
            <Content>
              <Markdown>{content}</Markdown>
            </Content>
          )}
        </Row>
      </Container>
    </Section>
  );
}

const Title = styled(Column)`
  h1 {
    line-height: 1.1;
    margin-bottom: 20px;

    ${media.greaterThan("md")`
      margin-bottom: 0;
    `};
  }
`;

const StyledFeatures = styled(Column)`
  margin-top: 80px;
  margin-bottom: 100px;

  ${media.greaterThan("sm")`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  `};

  ${media.greaterThan("md")`
    margin-top: 75px;
    margin-bottom: 90px;
  `};
`;

const Content = styled(Column)`
  p {
    font-size: 26px;
    line-height: 1.5;
    letter-spacing: 0.2;
  }
`;
