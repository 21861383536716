import styled from "@emotion/styled";

import media from "utils/media";

const Section = styled.section`
  padding-top: 115px;
  padding-bottom: 150px;

  ${media.greaterThan("md")`
    padding-top: 130px;
    padding-bottom: 130px;
  `};
`;

export default Section;
