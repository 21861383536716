import React, { useEffect } from "react";

import Components from "components/components.js";
import { useStateValue } from "components/common/GlobalState";

export default function Tag(props) {
  const { blok, pageContext = {} } = props;
  const [globalState = {}, dispatch] = useStateValue();

  useEffect(() => {
    dispatch({
      type: "UPDATE_STATE",
      payload: {
        lang: pageContext.lang,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageContext.lang]);
  return (
    <>
      {blok.body &&
        blok.body.map((blok) => {
          if (Components[blok.component]) {
            return React.createElement(Components[blok.component], {
              key: blok._uid,
              data: blok,
              pageContext,
            });
          } else {
            return (
              <h1 key={blok._uid}>
                Component <span style={{ color: "red" }}>{blok.component}</span>{" "}
                not created yet
              </h1>
            );
          }
        })}
    </>
  );
}
