import styled from "@emotion/styled";
import media from "utils/media";

export const Footer = styled.div`
  margin-top: 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EntryDate = styled.span`
  opacity: 0.3 !important;
  transition: opacity 0.3s ease;
  font-family: ${({ theme }) => theme.fonts.headerFont};
  line-height: 1;
  font-size: 16px;
  margin: 0 0 2px 0;
`;

export const ReadMoreCaption = styled.span`
  transition: opacity 0.3s ease 0s;
  text-transform: ${(props) => (props.isNewsGrid ? "uppercase" : "none")};
  opacity: ${(props) => (props.isNewsGrid ? 0 : 1)};
  font-weight: ${(props) => (props.isNewsGrid ? "bold" : "normal")};
  font-family: ${(props) =>
    props.isNewsGrid ? "WhitneyNew, sans-serif" : "Gotham"};

  ${(props) =>
    props.isNewsGrid &&
    media.lessThan("sm")`
    opacity: 0.5;
  `};
`;
