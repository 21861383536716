import React from "react";

import { Container, Row, Column } from "components/common/Grid";
import useMatchMedia from "utils/hooks/useMatchMedia";

import Section from "./Section";
import Title from "./Title";
import MembersSlider from "./MembersSlider";
import MembersTable from "./MembersTable";

export default function Team_v2({ data }) {
  const { heading, subheading, members, section_id } = data || {};
  const isSmallScreen = useMatchMedia("(max-width: 991px)");

  return (
    <Section id={section_id}>
      <Container>
        <Row>
          <Title>
            <h2 className="h1">{heading}</h2>
            <p className="lead">{subheading}</p>
          </Title>
          <Column>
            {isSmallScreen ? (
              <MembersSlider members={members} />
            ) : (
              <MembersTable members={members} />
            )}
          </Column>
        </Row>
      </Container>
    </Section>
  );
}
