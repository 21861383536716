import React from "react";
import Swiper from "react-id-swiper";
import {
  Pagination,
  Navigation,
  Autoplay,
  EffectFade,
} from "swiper/dist/js/swiper.esm";

import Markdown from "components/elements/Markdown";
import Image from "components/elements/Image";

import { ReactComponent as ArrowLeft } from "assets/svg/arrow_left.svg";
import { ReactComponent as ArrowRight } from "assets/svg/arrow_right.svg";

import Wrapper from "./Wrapper";
import ImageWrapper from "./Image";
import { Content, Author, Position } from "./Content";

import { getFluidGatsbyImage } from "gatsby-storyblok-image";

export default function TestimonialSlider({
  testimonials,
  autoplay,
  delay,
  mobile,
}) {
  const parsedDelay = parseInt(delay, 10) || 5000;

  const params = {
    modules: [Pagination, Navigation, Autoplay, EffectFade],
    effect: "fade",
    autoplay: autoplay
      ? {
          delay: parsedDelay,
          disableOnInteraction: true,
        }
      : false,
    allowTouchMove: false,
    autoHeight: false,
    roundLengths: true,
    fadeEffect: {
      crossFade: true,
    },
    pagination: {
      el: ".swiper-pagination",
      type: "fraction",
    },
    navigation: {
      nextEl: ".swiper-next",
      prevEl: ".swiper-prev",
    },
    breakpoints: {
      768: {
        allowTouchMove: true,
      },
    },
    renderPrevButton: () => (
      <button className="swiper-prev" aria-label="Swipe back">
        <ArrowLeft />
      </button>
    ),
    renderNextButton: () => (
      <button className="swiper-next" aria-label="Swipe next">
        <ArrowRight />
      </button>
    ),
  };

  return (
    <Wrapper>
      <Swiper {...params}>
        {testimonials.map((item) => {
          const {
            _uid: id,
            image,
            mobile_image,
            content,
            author,
            position,
          } = item;

          const validatedMobileImage = mobile_image && mobile_image.image;

          if (mobile_image) {
            getFluidGatsbyImage(mobile_image, {
              quality: 80,
              smartCrop: false,
              maxWidth: 1400,
              downloadLocal: true,
            });
          }

          return (
            <div key={id}>
              {validatedMobileImage && mobile ? (
                <ImageWrapper>
                  <Image
                    fluid
                    data={mobile_image}
                    fadeIn
                    options={{ maxWidth: 1400 }}
                  />
                </ImageWrapper>
              ) : (
                <ImageWrapper>
                  <Image
                    fluid
                    data={image}
                    fadeIn
                    objectPosition="top center"
                    options={{ maxWidth: 1400 }}
                  />
                </ImageWrapper>
              )}
              {content && (
                <Content>
                  <Markdown>{content}</Markdown>
                  {author && <Author>{author}</Author>}
                  {position && <Position>{position}</Position>}
                </Content>
              )}
            </div>
          );
        })}
      </Swiper>
    </Wrapper>
  );
}
