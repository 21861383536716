import React from "react";
import styled from "@emotion/styled";
import { Box } from "@rebass/grid/emotion";

import media from "utils/media";

const StyledBox = styled(Box)`
  max-width: 100%;
  display: block;

  ${media.greaterThan("xs")`
    max-width: 33.75rem;
  `};

  ${media.greaterThan("sm")`
    max-width: 45rem;
  `};

  ${media.greaterThan("md")`
    max-width: 60rem;
  `};

  ${media.greaterThan("lg")`
    max-width: 77rem;
  `};
`;

const Container = (props) => <StyledBox {...props} />;

Container.defaultProps = {
  mx: "auto",
  px: 4,
};

export default Container;

// Easy way

// import React from "react";
// import styled from "@emotion/styled";
// import { Box } from "@rebass/grid/emotion";

// import media from "utils/media";

// const StyledBox = styled(Box)`
//   max-width: 100%;
//   display: block;

//   ${media("xs")} {
//     max-width: 33.75rem;
//   }
//   ${media("sm")} {
//     max-width: 45rem;
//   }
//   ${media("md")} {
//     max-width: 60rem;
//   }
//   ${media("lg")} {
//     max-width: 77rem;
//   }
// `;

// const Container = props => <StyledBox {...props} />;

// Container.defaultProps = {
//   mx: "auto",
//   px: 4
// };

// export default Container;
