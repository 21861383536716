import { ReactComponent as LogoUsa } from "assets/svg/logo_usa.svg";
import { ReactComponent as LogoIsrael } from "assets/svg/logo_israel.svg";
import { ReactComponent as LogoSea } from "assets/svg/logo_sea.svg";
import { ReactComponent as LogoChina } from "assets/svg/logo_china.svg";
import { ReactComponent as LogoGrowth } from "assets/svg/logo_growth.svg";
import { ReactComponent as LogoHealthCare } from "assets/svg/logo_healthcare.svg";

const SPACE = process.env.GATSBY_SB_SPACE;

const logos = {
  usa: LogoUsa,
  israel: LogoIsrael,
  sea: LogoSea,
  china: LogoChina,
  growth: LogoGrowth,
  healthcare: LogoHealthCare,
  holdings: LogoHealthCare,
};

export default {
  logo: logos[SPACE],
  // Colors
  colors: {
    brand: "#124E91",
    textColor: "#00294c",
    white: "#fff",
    black: "#000",
    blue_v1: "#007bf8",
    blue_v2: "#e0f3fa",
    blue_v3: "#F9FDFE",
    blue_v4: "#D2DFF4",
    blue_v5: "#043fbe",
    blue_v6: "#55b8e2",
    gray_v1: "#fafafa",
    gray_v2: "rgba(0, 41, 76, 0.5)",
    gray_v3: "#d8d8d8",
    headerColor: "#124E91",
    headerLogo: "#114C8D",
  },
  fontSizes: {
    testimonialMobile: "11px",
    testimonialTablet: "16px",
  },
  fonts: {
    headerFont: "STXihei, '华文细黑', serif",
    bodyFont: "STXihei, '华文细黑', serif",
  },
  // GRID
  space: [0, 5, 10, 15, 26, 40],
  breakpoints: ["36rem", "48rem", "62rem", "80rem"],
};
