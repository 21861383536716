import React from "react";
import SbEditable from "storyblok-react";

import componentsMap from "components/components.js";

export default function Page(props) {
  const { blok, lang, path } = props;

  return (
    <>
      {blok.body &&
        blok.body.map((blok) => {
          if (componentsMap[blok.component]) {
            return (
              <SbEditable content={blok} key={blok._uid}>
                {React.createElement(componentsMap[blok.component], {
                  key: blok._uid,
                  data: blok,
                  lang,
                  path,
                })}
              </SbEditable>
            );
          } else {
            return (
              <h1 key={blok._uid}>
                Component <span style={{ color: "red" }}>{blok.component}</span>{" "}
                not created yet
              </h1>
            );
          }
        })}
    </>
  );
}
