export default {
  langLabel: "JP",
  projectCategory: {
    all: "全て",
    active: "アクティブ",
    exited: "イグジット済み",
    internet: "internet",
    technology: "テクノロジー",
    consumer: "コンシューマー",
    healthcare: "ヘルスケア",
    fintech: "fintech",
    enterprise: "enterprise",
  },
};
