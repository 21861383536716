import styled from "@emotion/styled";

import media from "utils/media";

const SocialsWrapper = styled.div`
  position: sticky;
  width: 200px;
  bottom: 15px;
  z-index: 90;
  margin-left: auto;
  margin-right: -10px;

  ${media.greaterThan("xs")`
    margin-right: calc(-100vw / 2 + 33.75rem / 2 - 10px);
  `};

  ${media.greaterThan("sm")`
    margin-right: calc(-100vw / 2 + 45rem / 2 - 10px);
  `};

  ${media.greaterThan("md")`
    margin-right: calc(-100vw / 2 + 60rem / 2 - 120px);
  `};

  ${media.greaterThan("lg")`
    margin-right: calc(-100vw / 2 + 77rem / 2 - 250px);
  `};

  ${media.greaterThan("1500px")`
    margin-right: -266px;
  `};

  & > div {
    width: 200px;
    height: 240px;
    position: relative;

    button,
    .react-share__ShareButton {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 100;
      width: 60px;
      height: 60px;
      background-color: ${({ theme }) => theme.colors.blue_v2} !important;
      border-radius: 100%;
      box-shadow: 0 12px 20px -3px rgba(0, 41, 76, 0.2);
      margin: 25px;
      transition: opacity 0.3s ease;
      cursor: pointer;

      svg {
        fill: #130b46;
        width: 25px;
      }
    }

    button:first-of-type {
      right: 0;
      bottom: 0;
    }

    button:last-of-type,
    .react-share__ShareButton {
      opacity: ${({ open }) => (open ? 1 : 0)};
    }

    .react-share__ShareButton:nth-of-type(2) {
      right: 100px !important;
      bottom: 0 !important;
      border: 1px solid red;
      transition-delay: ${({ open }) => (open ? "0.1s" : "0.3s")};
      background-color: red;
    }

    .react-share__ShareButton:nth-of-type(3) {
      right: 70px;
      bottom: 70px;
      transition-delay: ${({ open }) => (open ? "0.2s" : "0.2s")};
    }

    button:last-of-type {
      right: 0;
      bottom: 100px;
      transition-delay: ${({ open }) => (open ? "0.3s" : "0.1s")};
    }
  }
`;

export default SocialsWrapper;
