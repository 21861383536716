import React from "react";
import isEmpty from "lodash/isEmpty";

import { Container, Row } from "components/common/Grid";
import Markdown from "components/elements/Markdown";
import Image from "components/elements/Image";

import Section from "./Section";
import Content from "./Content";
import MainImage from "./MainImage";
import Images from "./Images";
import AnimatedImages from "./AnimatedImages";

export default function AboutUs_v2({ data }) {
  const {
    heading,
    lead,
    content,
    images,
    main_image,
    is_animation = false,
    bg_bottomColor,
  } = data || {};
  const offset = images ? Math.ceil(images.length / 4) : 0;

  return (
    <Section id="about" offset={offset} bgBottom={bg_bottomColor}>
      <Container>
        <Row style={{ position: "relative" }}>
          <Content width={[1, 1, 3 / 4, 1 / 2]}>
            <h2>{heading}</h2>
            <p className="lead">{lead}</p>
            {content && <Markdown>{content}</Markdown>}
          </Content>
          {!isEmpty(main_image) && (
            <MainImage width={[1, 1, 1, 1 / 2]}>
              <Image fluid data={main_image} />
            </MainImage>
          )}
          {is_animation
            ? !isEmpty(images) && <AnimatedImages images={images} />
            : !isEmpty(images) && <Images images={images} />}
        </Row>
      </Container>
    </Section>
  );
}
