import styled from "@emotion/styled";

import { Column } from "components/common/Grid";

import media from "utils/media";

const Wrapper = styled(Column)`
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: ${({ bgColor }) => bgColor || "#FFF"};

    ${media.greaterThan("md")`
      width: calc(100% - 26px);
      left: 52px;
    `};

    ${media.greaterThan("lg")`
      width: calc(100% - 176px);
      left: 176px;
    `};

    ${media.greaterThan("1500px")`
      width: calc(100% - 202px);
    `};
  }

  ${media.greaterThan("xs")`
    width: 100vw;
    margin-left: calc(-100vw / 2 + 33.75rem / 2 + 9px);
    margin-right: calc(-100vw / 2 + 33.75rem / 2 + 9px);
  `};

  ${media.greaterThan("sm")`
    margin-left: calc(-100vw / 2 + 45rem / 2 + 9px);
    margin-right: calc(-100vw / 2 + 45rem / 2 + 9px);
  `};

  ${media.greaterThan("md")`
    margin-left: auto;
    margin-right: calc(-100vw / 2 + 60rem / 2 + 35px);
    flex-direction: row;
    text-align: left;
    padding: 0 26px 40px 66px;
    margin-top: 100px;
  `};

  ${media.greaterThan("lg")`
    margin-right: calc(-100vw / 2 + 77rem / 2 + 9px);
    padding: 0 26px 40px 216px;
  `};

  ${media.greaterThan("1500px")`
    width: 100%;
    margin-right: auto;
    padding: 0 52px 40px 216px;
  `};
`;

export default Wrapper;
