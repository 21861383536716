import React from "react";
import styled from "@emotion/styled";

import media from "utils/media";

import Image from "components/elements/Image";

export default function Member({ data, className, onClick, linear }) {
  const { _uid: id, name, position, image } = data || {};

  return (
    <StyledMember
      className={className}
      onClick={onClick}
      id={id}
      aria-label="Member"
      linear={linear}
    >
      <Image
        fluid
        data={image}
        options={{ maxWidth: 500, useBase64: false, quality: 90 }}
        objectPosition="center top"
      />
      <div>
        <p>{name}</p>
        <span>{position}</span>
      </div>
    </StyledMember>
  );
}

const StyledMember = styled.button`
  height: 290px;
  position: relative;
  text-align: left;
  z-index: 5;

  ${({ linear }) => media.greaterThan("md")`
    width: 33.3333%;
    padding-bottom: 26px;
    padding-left: 13px;
    padding-right: 13px;
    flex: 0 1 auto;

    &:nth-of-type(3n + 1) {
      padding-left: ${linear ? "13px" : "26px"};

      div:last-of-type {
        left: ${linear ? "37px" : "50px"};
        bottom: 50px;
        width: calc(100% - 62px);
      }
    }

    &:nth-of-type(3n + 2) {
      padding-top: ${linear ? "0" : "26px"};
      padding-bottom: ${linear ? "26px" : "0"};

      div:last-of-type {
        left: 37px;
        bottom: ${linear ? "50px" : "26px"};
        width: calc(100% - 50px);
      }
    }

    &:nth-of-type(3n + 3) {
      padding-right: ${linear ? "13px" : "26px"};

      div:last-of-type {
        left: 37px;
        bottom: 50px;
        width: calc(100% - 63px);
      }
    }
  `};

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.97;
    z-index: 1;
    background-image: linear-gradient(
      to bottom,
      rgba(216, 216, 216, 0),
      #000000
    );

    ${media.greaterThan("md")`
      display: none;
    `};
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;

    ${media.lessThan("md")`
      position: absolute !important;
      top: 0;
      left: 0;
      z-index: 0;
    `};

    ${media.greaterThan("md")`
      pointer-events: none;
    `};

    &:before {
      content: "";
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  div:last-of-type {
    position: absolute;
    left: 16px;
    bottom: 25px;
    z-index: 1;
    pointer-events: none;

    ${media.greaterThan("md")`
      opacity: 0;
      transition: .3s;
    `};

    p,
    span {
      font-family: ${({ theme }) => theme.fonts.headerFont};
      color: ${({ theme }) => theme.colors.white};
    }

    p {
      font-weight: bold;

      ${media.greaterThan("md")`
        font-size: 42px;
        margin-bottom: 17px;
        font-weight: 300;
        line-height: 1;
      `};
    }

    span {
      font-weight: 300;

      ${media.greaterThan("md")`
        font-size: 24px;
      `};
    }
  }

  &:hover {
    ${media.greaterThan("md")`
      div:last-of-type {
        opacity: 1;
        transition-delay: .1s;
      }

      .gatsby-image-wrapper {
        box-shadow: 0 12px 20px -3px rgba(0, 41, 76, 0.2);

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-blend-mode: multiply;
          background-image: linear-gradient(to bottom, rgba(0, 123, 248, 0.7), rgba(0, 123, 248, 0.7));
          z-index: 1;
          opacity: 1;

        }
      }
    `};
  }
`;
