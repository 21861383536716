import styled from "@emotion/styled";

import media from "utils/media";

const Content = styled.div`
  width: 75%;
  height: 100px;
  border: 28px solid #007bf8;
  transform: translate(0, -50px);
  margin-left: auto;
  background-color: #007bf8;
  color: ${({ theme }) => theme.colors.white};
  position: relative;
  overflow: hidden;

  ${media.greaterThan("xs")`
    height: 150px;
  `};

  ${media.greaterThan("sm")`
    height: 200px;
    width: 444px;
  `};

  ${media.greaterThan("md")`
    width: 450px;
    height: auto;
    min-height: 100px;
    border: 50px solid #007bf8;
    margin-top: -60px;
  `};

  ${media.greaterThan("lg")`
    width: 650px;
  `};

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  & > div > *,
  & > * {
    ${({ theme }) => media.lessThan("sm")`
      font-size: ${theme.fontSizes.testimonialTablet};
      line-height: 1.3;
   `};

    ${({ theme }) => media.lessThan("xs")`
      font-size: ${theme.fontSizes.testimonialMobile};
      line-height: 1.4;
    `};

    ${media.greaterThan("md")`
      line-height: 1.14;
   `};

    & + p {
      margin-top: 50px;
    }
  }

  p,
  span {
    font-family: ${({ theme }) => theme.fonts.headerFont};
  }

  img {
    max-width: 70px;
    display: block;
    float: right;
    margin: -10px -10px 0 10px;

    ${media.greaterThan("xs")`
      max-width: 70px;
    `};

    ${media.greaterThan("sm")`
      max-width: 80px;
    `};

    ${media.greaterThan("md")`
      max-width: 90px;
      margin: -20px -20px 0 20px;
    `};
  }
`;

const Author = styled.p`
  display: block;
  margin: 10px 0;
  font-size: 26px;
  font-weight: 300;
  line-height: 1.1;

  ${media.greaterThan("md")`
   font-weight: 500;
   margin-top: 39px;
  `};
`;

const Position = styled.span`
  line-height: 1;

  ${media.greaterThan("md")`
      font-size: 26px;
   `};
`;

export { Content, Author, Position };
