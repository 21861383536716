import styled from "@emotion/styled";

export const Quote = styled.p`
  font-size: 26px;
  font-family: ${({ theme }) => theme.fonts.headerFont};
  font-weight: 300;
  font-style: italic;
`;

export const Author = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  img {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    margin-right: 26px;
  }

  p {
    span {
      display: block;

      &:first-of-type {
        font-weight: bold;
      }
    }
  }
`;
