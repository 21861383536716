import styled from "@emotion/styled";

import media from "utils/media";

const Section = styled.section`
  padding-top: 90px;
  padding-bottom: 90px;

  &.image-content ~ &.image-content {
    padding-top: 0;
    padding-bottom: 90px;

    ${media.greaterThan("sm")`
      padding-bottom: 120px;
    `};
  }

  ${media.greaterThan("sm")`
    padding-top: 80px;
    padding-bottom: 80px;
  `};
`;

export default Section;
