import React, { useState } from "react";
import Markdown from "components/elements/Markdown";
import { News, Image } from "./News";
import { Footer, EntryDate, ReadMoreCaption } from "./Footer";
import { formatDateForPostDisplay } from "utils/date";
import { navigate } from "gatsby";
import { isEmpty } from "lodash";
import ConfirmationPopup from "./ConfirmationPopup";

export default function NewsEntry({ newsData, isNewsGrid = false }) {
  const {
    post_excerpt,
    post_date,
    external_post,
    external_link,
    post_hero,
    post_title,
    show_confirmation_popup: showConfirmationPopup = false,
  } = JSON.parse(newsData.content);
  const { slug } = newsData;
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handlePostOpen = () => {
    if (!external_link && slug) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigate(`/news/${slug}`);
    } else if (external_post && external_link) {
      window.open(external_link, "_blank");
    }
  };

  const handlePostClick = () => {
    if (showConfirmationPopup) {
      setIsPopupVisible(true);
    } else {
      handlePostOpen();
    }
  };

  const handleClosePopup = () => setIsPopupVisible(false);

  const handleConfirmPopup = () => {
    handleClosePopup();
    handlePostOpen();
  };

  return (
    <>
      {isPopupVisible && (
        <ConfirmationPopup
          onClose={handleClosePopup}
          onConfirm={handleConfirmPopup}
        />
      )}
      <News
        isNewsGrid={isNewsGrid}
        onClick={handlePostClick}
        $isHighlighted={showConfirmationPopup}
      >
        {!isEmpty(post_hero) && (
          <Image isNewsGrid={isNewsGrid} fluid data={post_hero.filename} />
        )}
        {!isEmpty(post_title) && <h3>{post_title}</h3>}
        {!isEmpty(post_excerpt) && <Markdown>{post_excerpt}</Markdown>}
        <Footer>
          <EntryDate>{formatDateForPostDisplay(post_date)}</EntryDate>
          <ReadMoreCaption isNewsGrid={isNewsGrid}>Read more</ReadMoreCaption>
        </Footer>
      </News>
    </>
  );
}
