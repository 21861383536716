import styled from "@emotion/styled";

import media from "utils/media";

const Section = styled.section`
  padding-top: 71px;
  padding-bottom: 79px;
  background-color: ${({ bg }) => bg};

  color: ${({ color }) => color};
  text-align: center;

  ${media.greaterThan("md")`
    padding-top: 100px;
    padding-bottom: 110px;
  `};

  h2 {
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.5rem;
    margin-bottom: 4rem;
  }
`;

export default Section;
