import styled from "@emotion/styled";

import { Column } from "components/common/Grid";

import media from "utils/media";

const Wrapper = styled(Column)`
  padding-top: ${({ fixed }) => (fixed ? "20px" : 0)};
  padding-bottom: ${({ fixed }) => (fixed ? "20px" : 0)};

  ${media.greaterThan("sm")`
    padding-top: 0;
    padding-bottom: 0;
  `};

  ${({ fixed }) => media.greaterThan("md")`
    padding-top: ${fixed ? "20px" : 0};
    padding-bottom: ${fixed ? "40px" : 0};
  `};

  h2 {
    text-align: center;

    ${media.greaterThan("sm")`
      text-align: left;
    `};
  }

  .download_links {
    margin-top: ${({ fixed }) => (fixed ? "20px" : "30px")};
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

    ${media.greaterThan("sm")`
      flex-direction: row;
    `};

    a + a {
      margin-top: ${({ fixed }) => (fixed ? "10px" : "35px")};

      ${media.greaterThan("sm")`
        margin-top: 0;
        margin-left: 30px;
      `};

      ${media.greaterThan("md")`
        margin-left: 67px;
      `};
    }
  }

  .content {
    margin-top: ${({ fixed }) => (fixed ? "20px" : "70px")};
    text-align: ${({ fixed }) => (fixed ? "center" : "left")};

    ${media.greaterThan("sm")`
      margin-top: 60px;
      text-align: left;
    `};

    ${({ fixed }) => media.greaterThan("md")`
      margin-top: ${fixed ? "60px" : "104px"};
      font-size: 20px;
      line-height: 30px;
    `};

    a {
      font-weight: bold;
    }
  }
`;

export default Wrapper;
