import styled from "@emotion/styled";

const Link = styled.a`
  display: inline-block;
  width: auto;
  position: relative;
  padding-bottom: 8px;
  font-weight: bold;
  line-height: 1;
  /* white-space: pre; */
  word-wrap: break-word;

  span {
    display: inline-block;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fonts.headerFont};
    letter-spacing: 2px;
  }

  img {
    max-width: 12px;
    margin-left: 20px;
  }

  &:before {
    content: "";
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.textColor};
  }
`;

export default Link;
