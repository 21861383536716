import styled from "@emotion/styled";

import { Column } from "components/common/Grid";

import media from "utils/media";

const Heading = styled(Column)`
  margin-bottom: 20px;

  ${media.greaterThan("md")`
    max-width: 860px;
    margin: 0 auto;
    padding: 0;
  `};
`;

export default Heading;
