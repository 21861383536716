import React from "react";

import FlipAnimation from "./FlipAnimation";
import FadeAnimation from "./FadeAnimation";
import RotateAnimation from "./RotateAnimation";

export default function AnimatedImages({ type, ...rest }) {
  switch (type) {
    case "flip":
      return <FlipAnimation {...rest} />;
    case "fade":
      return <FadeAnimation {...rest} />;
    case "rotate":
      return <RotateAnimation {...rest} />;
    default:
      return <FadeAnimation {...rest} />;
  }
}
