import styled from "@emotion/styled";
import { Box } from "@rebass/grid/emotion";
import Masonry from "react-masonry-css";
import media from "utils/media";
import { News } from "../elements/NewsEntry/News";

export const Wrapper = styled.section`
  padding-top: 126px;
  padding-bottom: 78px;

  ${media.greaterThan("md")`
  padding-top: 108px;
  padding-bottom: 118px;
`};
`;

export const Content = styled(Box)`
  max-width: 100%;
  display: block;
  margin: 0 auto;

  ${media.greaterThan("xs")`
    max-width: 33.75rem;
  `};

  ${media.greaterThan("sm")`
    max-width: 45rem;
  `};

  ${media.greaterThan("md")`
    max-width: 60rem;
  `};

  ${media.greaterThan("lg")`
    max-width: 77rem;
  `};
`;

export const Title = styled.h2`
  margin-bottom: 40px;
  line-height: 1;
  white-space: pre;
  font-size: 56px;

  ${media.greaterThan("sm")`
    font-size: 68px;
  `};
`;

export const MasonryWrapper = styled(Masonry)`
  display: flex;
  ${News} {
    width: 100%;
  }
`;
