import React from "react";

import Wrapper from "./Wrapper";
import { Quote, Author } from "./Content";

export default function Blockquote({
  text,
  authorPhoto,
  authorName,
  authorPosition,
  fullWidth,
}) {
  return (
    <Wrapper fullWidth={fullWidth}>
      <Quote>{text}</Quote>
      <Author>
        <img src={authorPhoto} alt="author image" />
        <p>
          <span>{authorName}</span>
          <span>{authorPosition}</span>
        </p>
      </Author>
    </Wrapper>
  );
}
