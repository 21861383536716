import React from "react";
import { Global, ThemeProvider } from "@emotion/react";
import isEmpty from "lodash/isEmpty";
import merge from "lodash/merge";

import SEO from "components/common/SEO";
import Header from "components/common/Header";
import Main from "components/common/Main";
import Footer from "components/common/Footer";
import CookieBanner from "components/common/CookieBanner";

import theme from "utils/theme";
import themeChina from "utils/themeChina";

import globalStyles from "./globalStyles";
import "swiper/dist/css/swiper.min.css";
import "react-responsive-modal/styles.css";

const getSiteTheme = (theme) => {
  switch (theme) {
    case "white":
      return {
        colors: {
          headerColor: "#FFF",
          headerLogo: "#FFF",
        },
      };
    case "blue":
      return {
        colors: {
          headerColor: "#124E91",
          headerLogo: "#114C8D",
        },
      };
    default:
      return {
        colors: {
          headerColor: "#124E91",
          headerLogo: "#114C8D",
        },
      };
  }
};

const Layout = ({
  header,
  footer,
  seo,
  ogType,
  cookie,
  blok,
  siteName,
  location,
  alternates,
  lang,
  languages,
  siteTheme,
  children,
}) => {
  const additionalTheme = getSiteTheme(siteTheme);

  const updatedTheme = merge({}, theme, additionalTheme);
  const updatedChinaTheme = merge({}, themeChina, additionalTheme);

  return blok ? (
    <React.Fragment>
      <SEO
        seoData={seo}
        ogType={ogType}
        pathname={(location && location.pathname) || "/"}
        lang={lang}
        siteName={siteName}
      />
      <ThemeProvider theme={lang === "zh" ? updatedChinaTheme : updatedTheme}>
        <React.Fragment>
          <Global styles={globalStyles} />
          <Header
            data={header}
            languages={languages}
            activeLang={lang}
            alternates={alternates}
            pathname={(location && location.pathname) || "/"}
            theme={siteTheme}
          />
          <Main>
            <h2>This is Layout component for testing</h2>
          </Main>
          <Footer data={blok.footer && blok.footer[0]} />
        </React.Fragment>
      </ThemeProvider>
      {/* {!isEmpty(cookie) && typeof window !== "undefined" && (
        <CookieBanner
          cookie="user-has-accepted-cookies"
          dismissOnScroll={false}
        >
          {onAccept => <Cookies data={cookie} onAccept={onAccept} />}
        </CookieBanner>
      )} */}
    </React.Fragment>
  ) : (
    <React.Fragment>
      <SEO
        seoData={seo}
        ogType={ogType}
        pathname={(location && location.pathname) || "/"}
        lang={lang}
        siteName={siteName}
      />
      <ThemeProvider theme={lang === "zh" ? updatedChinaTheme : updatedTheme}>
        <React.Fragment>
          <Global styles={globalStyles} />
          <Header
            data={header}
            languages={languages}
            activeLang={lang}
            alternates={alternates}
            pathname={(location && location.pathname) || "/"}
            theme={siteTheme}
          />
          <Main>{children}</Main>
          <Footer data={footer} />
        </React.Fragment>
      </ThemeProvider>
      {!isEmpty(cookie) && typeof window !== "undefined" && (
        <CookieBanner cookie={cookie} />
      )}
    </React.Fragment>
  );
};

export default Layout;
