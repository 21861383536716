import React from "react";
import styled from "@emotion/styled";

import media from "utils/media";

export default function Member({ data, className, onClick }) {
  const { _uid: id, name, position } = data || {};

  return (
    <StyledMember
      className={className}
      onClick={onClick}
      id={id}
      aria-label="Member"
    >
      <div>
        <h3>{name}</h3>
        <span>{position}</span>
      </div>
    </StyledMember>
  );
}

const StyledMember = styled.button`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.textColor};
  position: relative;
  z-index: 5;

  ${media.greaterThan("md")`
    padding: 10px;
    height: 200px;
  `};

  div {
    pointer-events: none;
  }

  span {
    line-height: 1;
    font-size: 26px;
    font-family: ${({ theme }) => theme.fonts.headerFont};
    color: ${({ theme }) => theme.colors.gray_v2};
  }

  h3 {
    font-size: 30px;
    line-height: 1;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.textColor};

    ${media.greaterThan("xs")`
      font-size: 42px;
    `};
  }
`;
