import styled from "@emotion/styled";

import media from "utils/media";

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 400px;
  position: relative;

  ${media.greaterThan("xs")`
    max-height: 500px;
  `};

  ${media.greaterThan("sm")`
    max-height: 600px;
  `};

  ${media.greaterThan("lg")`
    max-height: 800px;
  `};

  ${media.greaterThan("1600px")`
    max-height: 900px;
  `};

  ${media.greaterThan("1920px")`
    max-height: 1000px;
  `};

  .video {
    visibility: ${({ visible }) => (visible ? "hidden" : "visible")};
    padding-top: 56.25%;
    transition: visibility 0.5s ease;

    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  button {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    border-radius: 100%;

    ${media.greaterThan("sm")`
      width: 80px;
      height: 80px;
    `};

    ${media.greaterThan("md")`
      width: 130px;
      height: 130px;
    `};

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.play {
      background-color: rgba(0, 41, 76, 0.8);

      &:after {
        border-top: 10px solid transparent;
        border-left: 15px solid ${({ theme }) => theme.colors.white};
        border-bottom: 10px solid transparent;

        ${({ theme }) => media.greaterThan("sm")`
        border-top: 15px solid transparent;
        border-left: 20px solid ${theme.colors.white};
        border-bottom: 15px solid transparent;
      `};

        ${({ theme }) => media.greaterThan("md")`
        border-top: 18px solid transparent;
        border-left: 35px solid ${theme.colors.white};
        border-bottom: 18px solid transparent;
      `};
      }
    }

    &.pause {
      background-color: rgba(0, 41, 76, 0.2);
      opacity: ${({ hover }) => (hover ? 1 : 0)};
      transition: opacity 0.3s ease-out;

      &:after {
        opacity: 0.6;
        width: 15px;
        height: 20px;
        transform: translate(-50%, -50%);
        border-left: 5px solid ${({ theme }) => theme.colors.white};
        border-right: 5px solid ${({ theme }) => theme.colors.white};

        ${({ theme }) => media.greaterThan("sm")`
        width: 20px;
        height: 30px;
        border-left: 7px solid ${theme.colors.white};
        border-right: 7px solid ${theme.colors.white};
      `};

        ${({ theme }) => media.greaterThan("md")`
        width: 35px;
        height: 36px;
        border-left: 10px solid ${theme.colors.white};
        border-right: 10px solid ${theme.colors.white};
      `};
      }
    }
  }
`;

export default VideoWrapper;
