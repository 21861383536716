import React from "react";
import isEmpty from "lodash/isEmpty";

import Markdown from "components/elements/Markdown";
import { Container, Row } from "components/common/Grid";

import Section from "./Section";
import Heading from "./Heading";
import Content from "./Content";

export default function HeadingContent({ data }) {
  const { heading, content } = data || {};

  return (
    <Section>
      <Container>
        <Row>
          <Heading>
            <h1>{heading}</h1>
          </Heading>
          {!isEmpty(content) && (
            <Content>
              <Markdown>{content}</Markdown>
            </Content>
          )}
        </Row>
      </Container>
    </Section>
  );
}
