import React from "react";
import styled from "@emotion/styled";

export default function Animation({ words }) {
  return (
    <Wrapper>
      {words.map((word, index) => (
        <span key={`word_${index}`} className={`word_${index + 1}`}>
          {word.text}
        </span>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  span {
    text-transform: uppercase;
    color: white;
    font-size: 2.5vw;
    font-weight: bold;
    display: inline-block;
    position: absolute;
    z-index: 1;
    line-height: 1;
    transition: 0.2s;
    animation-duration: 1s;
    animation-fill-mode: both;
    opacity: 1;

    &.word_1 {
      top: 42%;
      right: 47%;
      transform: rotate(-90deg);
      transform-origin: top left;
      animation-name: fadeInUp;
      animation-delay: 0.7s;
    }

    &.word_2 {
      top: 51%;
      left: 46.5%;
      transform: rotate(-90deg);
      transform-origin: top left;
      font-size: 3.5vw;
      animation-name: fadeInUp;
      animation-delay: 0.4s;
    }

    &.word_3 {
      top: 42%;
      right: 38.5%;
      transform: rotate(-90deg);
      transform-origin: top left;
      font-size: 3.5vw;
      animation-name: fadeInUp;
      animation-delay: 0.3s;
    }

    &.word_4 {
      top: 38%;
      left: 57%;
      animation-name: fadeInRight;
      animation-delay: 0.8s;
    }

    &.word_5 {
      top: 45%;
      left: 52%;
      font-size: 3.5vw;
      animation-name: fadeInRight;
      animation-delay: 0.1s;
    }

    &.word_6 {
      top: 54%;
      left: 46.5%;
      font-size: 3.5vw;
      animation-name: fadeInRight;
      animation-delay: 0.4s;
    }

    &.word_7 {
      top: 63%;
      left: 52%;
      animation-name: fadeInRight;
      animation-delay: 0.2s;
    }

    &.word_8 {
      top: 63.1%;
      right: 35.9%;
      transform: rotate(-270deg);
      transform-origin: top left;
      font-size: 3.5vw;
      animation-name: fadeInDown;
      animation-delay: 0.5s;
    }

    &.word_9 {
      top: 54.5%;
      right: 45.5%;
      transform: rotate(-270deg);
      transform-origin: top left;
      animation-name: fadeInDown;
      animation-delay: 0.3s;
    }

    &.word_10 {
      top: 54%;
      right: 60%;
      animation-name: fadeInLeft;
      animation-delay: 0.4s;
    }

    &.word_11 {
      top: 45%;
      right: 56%;
      font-size: 3.5vw;
      animation-name: fadeInLeft;
      animation-delay: 0.8s;
    }
  }

  @keyframes fadeInDown {
    from {
      opacity: 0;
      margin-top: 100px;
    }

    to {
      opacity: 1;
      margin-top: 0;
    }
  }

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(200px, 0, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      margin-top: -100px;
    }

    to {
      opacity: 1;
      margin-top: 0px;
    }
  }
`;
