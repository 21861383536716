import styled from "@emotion/styled";

import { Column } from "components/common/Grid";

import media from "utils/media";

const Copyright = styled(Column)`
  h3 {
    margin-bottom: 11px;
    letter-spacing: 0.2px;

    ${media.greaterThan("md")`
      margin-bottom: 17px;
    `};
  }

  p {
    display: inline-block;

    ${media.greaterThan("md")`
      display: block;
    `};

    &:first-of-type {
      margin-right: 5px;
    }

    &:last-of-type {
      display: block;
    }
  }

  p,
  a {
    letter-spacing: 0.1px;
    color: ${({ theme }) => theme.colors.blue_v2};
  }

  a {
    display: block;
    text-decoration: underline;
    margin-top: 11px;

    ${media.greaterThan("md")`
      margin-top: 25px;
    `};
  }
`;

export default Copyright;
