import styled from "@emotion/styled";

import media from "utils/media";

const MainImage = styled.div`
  width: 100%;

  ${media.greaterThan("xs")`
    width: 100vw;
    margin-left: calc(-100vw / 2 + 33.75rem / 2 );
    margin-right: calc(-100vw / 2 + 33.75rem / 2 );
  `};

  ${media.greaterThan("sm")`
    margin-left: calc(-100vw / 2 + 45rem / 2 );
    margin-right: calc(-100vw / 2 + 45rem / 2 );
  `};

  ${media.greaterThan("md")`
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: calc(-100vw / 2 + 60rem / 2 );
  `};

  ${media.greaterThan("lg")`
    width: 640px;
    height: 640px;
    margin-left: auto;
    margin-right: calc(-100vw / 2 + 77rem / 2 );
  `};

  ${media.greaterThan("1920px")`
    margin-right: -350px;
  `};

  .gatsby-image-wrapper {
    height: 100%;
  }
`;

export default MainImage;
