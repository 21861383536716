import styled from "@emotion/styled";

import { Column } from "components/common/Grid";

import media from "utils/media";

const Lead = styled(Column)`
  margin-bottom: 70px;

  ${media.greaterThan("md")`
    display: flex;
    align-items: center;
  `};

  ${media.greaterThan("lg")`
    margin-bottom: 94px;
  `};

  & > div:first-of-type {
    margin-bottom: 25px;

    ${media.greaterThan("md")`
      order: 2;
      width: 60%;
      padding-left: 50px;
      margin-bottom: 0;
    `};

    ${media.greaterThan("lg")`
      width: 55%;
      padding-left: 100px;
    `};
  }

  .gatsby-image-wrapper {
    margin-left: -26px;
    max-height: 300px;

    ${media.greaterThan("xs")`
      margin-left: calc(-100vw / 2 + 33.75rem / 2 - 17px);
      max-height: 350px;
    `};

    ${media.greaterThan("sm")`
      margin-left: calc(-100vw / 2 + 45rem / 2 - 17px);
      max-height: 400px;
    `};

    ${media.greaterThan("md")`
      margin-left: calc(-100vw / 2 + 60rem / 2 - 17px);
      width: 40%;
      max-height: none;
    `};

    ${media.greaterThan("lg")`
      margin-left: calc(-100vw / 2 + 77rem / 2 - 17px);
      width: 45%;
      max-height: none;
    `};

    ${media.greaterThan("1600px")`
      margin-left: 0;
    `};
  }
`;

export default Lead;
