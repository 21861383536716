import React from "react";
import isEmpty from "lodash/isEmpty";

import { Container, Row } from "components/common/Grid";
import Markdown from "components/elements/Markdown";
import NewsEntry from "components/elements/NewsEntry";

import Section from "./Section";
import Content from "./Content";
import Socials from "./Socials";
import PrimaryInfo from "./PrimaryInfo";
import { RecentNews } from "./RecentNews";

export default function Post({ blok, path, featuredNews }) {
  const { post_author, post_date, post_title, post_content } = blok || {};

  return (
    <Section>
      <Container>
        <Row style={{ position: "relative" }}>
          <PrimaryInfo
            postAuthor={post_author}
            postDate={post_date}
            postTitle={post_title}
          />
          {!isEmpty(post_content) && (
            <Content>
              <Markdown>{post_content}</Markdown>
              <Socials path={path} />
            </Content>
          )}
          {!isEmpty(featuredNews.edges) && (
            <RecentNews>
              <h2>Recent posts</h2>
              {featuredNews.edges.map((news) => {
                const { _uid } = JSON.parse(news.node.content);
                return <NewsEntry key={_uid} newsData={news.node} />;
              })}
            </RecentNews>
          )}
        </Row>
      </Container>
    </Section>
  );
}
