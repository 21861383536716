import React from "react";
import isEmpty from "lodash/isEmpty";

import { Container, Row, Column } from "components/common/Grid";
import Link from "components/elements/Link";

import Section from "./Section";

export default function BackToHome({ data }) {
  const { link } = data || {};

  return (
    <Section>
      <Container>
        <Row>
          <Column>
            {!isEmpty(link) && !isEmpty(link[0]) && (
              <Link {...link[0]} className="primary" />
            )}
          </Column>
        </Row>
      </Container>
    </Section>
  );
}
