import styled from "@emotion/styled";

import media from "utils/media";

const Wrapper = styled.div`
  position: relative;
  padding: 98px 0 46px 0;

  &:after {
    content: "";
    width: 78%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-color: ${({ mainBg }) => mainBg || "#fff"};

    ${media.greaterThan("md")`
      width: 66%;
    `};
  }

  ${media.greaterThan("md")`
    padding: 123px 0 45px 0;
  `};
`;

export default Wrapper;
