import React from "react";
import Img from "gatsby-image/withIEPolyfill";

import {
  getFixedGatsbyImage,
  getFluidGatsbyImage,
} from "gatsby-storyblok-image";

export default function ImageComponent({
  fluid,
  fixed,
  data,
  options = {},
  ...rest
}) {
  const fixedProps =
    fixed && data
      ? getFixedGatsbyImage(data, {
          ...options,
          format: "webp",
          toFormat: "webp",
        })
      : null;

  const fluidProps =
    fluid && data
      ? getFluidGatsbyImage(data, {
          ...options,
          format: "webp",
          toFormat: "webp",
        })
      : null;

  return fixedProps ? (
    <Img fixed={fixedProps} {...rest} alt={data.alt_tag || ""} />
  ) : fluidProps ? (
    <Img fluid={fluidProps} {...rest} alt={data.alt_tag || ""} />
  ) : null;
}
