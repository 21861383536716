import React from "react";
import TileLink from "./PostTile";

export default function PostTile({
  formattedSlug,
  title,
  date,
  isExternal,
  externalUrl,
  readMoreLabel,
}) {
  return (
    <TileLink
      type={isExternal ? "external" : "internal"}
      url={isExternal ? externalUrl : formattedSlug}
    >
      <h3>{title}</h3>
      <span>{date}</span>
      <p>{readMoreLabel}</p>
    </TileLink>
  );
}
