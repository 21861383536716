import React from "react";

import downloadIco from "assets/svg/download-button.svg";

import Link from "./Link";

export default function DownloadLink({ url, title, isIcon }) {
  return (
    <Link
      href={url}
      target="blank"
      download
      className="download"
      rel="noopener noreferrer"
    >
      <span>{title}</span>
      {isIcon ? <img src={downloadIco} alt="download" /> : null}
    </Link>
  );
}
