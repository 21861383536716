import styled from "@emotion/styled";

import { Column } from "components/common/Grid";

import media from "utils/media";

const ProjectsTitle = styled(Column)`
  margin-bottom: 50px;

  ${media.greaterThan("md")`
    margin-bottom: 80px;
  `};

  h2 {
    line-height: 1;
  }
`;

export default ProjectsTitle;
