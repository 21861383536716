import React from "react";
import styled from "@emotion/styled";

import media from "utils/media";

const StyledHamburger = styled.button`
  display: block;
  cursor: pointer;
  position: relative;
  z-index: 102;
  width: 26px;
  height: 42px;

  ${({ hiden }) => media.greaterThan("sm")`
    display: ${hiden ? "none" : "block"};
    margin-left: ${hiden ? "0" : "90px"};
  `};

  span {
    display: block;
    position: absolute;
    height: 1px;
    width: 26px;
    border: 2px solid
      ${({ fixed, theme }) =>
        fixed ? theme.colors.brand : theme.colors.headerColor};
    background-color: ${({ fixed, theme }) =>
      fixed ? theme.colors.brand : theme.colors.headerColor};
    left: 0;
    opacity: ${({ hover }) => (hover === "inactive" ? "0.3" : 1)};
    transition: border 0.3s ease-in-out, background-color 0.3s ease-in-out,
      opacity 0.3s ease-in-out;

    &.open {
      border: 2px solid ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.white};
    }

    &:nth-of-type(1) {
      top: ${({ open }) => (open ? "20px" : "15px")};
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    &:nth-of-type(2) {
      top: ${({ open }) => (open ? "20px" : "25px")};
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

export default function Hamburger({ open, fixed, onClick, hiden }) {
  return (
    <StyledHamburger
      onClick={onClick}
      fixed={fixed}
      open={open}
      type="button"
      hiden={hiden}
      aria-label="Hamburger Menu"
    >
      <span className={open ? "open" : ""} />
      <span className={open ? "open" : ""} />
    </StyledHamburger>
  );
}
