import React, { Fragment } from "react";
import styled from "@emotion/styled";
import find from "lodash/find";

import Link from "components/elements/Link";
import getTranslations from "utils/getTranslations";

const LangSwitcher = ({ languages, activeLang, alternates }) => {
  const GATSBY_DEFAULT_LANG = process.env.GATSBY_DEFAULT_LANG;

  return (
    <Container>
      {languages.map((language, i) => {
        const langLabel = getTranslations(language, "langLabel");
        if (language === activeLang) {
          return (
            <Fragment key={language}>
              <ActiveLang>{langLabel}</ActiveLang>
              {i < languages.length - 1 && <LangSeparator>|</LangSeparator>}
            </Fragment>
          );
        } else {
          let alternate = null;
          if (Array.isArray(alternates)) {
            alternate = find(
              alternates,
              (item) =>
                item.full_slug.substring(0, item.full_slug.indexOf("/")) ===
                language
            );

            /* alternate = alternates.filter(
              (item) =>
                item.full_slug.substring(0, item.full_slug.indexOf("/")) ===
                language
            ); */
          }

          let url = alternate?.full_slug;

          if (language === GATSBY_DEFAULT_LANG && url) {
            url = alternate.full_slug.substring(
              alternate.full_slug.indexOf("/")
            );
          }

          if (url) {
            if (language === GATSBY_DEFAULT_LANG) {
              url = alternate.full_slug.substring(
                alternate.full_slug.indexOf("/")
              );
            }
            return (
              <Fragment key={language}>
                <InactiveLang
                  type="internal"
                  url={url.indexOf("/") === 0 ? url : `/${url}`}
                >
                  {langLabel}
                </InactiveLang>
                {i < languages.length - 1 && <LangSeparator>|</LangSeparator>}
              </Fragment>
            );
          }
          return null;

          // temporary disabled EN version
          // return (
          //   <>
          //     <InactiveLang type="external" url="http://vertexholdings.com">
          //       {langLabel}
          //     </InactiveLang>
          //     {i < languages.length - 1 && <LangSeparator>|</LangSeparator>}
          //   </>
          // );
        }
      })}
    </Container>
  );
};

export default LangSwitcher;

const Container = styled.li`
  margin-top: 55px;
`;

const ActiveLang = styled.span`
  font-size: 26px;
  font-weight: bold;
`;

const InactiveLang = styled(Link)`
  font-size: 26px;
`;

const LangSeparator = styled.span`
  font-size: 26px;
  margin: 0 10px;
`;
