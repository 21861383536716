import styled from "@emotion/styled";

import media from "utils/media";

const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${media.greaterThan("sm")`
    display: block;

    &:after {
      content:'';
      display:block;
      clear:both;
    }
  `};

  .author {
    width: 100%;
    max-width: 350px;
    margin: 50px auto;

    ${media.greaterThan("sm")`
      max-width: none;
      width: 200px;
      float: left;
      margin: 0 70px 29px 0;
    `};

    ${media.greaterThan("md")`
      width: 300px;
      margin-right: 50px;
    `};

    ${media.greaterThan("lg")`
      width: 480px;
      margin-right: 110px;
      margin-bottom: 67px;
    `};

    .gatsby-image-wrapper {
      max-height: 350px;
      margin-bottom: 25px;

      ${media.greaterThan("sm")`
        width: 100%;
        max-height: none;
        border-radius: 0;
        margin-bottom: 30px;
      `};
    }

    .name {
      font-weight: bold;
      font-size: 18px;

      ${media.greaterThan("sm")`
        line-height: 24px;
      `};
    }

    .title {
      ${media.greaterThan("sm")`
        line-height: 24px;
      `};
    }

    .role {
      ${media.greaterThan("sm")`
        line-height: 21px;
        margin-top: 6px;
      `};
    }
  }

  .content {
    margin-bottom: 80px;

    ${media.greaterThan("sm")`
      margin: 30px 0 0;
    `};

    p {
      font-size: 26px;
      line-height: 1.5;
      letter-spacing: 0.2px;

      ${media.between("sm", "md")`
        font-size: 20px;
        line-height: 1.4;
      `};

      & + p {
        margin-top: 30px;
      }
    }
  }
`;

export default Content;
