import styled from "@emotion/styled";

import media from "utils/media";

const Section = styled.section`
  position: relative;
  padding-top: 83px;
  padding-bottom: 63px;
  overflow: hidden;

  ${media.greaterThan("md")`
    padding-top: 150px;
    padding-bottom: 75px;
  `};

  &:after {
    content: "";
    width: 100%;
    height: ${({ offset }) =>
      offset ? `calc((25vw * ${offset}) + 63px)` : "263px"};
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    background-color: ${({ bgBottom }) => bgBottom || "#FFF"};

    ${({ offset }) => media.greaterThan("md")`
      height: ${offset ? `calc((25vw * ${offset}) + 75px)` : "275px"} ;
    `};

    ${({ offset }) => media.greaterThan("lg")`
      height: ${offset ? `calc((20vw * ${offset}) + 75px)` : "275px"};
    `};

    ${({ offset }) => media.greaterThan("1920px")`
      height: ${offset ? `calc((15vw * ${offset}) + 75px)` : "275px"} ;
    `};
  }
`;

export default Section;
