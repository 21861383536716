import { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import throttle from "lodash/throttle";

export default function useChildrensHeight(target) {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      const nodeList =
        target && !isEmpty(target.current) && target.current.children;
      const heightCounter =
        nodeList &&
        Array.prototype.map
          .call(nodeList, (node) => node.clientHeight)
          .reduce((a, b) => {
            return a + b;
          }, 0);
      setHeight(heightCounter);
    };

    const handleResize = throttle(updateHeight, 300);

    updateHeight();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [target]);

  return height;
}
