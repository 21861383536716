import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Column } from "../common/Grid";
import NewsEntry from "../elements/NewsEntry";
import { Content, MasonryWrapper, Title, Wrapper } from "./Wrapper";

const INITIAL_NEWS_PAGE = 1;

export default function NewsMain({ lang }) {
  const [fetchedNews, setFetchedNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isFull, setIsFull] = useState(false);
  const marker = useRef(null);
  const observerRef = useRef(null);

  const fetchNewsPage = useCallback(
    async (manualPage) => {
      const pageToFetch = manualPage || currentPage;
      if (pageToFetch) {
        try {
          const res = await fetch(`/posts-data/${lang}-${pageToFetch}.json`);
          const data = await res.json();
          if (!isEmpty(data)) {
            if (pageToFetch === INITIAL_NEWS_PAGE) {
              setFetchedNews(data);
            } else if (pageToFetch > INITIAL_NEWS_PAGE) {
              let news = [...fetchedNews];
              news = news.concat(data);
              setFetchedNews(news);
            }
          } else if (!isFull) {
            setIsFull(true);
          }
        } catch (err) {
          console.err(err);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage]
  );

  const initializeNewsList = async () => {
    await fetchNewsPage(INITIAL_NEWS_PAGE);
    setCurrentPage((prev) => prev + 1);
  };

  useEffect(() => {
    initializeNewsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchNewsPage();
  }, [fetchNewsPage]);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setCurrentPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    if (currentPage === INITIAL_NEWS_PAGE) {
      const option = {
        root: null,
        rootMargin: "10px",
        threshold: 0,
      };
      const observer = new IntersectionObserver(handleObserver, option);
      if (marker.current) {
        observer.observe(marker.current);
        observerRef.current = observer; // to keep ref of observer in memory
      }
    }
    if (observerRef.current && isFull) {
      observerRef.current.unobserve(marker.current);
    }
  }, [handleObserver, currentPage, isFull]);

  return (
    <Wrapper>
      <Content>
        <Column>
          <Title>Latest News</Title>
          <MasonryWrapper
            breakpointCols={{
              default: 3,
              992: 2,
              767: 1,
            }}
          >
            {fetchedNews.map((news) => {
              const { _uid } = JSON.parse(news.content);
              return <NewsEntry isNewsGrid key={_uid} newsData={news} />;
            })}
          </MasonryWrapper>
          <div ref={marker} />
        </Column>
      </Content>
    </Wrapper>
  );
}
