import styled from "@emotion/styled";

import { Column } from "components/common/Grid";

import media from "utils/media";

const Image = styled(Column)`
  .gatsby-image-wrapper {
    height: 100%;
    min-height: 390px;

    ${({ mediaHeight }) => media.greaterThan("sm")`
      min-height: ${mediaHeight ? `${mediaHeight}px` : `390px`};
    `};
  }

  &.vertical {
    .gatsby-image-wrapper {
      ${media.greaterThan("md")`
        max-width: 70%;
        margin: 0 auto;
      `};
    }
  }
`;

export default Image;
