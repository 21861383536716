import styled from "@emotion/styled";

import media from "utils/media";

import Link from "components/elements/Link";
import Dropdown from "components/elements/Dropdown";

export const HamburgerNav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100vw;
  height: ${({ open }) => (open ? "100vh" : "0")};
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.blue_v1};
  opacity: 0.93;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  overflow: auto;

  ${media.greaterThan("sm")`
    display: flex;
    align-items: center;
  `};
`;

export const StyledLink = styled.a`
  opacity: ${({ open }) => (open ? "1" : "0")};
  transition: opacity 0.3s ease-in-out;
  transition-delay: ${({ open }) => (open ? "0.2s" : "0")};
`;

export const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  height: 100%;

  @media (orientation: landscape) and (max-height: 590px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

export const MainListItem = styled.li`
  @media (orientation: landscape) and (max-height: 590px) {
    margin-right: 30px;
  }

  ${media.greaterThan("sm")`
    display: none;
  `};
`;

export const ListItem = styled.li`
  @media (orientation: landscape) and (max-height: 590px) {
    margin-right: 30px;
  }
`;

export const ListItemLink = styled(Link)`
  font-size: 30px;
  line-height: 1.5;
  font-weight: 300;

  ${media.greaterThan("sm")`
    font-size: 68px;
  `};
`;

export const ListSubItem = styled(Dropdown)`
  margin-top: 30px;

  @media (orientation: landscape) and (max-height: 590px) {
    margin-top: 0;
  }

  ${media.greaterThan("sm")`
    margin-top: 0;
  `};
`;
