import React from "react";

import { Container, Row, Column } from "components/common/Grid";

import Section from "./Section";
import Map from "./Map";

export default function GlobalPlatform({ data }) {
  const {
    heading,
    lead,
    vertex_china,
    vertex_india,
    vertex_indonesia,
    vertex_israel,
    vertex_singapore,
    vertex_usa,
    vertex_hc,
    vertex_growth,
  } = data;

  const descriptions = {
    usa: vertex_usa,
    israel: vertex_israel,
    india: vertex_india,
    china: vertex_china,
    singapore: vertex_singapore,
    indonesia: vertex_indonesia,
    healthcare: vertex_hc,
    growth: vertex_growth,
  };

  return (
    <Section id="global-platform">
      <Container>
        <Row>
          <Column>
            <h2 className="h1" style={{ lineHeight: 1 }}>
              {heading}
            </h2>
            <Map descriptions={descriptions} />
            <p className="lead">{lead}</p>
          </Column>
        </Row>
      </Container>
    </Section>
  );
}
