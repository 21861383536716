import React from "react";
import Mailchimp from "react-mailchimp-form";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  input,
  button {
    width: 100%;
    padding: 5px 10px;
  }

  & button,
  & p {
    font-family: WhitneyNew, sans-serif;
    font-weight: bold;
    color: white;
  }

  button {
    margin-top: 10px;
    border: 1px solid white;

    &:disabled {
      opacity: 0.6;
      cursor: default;
    }
  }

  .msg-alert {
    width: 100%;
    margin-top: 10px;
  }
`;

const msg = {
  display: "block",
  width: "100%",
  color: "white",
  fontWeight: "inherit",
  padding: "5px 10px",
  textAlign: "center",
};

const styles = {
  sendingMsg: {
    ...msg,
    backgroundColor: "#0f8bbb",
  },

  successMsg: {
    ...msg,
    backgroundColor: "#44905b",
  },

  duplicateMsg: {
    ...msg,
    background: "#bb7f25",
  },

  errorMsg: {
    ...msg,
    backgroundColor: "#ec5b5b",
  },
};

const Newsletter = ({ action }) => {
  return (
    <Wrapper>
      <Mailchimp
        action={action}
        fields={[
          {
            name: "EMAIL",
            placeholder: "Enter email address",
            required: true,
          },
        ]}
        messages={{
          button: "Subscribe to our Newsletter!",
          sending: "Subscribing...",
          success: "See you in your inbox soon!",
          error: "An unexpected internal error has occurred.",
          empty: "Invalid e-mail address format.",
          duplicate: "Too many subscribe attempts for this email address.",
        }}
        styles={styles}
      />
    </Wrapper>
  );
};

export default Newsletter;
