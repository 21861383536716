import styled from "@emotion/styled";

import media from "utils/media";

const StyledContent = styled.div`
  background-color: ${({ theme }) => theme.colors.blue_v2};
  padding: 30px 20px;

  ${media.greaterThan("xs")`
    padding: 40px;
  `};

  ${media.greaterThan("md")`
    overflow-y: auto;
    height: calc(100% - 242px);
  `};

  ${media.greaterThan("lg")`
    padding: 60px 80px 95px 100px;
  `};

  div {
    p {
      letter-spacing: 0.1px;
    }

    p + p {
      margin-top: 16px;
    }
  }
`;

export default StyledContent;
