import styled from "@emotion/styled";

import media from "utils/media";

import { Column } from "components/common/Grid";

const Content = styled(Column)`
  .lead {
    margin: 20px 0;

    ${media.greaterThan("md")`
      margin: 35px 0;
    `};
  }

  & > h2 {
    line-height: 1;
  }

  & > div {
    margin-bottom: 30px;

    ${media.greaterThan("md")`
      margin-bottom: 60px;
    `};

    p {
      margin-bottom: 24px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  & > a {
    display: inline-block;
    padding-bottom: 10px;
    color: ${({ theme }) => theme.colors.textColor};
    font-family: ${({ theme }) => theme.fonts.headerFont};
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 2px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.textColor};
  }
`;

export default Content;
