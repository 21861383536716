import React from "react";
import { navigate } from "gatsby";
import styled from "@emotion/styled";

import media from "utils/media";

export default function CookieLang({ data, onAccept }) {
  const { message } = data || {};

  const handleOnAccept = () => {
    onAccept();
    navigate("/en/");
  };

  return (
    <Banner>
      <p>{message}</p>
      <div>
        <button onClick={onAccept}>NO</button>
        <button onClick={handleOnAccept}>YES</button>
      </div>
    </Banner>
  );
}

const Banner = styled.div`
  position: fixed;
  top: 65vh;
  left: 10%;
  width: 80%;
  padding: 14px 27px 18px;
  background-color: #124e91;
  color: #fff;
  letter-spacing: 0.11px;
  z-index: 200;

  ${media.greaterThan("xs")`
    max-width: 400px;
    padding: 40px;
  `};

  ${media.greaterThan("md")`
    max-width: 500px;
    top: 640px;
  `};

  @media screen and (max-height: 800px) {
    top: 60vh;
  }

  button {
    width: 49%;
    max-width: 100px;
    margin: 20px 0;
    border: solid 1px #ffffff;
    font-family: "WhitneyNew";
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    text-transform: uppercase;

    ${media.greaterThan("xs")`
      max-width: 150px;
    `};

    ${media.greaterThan("sm")`
      line-height: 54px;
    `};

    ${media.greaterThan("md")`
      max-width: 180px;
    `};
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
