import styled from "@emotion/styled";

import media from "utils/media";

const StyledModal = styled.div`
  ${media.greaterThan("md")`
    display: flex;
    height: 82vh;
    max-height: 700px;
    width: 100%;
  `};

  .gatsby-image-wrapper {
    ${media.greaterThan("xs")`
      max-height: 380px;
    `};

    ${media.greaterThan("md")`
      max-height: none;
      flex-basis:43%;
    `};
  }

  & > div:last-of-type {
    ${media.greaterThan("md")`
      flex-basis: 57%;
      overflow: hidden;
    `};
  }
`;

export default StyledModal;
