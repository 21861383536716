import React from "react";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import styled from "@emotion/styled";

import media from "utils/media";

import Project from "./Project";

export default function PortfolioGroup({
  title,
  projects,
  category,
  status,
  small,
}) {
  const filteredProjects =
    category && status
      ? filter(
          projects,
          (item) =>
            item.status === status &&
            (item.category === category || category === "all")
        )
      : category
      ? filter(
          projects,
          (item) => item.category === category || category === "all"
        )
      : status
      ? filter(projects, { status })
      : projects;

  return (
    !isEmpty(filteredProjects) && (
      <Wrapper>
        <h3>{title}</h3>
        <Projects>
          {filteredProjects.map((item) => (
            <Project key={item._uid} {...item} small={small} />
          ))}
        </Projects>
      </Wrapper>
    )
  );
}

const Wrapper = styled.div`
  width: 100%;

  & + & {
    margin-top: 30px;

    ${media.greaterThan("md")`
      margin-top: 58px;
    `};
  }

  h3 {
    padding: 10px 26px;
    font-weight: bold;
    background-color: ${({ theme }) => theme.colors.blue_v2};

    ${media.greaterThan("xs")`
      padding-left: calc((100% - 33.75rem) / 2 + 26px);
      padding-right: calc((100% - 33.75rem) / 2 + 26px);
    `};

    ${media.greaterThan("sm")`
      padding-left: calc((100% - 45rem) / 2 + 26px);
      padding-right: calc((100% - 45rem) / 2 + 26px);
    `};

    ${media.greaterThan("md")`
      padding: 36px 26px;
      padding-left: calc((100% - 60rem) / 2 + 26px);
      padding-right: calc((100% - 60rem) / 2 + 26px);
    `};

    ${media.greaterThan("lg")`
      padding: 36px 26px;
      padding-left: calc((100% - 77rem) / 2 + 26px);
      padding-right: calc((100% - 77rem) / 2 + 26px);
    `};
  }
`;

const Projects = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.gray_v1};
`;
