import styled from "@emotion/styled";

import media from "utils/media";

const ImageWrapper = styled.div`
  .gatsby-image-wrapper {
    height: calc(100vw - 52px);

    ${media.greaterThan("xs")`
      height: 280px;
    `};

    ${media.greaterThan("sm")`
      height: 350px;
    `};

    ${media.greaterThan("md")`
      height: 500px;
    `};

    ${media.greaterThan("lg")`
      height: 600px;
    `};
  }
`;

export default ImageWrapper;
