import React from "react";
import isEmpty from "lodash/isEmpty";

import { ReactComponent as FacebookIco } from "assets/svg/facebook-ico.svg";
import { ReactComponent as TwitterIco } from "assets/svg/twitter-ico.svg";
import { ReactComponent as LinkedinIco } from "assets/svg/linkedin-ico.svg";
import { ReactComponent as MediumIco } from "assets/svg/medium-ico.svg";
import { ReactComponent as Email } from "assets/svg/email-ico.svg";
import { ReactComponent as WeChat } from "assets/svg/wechat-ico.svg";
import { ReactComponent as Weibo } from "assets/svg/weibo-ico.svg";
import { ReactComponent as Toutiao } from "assets/svg/toutiao-ico.svg";
import { ReactComponent as Youtube } from "assets/svg/youtube-ico.svg";

const types = {
  facebook: <FacebookIco />,
  twitter: <TwitterIco />,
  linkedin: <LinkedinIco />,
  medium: <MediumIco />,
  email: <Email />,
  wechat: <WeChat />,
  weibo: <Weibo />,
  toutiao: <Toutiao />,
  youtube: <Youtube/>,
};

export default function SocialLink({ type, url }) {
  return type && !isEmpty(types[type]) ? (
    <a
      href={url}
      css={{ display: "block", svg: { display: "block" } }}
      aria-label={type.charAt(0).toUpperCase() + type.slice(1)}
      target={
        !isEmpty(url) && url.substring(0, 6) === "mailto" ? "_self" : "_blank"
      }
      rel="noreferrer"
    >
      {types[type]}
    </a>
  ) : (
    <p>Unknown type</p>
  );
}
