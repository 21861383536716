import React from "react";
import styled from "@emotion/styled";

import Image from "components/elements/Image";

import media from "utils/media";

export default function Images({ images }) {
  return (
    <Wrapper>
      {images.map((item) => (
        <StyledImage key={item._uid}>
          <Image fluid data={item.asset} alt="" />
        </StyledImage>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  ${media.greaterThan("xs")`
    width: 100vw;
    margin-left: calc(-100vw / 2 + 33.75rem / 2 );
    margin-right: calc(-100vw / 2 + 33.75rem / 2 );
  `};

  ${media.greaterThan("sm")`
    margin-left: calc(-100vw / 2 + 45rem / 2 );
    margin-right: calc(-100vw / 2 + 45rem / 2 );
  `};

  ${media.greaterThan("md")`
    margin-left: calc(-100vw / 2 + 60rem / 2 );
    margin-right: calc(-100vw / 2 + 60rem / 2 );
  `};

  ${media.greaterThan("lg")`
    width: 80vw;
    margin-left: calc(-100vw / 2 + 77rem / 2 );
    margin-right: auto;
    margin-top: -120px;
  `};

  ${media.greaterThan("1920px")`
    width: 60vw;
    margin-left: auto;
    margin-right: -250px;
  `};
`;

const StyledImage = styled.div`
  width: 25%;
  height: 25vw;

  ${media.greaterThan("lg")`
    height: 20vw;
  `};

  ${media.greaterThan("1920px")`
    height: 15vw;
  `};

  .gatsby-image-wrapper {
    height: 100%;
  }
`;
