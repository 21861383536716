import React, { useState, useEffect } from "react";
import without from "lodash/without";
import take from "lodash/take";
import posed from "react-pose";
import styled from "@emotion/styled";

import media from "utils/media";
import useMatchMedia from "utils/hooks/useMatchMedia";

import Image from "components/elements/Image";

const Flip = posed.div({
  enter: {
    rotateX: 360,
    transition: ({ duration }) => ({ duration, ease: "backOut" }),
  },
  exit: {
    rotateX: 0,
    transition: ({ duration }) => ({ duration, ease: "backOut" }),
  },
});

export default function FlipAnimation({ images, duration, frequency }) {
  const [animate, setAnimate] = useState(false);
  const [newArr, setNewArr] = useState(images);

  const isLargeScreen = useMatchMedia("(min-width: 992px)");
  const parsedDuration = duration ? parseInt(duration, 10) : 1500;
  const parsedFrequency = frequency ? parseInt(frequency, 10) : 3000;

  function flipAnimation() {
    setTimeout(function () {
      setAnimate(true);
    }, parsedFrequency);
  }

  function handleOnComplete(animation) {
    if (animation === "enter") {
      setAnimate(false);
    } else {
      const newElements = without(images, ...newArr);
      if (newElements.length < 5) {
        const offset = 5 - newElements.length;
        const missingItems = take(images, offset);
        const test = newElements.concat(missingItems);
        return setNewArr(test);
      }
      setNewArr(newElements);
    }
  }

  useEffect(() => {
    if (isLargeScreen && !animate) {
      flipAnimation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newArr, isLargeScreen]);
  return (
    <StyledImages
      pose={animate ? "enter" : "exit"}
      onPoseComplete={handleOnComplete}
    >
      {newArr &&
        newArr.map((item, i) => (
          <Flip key={i} duration={parsedDuration}>
            <Image
              fluid
              data={item.asset}
              fadeIn
              options={{
                maxWidth: 600,
                maxHeight: 600,
                useBase64: false,
                quality: 90,
              }}
            />
          </Flip>
        ))}
    </StyledImages>
  );
}
const test = {
  enter: {
    staggerChildren: 200,
  },
  exit: {
    staggerChildren: 200,
    staggerDirection: -1,
  },
};

const StyledImages = styled(posed.div(test))`
  width: 100%;
  display: flex;
  padding: 0;

  ${media.greaterThan("xs")`
    width: 100vw;
    margin-left: calc(-100vw / 2 + 33.75rem / 2 );
    margin-right: calc(-100vw / 2 + 33.75rem / 2 );
  `};

  ${media.greaterThan("sm")`
    margin-left: calc(-100vw / 2 + 45rem / 2 );
    margin-right: calc(-100vw / 2 + 45rem / 2 );
  `};

  ${media.greaterThan("md")`
    width: 55vw;
    margin-left: auto;
    margin-right: calc(-100vw / 2 + 60rem / 2 );
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: auto auto auto;
    -ms-grid-rows: 1fr 1fr 1fr;
    grid-template-rows: auto;
  `};

  ${media.greaterThan("lg")`
    width: 70%;
    margin-right: calc(-100vw / 2 + 77rem / 2 );
  `};

  ${media.greaterThan("1920px")`
    margin-right: -350px;
  `};

  & > div {
    display: none;
    flex: 1;

    ${media.greaterThan("md")`
      position: relative;
    `};

    .gatsby-image-wrapper {
      ${media.greaterThan("md")`
        position: static !important;
        height: 100%;
      `};
    }

    &:nth-of-type(1) {
      display: block;
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    &:nth-of-type(2) {
      display: block;
      -ms-grid-row: 1;
      -ms-grid-column: 2;
    }

    &:nth-of-type(3) {
      ${media.greaterThan("xs")`
        display: block;
      `};

      ${media.greaterThan("md")`
        -ms-grid-row: 2;
        -ms-grid-column: 2;
        grid-column-start: 2;
      `};
    }

    &:nth-of-type(4) {
      ${media.greaterThan("sm")`
        display: block;
      `};

      ${media.greaterThan("md")`
        -ms-grid-row: 2;
        -ms-grid-column: 3;
      `};
    }

    &:nth-of-type(5) {
      ${media.greaterThan("md")`
        display: block;
        -ms-grid-row: 3;
        -ms-grid-column: 2;
        grid-column-start: 2;
      `};
    }
  }
`;
