import styled from "@emotion/styled";

import media from "utils/media";

const MobileNav = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  ${media.greaterThan("md")`
    display: none;
  `};

  a {
    display: block;
    width: 30px;
    height: 30px;
  }

  button {
    display: block;
    width: 30px;
    text-align: right;

    svg {
      display: block;
      width: 100%;
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

export default MobileNav;
