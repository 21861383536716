import React, { useRef } from "react";
import isEmpty from "lodash/isEmpty";

import useOnScreen from "utils/hooks/useOnScreen";
import useMatchMedia from "utils/hooks/useMatchMedia";

import Markdown from "components/elements/Markdown";
import { Container } from "components/common/Grid";

import Section from "./Section";
import Wrapper from "./Wrapper";

export default function Numbers({ data }) {
  const { numbers, background_color, heading } = data || {};
  const numberOfCols = numbers ? numbers.length : 1;
  const ref = useRef();
  const isMobile = useMatchMedia("(max-width: 576px)");
  const rootMargin = isMobile ? "-100px" : "-200px";
  const onScreen = useOnScreen(ref, rootMargin);

  return (
    <Section bg={background_color} ref={ref}>
      <Container>
        <h2>{heading}</h2>
        <Wrapper cols={numberOfCols}>
          {!isEmpty(numbers) &&
            numbers.map(
              (item) =>
                !isEmpty(item.content) && (
                  <Markdown onScreen={onScreen} key={item._uid}>
                    {item.content}
                  </Markdown>
                )
            )}
        </Wrapper>
      </Container>
    </Section>
  );
}
