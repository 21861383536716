import styled from "@emotion/styled";

import media from "utils/media";

const Title = styled.div`
  width: 100%;
  margin-bottom: 50px;

  h2 {
    line-height: 1;
  }

  .lead {
    margin: 20px 0;

    ${media.greaterThan("md")`
      margin: 35px 0;
    `};
  }
`;

export default Title;
