import styled from "@emotion/styled";

import { Column } from "components/common/Grid";

import media from "utils/media";

const Content = styled(Column)`
  margin-bottom: 21px;

  ${media.greaterThan("xs")`
    margin-bottom: 40px;
  `};

  h2 {
    font-size: 60px;
    line-height: 64px;
    color: ${({ theme }) => theme.colors.black};
    margin-bottom: 50px;
  }

  .lead {
    font-size: 24px;
    margin-bottom: 34px;
  }
`;

export default Content;
