import styled from "@emotion/styled";

import media from "utils/media";

const Wrapper = styled.footer`
  padding: 75px 0;
  background-color: ${({ theme }) => theme.colors.brand};
  color: ${({ theme }) => theme.colors.white};

  ${media.greaterThan("md")`
    padding: 84px 0 120px;
  `};
`;

export default Wrapper;
