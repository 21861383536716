import styled from "@emotion/styled";

import media from "utils/media";

const Success = styled.div`
  opacity: ${({ copied }) => (copied ? 1 : 0)};
  position: relative;
  width: 50%;
  margin-left: auto;
  margin-right: 10px;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.blue_v2};
  color: #130b46;
  padding: 10px 20px;
  border-radius: 20px;
  transition: opacity 0.3s ease;
`;

export default Success;
