import React, { useState, useEffect, useRef } from "react";
import sample from "lodash/sample";

import Image from "components/elements/Image";

import Wrapper from "./Wrapper";
import StyledImage from "./StyledImage";

export default function AnimatedImages({ images }) {
  const animationInterval = useRef(null);
  const [animatedElement, setAnimatedElement] = useState(null);

  useEffect(() => {
    animationInterval.current = setInterval(() => {
      const filteredImages = images.filter(
        (item) => item._uid !== animatedElement
      );
      const sampledID = sample(filteredImages)._uid;
      setAnimatedElement(sampledID);
    }, 3000);

    return () => {
      clearInterval(animationInterval.current);
    };
  }, [animatedElement, images]);
  return (
    <Wrapper>
      {images.map((item) => (
        <StyledImage
          key={item._uid}
          pose={animatedElement === item._uid ? "hidden" : "visible"}
        >
          <Image
            fluid
            data={item.asset}
            alt=""
            options={{
              useBase64: false,
              quality: 90,
            }}
          />
        </StyledImage>
      ))}
    </Wrapper>
  );
}
