import React, { useState, useEffect, useRef } from "react";
import isEmpty from "lodash/isEmpty";

import { Container, Row, Column } from "components/common/Grid";
import Markdown from "components/elements/Markdown";

import useMatchMedia from "utils/hooks/useMatchMedia";
import scrollTo from "utils/scrollTo";

import Section from "./Section";
import Image from "./Image";
import ScrollBtn from "./ScrollBtn";
import Wrapper from "./Wrapper";
import Content from "./Content";

import { getFluidGatsbyImage } from "gatsby-storyblok-image";

export default function HeroWithImage({ data }) {
  const {
    additional_bgColor,
    content,
    image,
    image_mobile,
    main_bgColor,
    scroll_label,
    is_animation,
    animation_words,
  } = data;

  const [nextSibling, setNextSibling] = useState(null);
  const isMobileScreen = useMatchMedia("(max-width: 576px)");

  const section = useRef();

  useEffect(() => {
    if (section && !isEmpty(section.current)) {
      const target = section.current.nextSibling;
      setNextSibling(target);
    }
  }, []);

  const scrollNextSection = () => {
    if (nextSibling) {
      return scrollTo(nextSibling, -70);
    }
    return null;
  };

  if (image_mobile && !isEmpty(image_mobile.image)) {
    getFluidGatsbyImage(image_mobile, {
      quality: 80,
      smartCrop: false,
      maxWidth: 1300,
      downloadLocal: true,
    });
  }

  return (
    <Section mainBg={main_bgColor} ref={section}>
      <Wrapper mainBg={additional_bgColor}>
        {isMobileScreen && !isEmpty(image_mobile.image) ? (
          <Image
            data={image_mobile}
            isAnimation={is_animation}
            words={animation_words}
          />
        ) : (
          !isEmpty(image.image) && (
            <Image
              data={image}
              isAnimation={is_animation}
              words={animation_words}
            />
          )
        )}
        <ScrollBtn label={scroll_label} onClick={scrollNextSection} />
      </Wrapper>
      {!isEmpty(content) && (
        <Container>
          <Row>
            <Column>
              <Content>
                <Markdown>{content}</Markdown>
              </Content>
            </Column>
          </Row>
        </Container>
      )}
    </Section>
  );
}
