import styled from "@emotion/styled";

import media from "utils/media";

export const MainNavElement = styled.li`
  margin-right: 90px;
  font-family: ${({ theme }) => theme.fonts.headerFont};
  font-weight: bold;
  opacity: ${({ hover }) => (hover === "inactive" ? "0.3" : 1)};
  transition: opacity 0.3s ease-in-out;

  &:last-of-type {
    margin-right: 0;
  }

  &:after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    transition: width 0.3s;
  }

  &:hover:after {
    width: 100%;
  }

  a {
    display: block;
    font-family: ${({ theme }) => theme.fonts.headerFont};
    font-weight: bold;
    line-height: 42px;
  }
`;

export const MainNav = styled.nav`
  display: none;

  ${media.greaterThan("sm")`
    display: block;
  `};

  & > ul {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${MainNavElement} {
    color: ${({ fixed, theme }) =>
      fixed ? theme.colors.brand : theme.colors.headerColor};

    &:after {
      background: ${({ fixed, theme }) =>
        fixed ? theme.colors.brand : theme.colors.headerColor};
    }
  }
`;
