import React, { useState } from "react";
import styled from "@emotion/styled";
import isEmpty from "lodash/isEmpty";
import { Link as GatsbyLink } from "gatsby";

import media from "utils/media";

import Markdown from "components/elements/Markdown";
import Link from "components/elements/Link";
import Image from "components/elements/Image";

import Modal from "./Modal";

import closeIco from "assets/svg/close-white.svg";
import logoIco from "assets/svg/logo-vertex-white.svg";

export default function Project({
  name,
  description,
  link,
  small,
  hover_color,
  category,
  logo_black,
  logo_white,
}) {
  const [open, setOpen] = useState(false);
  const validatedColor =
    !isEmpty(hover_color) && !isEmpty(hover_color.color)
      ? hover_color.color
      : "#007bf8";
  // const isHover = category && category !== "exited";
  function handleModal(e) {
    e.preventDefault();
    setOpen(!open);
  }

  return (
    <StyledProject bg={validatedColor}>
      <div onClick={small ? handleModal : null}>
        <div className="black">
          <Image
            fluid
            objectFit="contain"
            fadeIn
            data={logo_black}
            options={{
              maxWidth: 200,
              quality: 100,
              useBase64: false,
            }}
          />
        </div>
        <div className="white">
          <Image
            fluid
            objectFit="contain"
            fadeIn
            data={logo_white}
            options={{
              maxWidth: 200,
              quality: 100,
              useBase64: false,
            }}
          />
        </div>
        <div>{description && <Markdown>{description}</Markdown>}</div>
        {!isEmpty(link) && !isEmpty(link[0]) && <Link {...link[0]} />}
      </div>
      {small && (
        <Modal open={open} onClose={handleModal} center showCloseIcon={false}>
          <nav>
            <GatsbyLink to="/">
              <img src={logoIco} alt="logo" />
            </GatsbyLink>
            <button onClick={handleModal} aria-label="Close modal">
              <img src={closeIco} alt="close icon" />
            </button>
          </nav>
          <h2>{name}</h2>
          {description && <Markdown>{description}</Markdown>}
          {!isEmpty(link) && !isEmpty(link[0]) && <Link {...link[0]} />}
        </Modal>
      )}
    </StyledProject>
  );
}

const StyledProject = styled.div`
  width: 50%;
  min-height: 200px;
  overflow: hidden;
  transition: background-color 0.3s;

  ${media.greaterThan("sm")`
    height: 300px;
  `};

  ${media.greaterThan("md")`
    width: calc(100% / 3);
    height: calc(100vw / 3);
  `};

  ${media.greaterThan("lg")`
    width: 25%;
    height: 25vw;
  `};

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    outline: none;
    padding: 0 20px;
    text-align: left;
    user-select: text;

    ${media.greaterThan("sm")`
      cursor: initial;
    `};

    .black {
      width: 110px;
      max-height: 120px;
      opacity: 0.3;

      ${media.greaterThan("md")`
        width: 150px;
        max-height: 150px;
      `};

      ${media.greaterThan("lg")`
        width: 200px;
      `};

      .gatsby-image-wrapper {
        max-height: 120px;

        ${media.greaterThan("md")`
          max-height: 150px;
        `};
      }
    }

    .white {
      width: 110px;
      max-height: 90px;
      display: none;

      ${media.greaterThan("md")`
        max-height: 90px;
        width: 110px;
      `};

      ${media.greaterThan("lg")`
        width: 90px;
      `};

      .gatsby-image-wrapper {
        max-height: 90px;

        ${media.greaterThan("md")`
          max-height: 90px;
        `};
      }
    }

    h3 {
      font-weight: bold;
      line-height: 1;
    }

    & > div:nth-of-type(3) {
      margin: 22px 0;
      font-size: 14px;
      overflow: hidden;
      max-height: 150px;
    }

    a {
      font-family: ${({ theme }) => theme.fonts.headerFont};
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    p {
      ${media.greaterThan("md")`
        font-size: 16px;
        line-height: 1.5;
      `};
    }

    & > h3,
    & > div:nth-of-type(3),
    & > a {
      display: none;
    }
  }

  &:hover {
    ${({ bg }) => media.greaterThan("md")`
      box-shadow: 0 12px 20px -3px rgba(0, 41, 76, 0.2);
      background-color: ${bg};

      & > div {
        flex-direction: column;
        align-items: center;
        text-align: center;

        .black {
          display: none;
        }

        .white {
          display: block;
        }


        h3,
        div:nth-of-type(3),
        a {
          display: block;
        }
      }
    `};
  }
`;
