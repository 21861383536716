import React, { useRef } from "react";
import isEmpty from "lodash/isEmpty";
import styled from "@emotion/styled";

import useChildrensHeight from "utils/hooks/useChildrensHeight";

import media from "utils/media";

import { Container, Row, Column } from "components/common/Grid";
import Markdown from "components/elements/Markdown";
import ImageComponent from "components/elements/Image";

import Section from "./Section";
import Image from "./Image";
import Content from "./Content";

export default function ImageContent({ data }) {
  const contentRef = useRef(null);
  const height = useChildrensHeight(contentRef);

  const { variant, heading, lead, image, content, section_id } = data || {};
  const isVertical = variant === "vertically" || variant !== "horizontally";

  return (
    <Section className="image-content" id={section_id}>
      <Container>
        {variant === "horizontally" ? (
          <Row alignItems="center">
            <Column>
              <Heading className="h1" vertical={isVertical}>
                {heading}
              </Heading>
              <Lead className="lead">{lead}</Lead>
            </Column>
            <Image
              width={[1, 1, 6 / 12]}
              order={[2, 2, 1]}
              mediaHeight={height}
            >
              {!isEmpty(image) && (
                <ImageComponent
                  fluid
                  data={image}
                  options={{ maxWidth: 1920, quality: 90, useBase64: false }}
                />
              )}
            </Image>
            <Content
              ref={contentRef}
              width={[1, 1, 5 / 12]}
              order={[1, 1, 2]}
              ml={[0, 0, `${(1 / 12) * 100}%`]}
              vertical={isVertical}
            >
              {!isEmpty(content) && <Markdown>{content}</Markdown>}
            </Content>
          </Row>
        ) : (
          <Row>
            <Column>
              <Heading className="h1" vertical={isVertical}>
                {heading}
              </Heading>
              <Lead className="lead">{lead}</Lead>
            </Column>
            {!isEmpty(image.image) && (
              <Image mb={5} className="vertical">
                <ImageComponent
                  fluid
                  data={image}
                  options={{ maxWidth: 1920, quality: 90, useBase64: false }}
                />
              </Image>
            )}
            <Content ref={contentRef} vertical={isVertical}>
              {!isEmpty(content) && <Markdown>{content}</Markdown>}
            </Content>
          </Row>
        )}
      </Container>
    </Section>
  );
}

const Heading = styled.h2`
  margin-bottom: ${({ vertical }) => (vertical ? "25px" : "50px")};
  line-height: 1;
`;

const Lead = styled.p`
  margin: 20px 0;

  ${media.greaterThan("md")`
    margin: 35px 0;
  `};
`;
