import React from "react";
import styled from "@emotion/styled";

import { Row, Column } from "components/common/Grid";

import useMemberModal from "utils/hooks/useMemberModal";

import Member from "./Member";
import Modal from "./Modal";

export default function MembersTable({ members }) {
  const [
    open,
    member,
    handleOpenModal,
    handleCloseModal,
    nextMember,
    prevMember,
  ] = useMemberModal(members);

  return (
    <div>
      <Row justifyItems="space-between">
        {members.map((item) => (
          <StyledColumn width={1 / 3} my={4} key={item._uid}>
            <Member key={item._uid} data={item} onClick={handleOpenModal} />
          </StyledColumn>
        ))}
      </Row>
      {members && members.length && (
        <Modal
          member={member}
          open={open}
          close={handleCloseModal}
          next={nextMember}
          prev={prevMember}
          onClose={handleCloseModal}
          center
          showCloseIcon={false}
          blockScroll={false}
        />
      )}
    </div>
  );
}

const StyledColumn = styled(Column)`
  margin-top: 0;
  margin-bottom: 0;

  &:nth-of-type(3n + 2) {
    margin-top: 50px;
  }
`;
