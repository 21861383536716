import React, { useRef, useEffect } from "react";
import styled from "@emotion/styled";
import Modal from "react-responsive-modal";
import isEmpty from "lodash/isEmpty";
import { Link } from "gatsby";

import media from "utils/media";

import Image from "components/elements/Image";
import SocialLink from "components/elements/SocialLink";
import Markdown from "components/elements/Markdown";

import StyledModal from "./StyledModal";
import Content from "./Content";
import Title from "./Title";
import MobileNav from "./MobileNav";

import logoIco from "assets/svg/logo-vertex-white.svg";
import { ReactComponent as CloseSvgIcon } from "assets/svg/close.svg";
import { ReactComponent as ArrowLeft } from "assets/svg/arrow_left.svg";
import { ReactComponent as ArrowRight } from "assets/svg/arrow_right.svg";

export default function ModalComponent({ member, next, prev, close, ...rest }) {
  const {
    _uid: id,
    name,
    position,
    image,
    socials,
    description,
  } = member || {};
  const wrapperRef = useRef();
  const contentRef = useRef();

  useEffect(() => {
    if (contentRef.current && contentRef.current.scrollTop) {
      contentRef.current.scrollTop = 0;
    }
  }, [member]);

  function handleNext() {
    next(id);
  }

  function handlePrev() {
    prev(id);
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 39) {
      return next(id);
    } else if (e.keyCode === 37) {
      return prev(id);
    }
    return null;
  };

  return (
    <>
      <Wrapper ref={wrapperRef} onKeyDown={handleKeyDown} tabIndex={0} />
      <Modal
        {...rest}
        focusTrapped
        focusTrapOptions={{
          initialFocus: wrapperRef.current,
        }}
        container={wrapperRef.current}
        classNames={classNamesModal}
      >
        <MobileNav>
          <Link to="/">
            <img src={logoIco} alt="logo" />
          </Link>
          <button onClick={close} aria-label="Close">
            <CloseSvgIcon />
          </button>
        </MobileNav>
        <StyledModal>
          <Image
            fluid
            data={image}
            options={{ maxWidth: 935, useBase64: false, quality: 90 }}
          />
          <div>
            <Title>
              <nav>
                <div>
                  <button
                    rel="prev"
                    aria-label="Previous slide"
                    onClick={handlePrev}
                    onKeyDown={handleKeyDown}
                  >
                    <ArrowLeft />
                  </button>
                  <button
                    rel="next"
                    aria-label="Next slide"
                    onClick={handleNext}
                    onKeyDown={handleKeyDown}
                  >
                    <ArrowRight />
                  </button>
                </div>
                <button onClick={close} aria-label="Close">
                  <CloseSvgIcon />
                </button>
              </nav>
              <span>{position}</span>
              <h2>{name}</h2>
              <ul>
                {!isEmpty(socials) &&
                  socials.map((item) => (
                    <li key={item._uid}>
                      <SocialLink {...item} />
                    </li>
                  ))}
              </ul>
            </Title>
            <Content ref={contentRef}>
              {description && <Markdown>{description}</Markdown>}
            </Content>
          </div>
        </StyledModal>
      </Modal>
    </>
  );
}

const classNamesModal = {
  overlay: "team-overlay",
  modal: "team-modal",
  transitionEnter: "team-tr-enter",
  transitionEnterActive: "team-tr-enter-active",
};

const Wrapper = styled.div`
  & > div {
    padding: 48px 26px 40px;

    ${media.greaterThan("md")`
      padding: 0 63px;
    `};
  }

  .team-overlay {
    box-shadow: 0 12px 20px -3px rgba(0, 41, 76, 0.2);
    background-color: rgba(0, 123, 248, 0.9);
  }

  .react-responsive-modal-container {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .team-modal {
    padding: 0;
    margin: auto;
    background: none;
    box-shadow: none;
    width: 100%;
    max-width: 600px;

    ${media.greaterThan("md")`
      max-width: 100%;

      & > div {
        height: 82vh;
        max-height: 700px;
      }
    `};

    ${media.greaterThan("lg")`
      max-width: 1300px;
    `};
  }
`;
