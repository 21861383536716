import styled from "@emotion/styled";

import media from "utils/media";

const Section = styled.section`
  padding-top: 71px;
  padding-bottom: 79px;
  background-color: ${({ bg }) => bg};

  ${media.greaterThan("md")`
    padding-top: 140px;
    padding-bottom: 150px;
  `};

  h2 {
    text-align: center;
    margin-bottom: 50px;
  }
`;

export default Section;
