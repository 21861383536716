import { navigate } from "gatsby";
import React from "react";
import { formatDateForPostDisplay } from "utils/date";

import Title from "./Title";

export default function PrimaryInfo({ postTitle, postAuthor, postDate }) {
  const { content } = postAuthor || {};

  return (
    <Title>
      <button
        onClick={() => navigate("/news")}
        style={{ cursor: "pointer" }}
        aria-label="Back to news"
      >
        <span>News</span>
      </button>
      <h1>{postTitle}</h1>
      <div>
        {content?.avatar.filename && (
          <img src={content?.avatar.filename} alt="author of post" />
        )}
        <p>
          <span>{content?.name}</span>
          <span>posted on {formatDateForPostDisplay(postDate)}</span>
        </p>
      </div>
    </Title>
  );
}
