import styled from "@emotion/styled";
import posed from "react-pose";

import media from "utils/media";

const Box = posed.div({
  hidden: {
    opacity: 0,
    transition: { duration: 1500 },
  },
  visible: {
    opacity: 1,
    transition: { duration: 1500 },
  },
});

const StyledBox = styled(Box)`
  width: 25%;
  height: 25vw;

  ${media.greaterThan("lg")`
    height: 20vw;
  `};

  ${media.greaterThan("1920px")`
    height: 15vw;
  `};

  .gatsby-image-wrapper {
    height: 100%;
  }
`;

export default StyledBox;
