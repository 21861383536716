import styled from "@emotion/styled";

import media from "utils/media";

const Section = styled.section`
  padding-top: 80px;
  padding-bottom: 50px;

  ${media.greaterThan("sm")`
    padding-top: 120px;
    padding-bottom: 60px;
  `};

  ${media.greaterThan("md")`
    padding-top: 160px;
    padding-bottom: 50px;
  `};
`;

export default Section;
