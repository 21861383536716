import React from "react";
import isEmpty from "lodash/isEmpty";

import { Container, Row } from "components/common/Grid";
import Markdown from "components/elements/Markdown";
import Link from "components/elements/Link";

import Section from "./Section";
import Content from "./Content";

export default function AbousUs_v3({ data }) {
  const { heading, lead, content, button } = data || {};

  return (
    <Section id="about">
      <Container>
        <Row>
          <Content>
            <h2 className="h1">{heading}</h2>
            <p className="lead">{lead}</p>
            {content && (
              <div>
                <Markdown>{content}</Markdown>
              </div>
            )}
            {!isEmpty(button) && !isEmpty(button[0]) && <Link {...button[0]} />}
          </Content>
        </Row>
      </Container>
    </Section>
  );
}
