export default {
  langLabel: "EN",
  projectCategory: {
    all: "all",
    active: "active",
    biotech: "biotech",
    medtech: "medtech",
    internet: "internet",
    technology: "technology",
    consumer: "consumer",
    healthcare: "healthcare",
    deeptech: "deep tech",
    digitalEconomy: "new digital economy",
    fintech: "fintech",
    enterprise: "enterprise",
    exited: "exited",
  },
};
