import styled from "@emotion/styled";

import media from "utils/media";

const Wrapper = styled.div`
  background-color: ${({ mainBg }) => mainBg || "#FFF"};
  width: 78%;
  margin-left: auto;
  padding: 98px 26px 46px 0;

  ${media.greaterThan("xs")`
    padding-right: calc(100vw / 2 - 33.75rem / 2 + 26px);
  `};

  ${media.greaterThan("sm")`
    padding-right: calc(100vw / 2 - 45rem / 2 + 26px);
  `};

  ${media.greaterThan("md")`
    position: relative;
    padding: 123px 26px 98px 0;
    padding-right: calc(100vw / 2 - 60rem / 2 + 26px);
    width: 66%;
  `};

  ${media.greaterThan("lg")`
    padding-right: calc(100vw / 2 - 77rem / 2 + 26px);
  `};
`;

export default Wrapper;
