import React, { useRef } from "react";
import isEmpty from "lodash/isEmpty";

import useChildrensHeight from "utils/hooks/useChildrensHeight";

import { Container, Row } from "components/common/Grid";
import Markdown from "components/elements/Markdown";

import Section from "./Section";
import Content from "./Content";
import Images from "./Images";
import AnimatedImages from "./AnimatedImages";

export default function AboutUs({ data }) {
  const contentRef = useRef(null);
  const contentHeight = useChildrensHeight(contentRef);

  const {
    heading,
    lead,
    content,
    images,
    is_animation,
    animation_duration,
    animation_frequency,
    animation_type,
  } = data || {};
  const validatedImages = !isEmpty(images) && images;

  return (
    <Section id="about" offsetHeight={contentHeight}>
      <Container>
        <Row style={{ position: "relative" }}>
          <Content width={[1, 1, 3 / 4, 5 / 9, 1 / 2]} ref={contentRef}>
            <h2 className="h1">{heading}</h2>
            <p className="lead">{lead}</p>
            {content && <Markdown>{content}</Markdown>}
          </Content>
          {is_animation
            ? validatedImages && (
                <AnimatedImages
                  images={validatedImages}
                  frequency={animation_frequency}
                  duration={animation_duration}
                  type={animation_type}
                />
              )
            : validatedImages && <Images images={validatedImages} />}
        </Row>
      </Container>
    </Section>
  );
}
