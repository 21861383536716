import styled from "@emotion/styled";

import media from "utils/media";

const Section = styled.section`
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: ${({ bg }) => bg || "#FFF"};

  ${media.greaterThan("md")`
    padding-top: 100px;
    padding-bottom: 120px;
  `};
`;

export default Section;
