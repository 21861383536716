import styled from "@emotion/styled";

import media from "utils/media";

export const Title = styled.span`
  position: relative;
  width: 100%;
  padding-right: 20px;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-items: center;
  font-size: 30px;
  line-height: 42px;

  ${({ mobile }) => media.greaterThan("sm")`
    font-size: ${mobile ? "68px" : "16px"};
    font-weight: ${mobile ? "300" : "bold"};
    padding-right: ${mobile ? "30px" : "0"};;
    line-height: ${mobile ? "1.5" : "42px"};
  `};

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-top: 5px solid ${({ theme }) => theme.colors.white};
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    ${({ theme, mobile }) => media.greaterThan("sm")`
      display: ${mobile ? "block" : "none"};
      border-top: ${
        mobile
          ? `10px solid ${theme.colors.white}`
          : `5px solid ${theme.colors.white}`
      };
      border-left: ${
        mobile ? `10px solid transparent` : `5px solid transparent`
      };
      border-right: ${
        mobile ? `10px solid transparent` : `5px solid transparent`
      };
    `};
  }
`;

export const List = styled.div`
  height: 0;
  transition: height 250ms ease-in-out;
  overflow: hidden;
  color: ${({ theme, fixed }) =>
    fixed ? theme.colors.brand : theme.colors.white};

  ${({ theme, fixed }) => media.greaterThan("sm")`
    width: 200px;
    position: absolute;
    top: 45px;
    z-index: 200;
    transform: translateX(-71px);
    transform-origin: top left;
    background-color: ${fixed ? theme.colors.white : theme.colors.blue_v1};
    opacity: 0.9;
    box-shadow: 0 12px 20px -3px rgba(0, 41, 76, 0.2);
  `};

  ul {
    width: 100%;
    list-style: none;
    display: block;
    text-align: center;
    margin: 0;

    ${media.greaterThan("sm")`
      text-align: right;
    `};

    li {
      ${({ fixed }) => media.greaterThan("sm")`
        margin-right: 0;
        height: 70px;
        padding-right: 30px;
        border-bottom: ${
          fixed
            ? "1px solid rgba(4, 63, 190, 0.1)"
            : "1px solid rgba(255,255,255,0.1)"
        };
      `};

      & + li {
        margin-top: 10px;

        ${media.greaterThan("sm")`
          margin-top: 0;
        `};
      }

      a {
        display: block;
        width: 100%;
        font-size: 20px;
        font-weight: bold;
        font-family: ${({ theme }) => theme.fonts.headerFont};
        line-height: 70px;

        ${media.greaterThan("sm")`
          font-size: 16px;
        `};
      }
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  ${media.greaterThan("sm")`
    width: auto;
    height: 100%;
  `};

  &.open {
    ${({ height, offset, theme }) => media.lessThan("767px")`
      ${List} {
        position: static;
        height: ${`${height + offset * 10}px`};
      }

      ${Title} {
        &:after {
          top: 15px;
          border-top: 5px solid transparent;
          border-bottom: 5px solid ${theme.colors.white};
        }
      }
    `};
  }

  &:hover {
    ${({ height, theme }) => media.greaterThan("sm")`
      ${List} {
        position: absolute;
        height: ${`${height}px`};
      }

      ${Title} {
        &:after {
          top: 15px;
          border-top: 5px solid transparent;
          border-bottom: 5px solid ${theme.colors.white};
        }
      }
    `};
  }
`;

export const MobileList = styled.div`
  height: 0;
  transition: height 250ms ease-in-out;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.white};

  ul {
    width: 100%;
    list-style: none;
    display: block;
    text-align: center;
    margin: 0;

    li {
      & + li {
        margin-top: 10px;
      }

      a {
        display: block;
        width: 100%;
        font-size: 20px;
        font-weight: bold;
        font-family: ${({ theme }) => theme.fonts.headerFont};
        line-height: 70px;
      }
    }
  }
`;

export const MobileWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  &.open {
    ${MobileList} {
      position: static;
      height: ${({ height, offset }) => `${height + offset * 10}px`};
    }

    ${Title} {
      &:after {
        border-top: 5px solid transparent;
        border-bottom: ${({ theme }) => `5px solid ${theme.colors.white}`};

        ${({ theme }) => media.greaterThan("sm")`
          border-top: 10px solid transparent;
          border-bottom: ${`10px solid ${theme.colors.white}`};
        `}
      }
    }
  }
`;
