import styled from "@emotion/styled";

import media from "utils/media";

const StyledImages = styled.div`
  width: 100%;
  display: flex;
  padding: 0;

  ${media.greaterThan("xs")`
    width: 100vw;
    margin-left: calc(-100vw / 2 + 33.75rem / 2 );
    margin-right: calc(-100vw / 2 + 33.75rem / 2 );
  `};

  ${media.greaterThan("sm")`
    margin-left: calc(-100vw / 2 + 45rem / 2 );
    margin-right: calc(-100vw / 2 + 45rem / 2 );
  `};

  ${media.greaterThan("md")`
    width: 55vw;
    margin-left: auto;
    margin-right: calc(-100vw / 2 + 60rem / 2 );
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: auto auto auto;
    -ms-grid-rows: 1fr 1fr 1fr;
    grid-template-rows: auto;
  `};

  ${media.greaterThan("lg")`
    width: 70%;
    margin-right: calc(-100vw / 2 + 77rem / 2 );
  `};

  ${media.greaterThan("1920px")`
    margin-right: -350px;
  `};

  & > div {
    display: none;
    flex: 1;

    ${media.greaterThan("md")`
      position: relative;
    `};

    .gatsby-image-wrapper {
      ${media.greaterThan("md")`
        position: static !important;
        height: 100%;
      `};
    }

    &:nth-of-type(1) {
      display: block;
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    &:nth-of-type(2) {
      display: block;
      -ms-grid-row: 1;
      -ms-grid-column: 2;
    }

    &:nth-of-type(3) {
      ${media.greaterThan("xs")`
        display: block;
      `};

      ${media.greaterThan("md")`
        -ms-grid-row: 2;
        -ms-grid-column: 2;
        grid-column-start: 2;
      `};
    }

    &:nth-of-type(4) {
      ${media.greaterThan("sm")`
        display: block;
      `};

      ${media.greaterThan("md")`
        -ms-grid-row: 2;
        -ms-grid-column: 3;
      `};
    }

    &:nth-of-type(5) {
      ${media.greaterThan("md")`
        display: block;
        -ms-grid-row: 3;
        -ms-grid-column: 2;
        grid-column-start: 2;
      `};
    }
  }
`;

export default StyledImages;
