import styled from "@emotion/styled";

import { Column } from "components/common/Grid";

import media from "utils/media";

const Menu = styled(Column)`
  display: grid;
  padding-bottom: 30px;

  h3 {
    margin-bottom: 17px;
  }

  ul {
    display: flex;
  }

  ul:first-of-type {
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0;

    ${media.greaterThan("xs")`
      justify-content: flex-start;
      flex-direction: column;
    `};

    li {
      margin-bottom: 29px;
      margin-right: 44px;

      ${media.greaterThan("xs")`
        margin-right: 0;
      `};

      &:last-child {
        margin-bottom: 0;
        margin-right: 0;
      }

      a {
        text-decoration: underline;
        letter-spacing: 0.1px;
        color: ${({ theme }) => theme.colors.blue_v2};

        ${media.greaterThan("md")`
          text-decoration: none;
        `};
      }
    }
  }

  ul:last-of-type {
    margin: 60px 0;
    align-items: center;

    ${media.greaterThan("md")`
      margin: 29px 0;
    `};

    &.single {
      ${media.greaterThan("md")`
        margin-top: 0;
      `};
    }

    li {
      width: 24px;
      height: 24px;

      & + li {
        margin-left: 48px;

        ${media.greaterThan("md")`
          margin-left: 13px;
        `};
      }

      a {
        display: block;
        width: 24px;
        height: 24px;

        ${media.greaterThan("md")`
          opacity: .3;
        `};

        svg {
          fill: ${({ theme }) => theme.colors.white};
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;

export default Menu;
