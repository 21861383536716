import React from "react";
import isEmpty from "lodash/isEmpty";

import { Container } from "components/common/Grid";
import Link from "components/elements/Link";

import Section from "./Section";

export default function Text({ data }) {
  const { heading, lead, link, background_color, text_color } = data || {};

  return (
    <Section bg={background_color} color={text_color}>
      <Container>
        <h2>{heading}</h2>
        <p>{lead}</p>
        {!isEmpty(link) && !isEmpty(link[0]) && (
          <Link {...link[0]} className="button" />
        )}
      </Container>
    </Section>
  );
}
