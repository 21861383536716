// common Components
import Page from "components/common/Page";
import Tag from "components/common/Tag";
import Post from "components/common/Post";
import Layout from "components/common/Layout";
import NotFound from "components/NotFound";

import HeroWithImage from "components/HeroWithImage";
import HeroWithImage_v2 from "components/HeroWithImage_v2";
import HeroWithAnimation from "components/HeroWithAnimation";
import HeroWithVideo from "components/HeroWithVideo";
import AboutUs from "components/AboutUs";
import AboutUs_v2 from "components/AboutUs_v2";
import AboutUs_v3 from "components/AboutUs_v3";
import Numbers from "components/Numbers";
import Text from "components/Text";
import Portfolio from "components/Portfolio";
import MultiPortfolio from "components/MultiPortfolio";
import ImageContent from "components/ImageContent";
import NewsSection from "components/NewsSection";
import NewsMain from "components/NewsMain";
import Team from "components/Team";
import Team_v2 from "components/Team_v2";
import HeadingContent from "components/HeadingContent";
import SingleTestimonial from "components/SingleTestimonial";
import LongTestimonial from "components/LongTestimonial";
import Testimonials from "components/Testimonials";
import BackToHome from "components/BackToHome";
import RelatedLinks from "components/RelatedLinks";
import DownloadSection from "components/DownloadSection";
import Features from "components/Features";
import GlobalPlatform from "components/GlobalPlatform";

export default {
  common_page: Page,
  common_tag: Tag,
  common_post: Post,
  common_layout: Layout,
  component_404: NotFound,
  component_heroWithImage: HeroWithImage,
  component_heroWithImage_v2: HeroWithImage_v2,
  component_heroWithAnimation: HeroWithAnimation,
  component_heroWithVideo: HeroWithVideo,
  component_aboutUs: AboutUs,
  component_aboutUs_v2: AboutUs_v2,
  component_aboutUs_v3: AboutUs_v3,
  component_numbers: Numbers,
  component_text: Text,
  component_portfolio: Portfolio,
  component_multiPortfolio: MultiPortfolio,
  component_imageContent: ImageContent,
  component_newsSection: NewsSection,
  component_newsMain: NewsMain,
  component_team: Team,
  component_team_v2: Team_v2,
  component_headingContent: HeadingContent,
  component_singleTestimonial: SingleTestimonial,
  component_longTestimonial: LongTestimonial,
  component_testimonials: Testimonials,
  component_backToHome: BackToHome,
  component_relatedLinks: RelatedLinks,
  component_downloadSection: DownloadSection,
  component_features: Features,
  component_globalPlatform: GlobalPlatform,
};
