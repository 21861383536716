import styled from "@emotion/styled";

import media from "utils/media";

const Section = styled.section`
  position: relative;
  padding-top: 83px;
  padding-bottom: 63px;
  overflow: hidden;

  ${({ offsetHeight }) => media.greaterThan("md")`
    padding-top: 0;
    min-height: ${offsetHeight ? `calc(18vw + ${offsetHeight}px)` : "auto"};
  `};

  ${({ offsetHeight }) => media.greaterThan("lg")`
    padding-bottom: 0;
    min-height: ${offsetHeight ? `calc(24vw + ${offsetHeight}px)` : "auto"};
  `};

  ${({ offsetHeight }) => media.greaterThan("1500px")`
    min-height: ${offsetHeight ? `${offsetHeight + 300}px` : "auto"};
  `};
`;

export default Section;
