import React, { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import throttle from "lodash/throttle";
import ReactPlayer from "react-player";

import Image from "components/elements/Image";
import { Container, Row, Column } from "components/common/Grid";
import Markdown from "components/elements/Markdown";

import Section from "./Section";
import ImageWrapper from "./ImageWrapper";
import VideoWrapper from "./VideoWrapper";
import Lead from "./Lead";

export default function HeroWithVideo({ data }) {
  const [play, setPlay] = useState(true);
  const [hover, setHover] = useState(true);
  const { poster, video, lead, lead_bg } = data || {};

  useEffect(() => {
    let timeout;

    if (play && hover) {
      timeout = setTimeout(function () {
        setHover(false);
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [hover, play]);

  function handleOnPlay() {
    setPlay((state) => !state);
  }

  function handleOnHover(opt) {
    setHover(opt);
  }

  function handleOnMove() {
    if (play && !hover) {
      setHover(true);
    }
  }

  const throttledOnMove = throttle(handleOnMove, 300);

  return (
    <Section bg={lead_bg}>
      <VideoWrapper
        visible={!play}
        hover={hover}
        onMouseLeave={() => handleOnHover(false)}
        onMouseMove={throttledOnMove}
      >
        {!isEmpty(poster) && (
          <ImageWrapper visible={!play}>
            <Image
              fluid
              data={poster}
              options={{ maxWidth: 1920, quality: 100, useBase64: false }}
            />
          </ImageWrapper>
        )}
        {!isEmpty(video) && (
          <>
            <div className="video">
              <ReactPlayer
                url={video}
                playing={play}
                width="100%"
                height="100%"
                className="react-player"
                muted
                loop
              />
            </div>
            {/* {play ? (
              <button className="pause" onClick={handleOnPlay} />
            ) : (
              <button className="play" onClick={handleOnPlay} />
            )} */}
          </>
        )}
      </VideoWrapper>
      {!isEmpty(lead) && (
        <Container>
          <Row>
            <Column>
              <Lead>
                <Markdown>{lead}</Markdown>
              </Lead>
            </Column>
          </Row>
        </Container>
      )}
    </Section>
  );
}
