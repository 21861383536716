import React from "react";
import isEmpty from "lodash/isEmpty";

import Image from "components/elements/Image";
import { Container, Row, Column } from "components/common/Grid";
import Markdown from "components/elements/Markdown";

import Section from "./Section";
import StyledImage from "./StyledImage";
import Animation from "./Animation";
import Content from "./Content";

export default function HeroWithAnimation({ data }) {
  const { background, is_animation, lead, lead_bg, animation_words } =
    data || {};

  return (
    <Section bg={lead_bg}>
      {!isEmpty(background) && (
        <StyledImage isAnimation={is_animation}>
          <Image
            fluid
            data={background}
            options={{ quality: 100, useBase64: false }}
            // options={{ maxWidth: 1920, quality: 100, useBase64: false }}
          />
          {is_animation && !isEmpty(animation_words) && (
            <Animation words={animation_words} />
          )}
        </StyledImage>
      )}
      {!isEmpty(lead) && (
        <Container>
          <Row>
            <Column>
              <Content>
                <Markdown>{lead}</Markdown>
              </Content>
            </Column>
          </Row>
        </Container>
      )}
    </Section>
  );
}
