import styled from "@emotion/styled";

import { Column } from "components/common/Grid";

import media from "utils/media";

const Title = styled(Column)`
  margin-bottom: 44px;
  width: 100%;

  ${media.greaterThan("md")`
    padding: 0 130px;
  `};

  ${media.greaterThan("lg")`
    padding: 0 170px;
  `};

  a,
  button {
    opacity: 0.7;
    font-size: 26px;
    font-family: ${({ theme }) => theme.fonts.headerFont};
    line-height: 1;

    span {
      text-transform: capitalize;
      line-height: 1;
    }
  }

  h1 {
    margin: 30px 0;
    font-size: 42px;
    line-height: 1.25;

    ${media.greaterThan("md")`
      margin: 0 0 30px 0;
      font-size: 68px;
      line-height: 1.1;
    `};
  }

  & > div {
    display: flex;
    align-items: center;

    img {
      width: 48px;
      height: 48px;
      border-radius: 100%;
      margin-right: 26px;
      object-fit: cover;
    }

    p {
      span {
        display: block;

        ${media.greaterThan("md")`
          font-weight: bold;
          display: inline;
        `};

        &:first-of-type {
          font-weight: bold;

          ${media.greaterThan("md")`
            &:after {
              content: ' | ';
            }
          `};
        }
      }
    }
  }
`;

export default Title;
