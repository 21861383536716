import React, { useEffect } from "react";
import styled from "@emotion/styled";
import debounce from "lodash/debounce";

import media from "utils/media";

export default function Navigation({
  categoryNames,
  categories,
  activeCategory,
  activeStatus,
  setCategory,
  setStatus,
}) {
  const handleChangeStatus = debounce((id) => {
    if (id === activeStatus) {
      return setStatus(null);
    }
    setStatus(id);
  }, 200);

  const handleChangeCategory = debounce((id) => {
    if (id === activeCategory) {
      return;
    }
    setCategory(id);
  }, 200);

  useEffect(() => {
    return () => {
      handleChangeCategory.cancel();
      handleChangeStatus.cancel();
    }; // cancel debounce to prevent updating unmounted component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledNavigation>
      <ul>
        <li className={activeStatus === "active" ? "active" : ""}>
          <button
            id="active"
            onClick={(e) => handleChangeStatus(e.target.id)}
            aria-label="Tab with Active projects"
          >
            {categoryNames.active}
          </button>
        </li>
        <li className={activeStatus === "exited" ? "active" : ""}>
          <button
            id="exited"
            onClick={(e) => handleChangeStatus(e.target.id)}
            aria-label="Tab with Exited projects"
          >
            {categoryNames.exited}
          </button>
        </li>
      </ul>
      <ul>
        {categories.map((item) => {
          return (
            <li key={item} className={activeCategory === item ? "active" : ""}>
              <button
                id={item}
                onClick={(e) => handleChangeCategory(e.target.id)}
                aria-label={`Tab with ${item} projects`}
              >
                {categoryNames[item]}
              </button>
            </li>
          );
        })}
      </ul>
    </StyledNavigation>
  );
}

const StyledNavigation = styled.div`
  width: 100%;
  position: sticky;
  top: 76px;
  z-index: 99;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px 0;

  ${media.greaterThan("xs")`
    padding-left: calc((100% - 33.75rem) / 2 + 26px);
    padding-right: calc((100% - 33.75rem) / 2 + 26px);
  `};

  ${media.greaterThan("sm")`
    padding-left: calc((100% - 45rem) / 2 + 26px);
    padding-right: calc((100% - 45rem) / 2 + 26px);
  `};

  ${media.greaterThan("md")`
    padding-top: 30px;
    padding-bottom: 50px;
    padding-left: calc((100% - 60rem) / 2 + 26px);
    padding-right: calc((100% - 60rem) / 2 + 26px);
    top: 78px;
    display: flex;
    justify-content: space-between;
  `};

  ${media.greaterThan("lg")`
    padding-left: calc((100% - 77rem) / 2 + 26px);
    padding-right: calc((100% - 77rem) / 2 + 26px);
  `};

  ul {
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    &:first-of-type {
      margin-bottom: 30px;

      ${media.greaterThan("xs")`
        margin-bottom: 0;
      `};

      ${media.greaterThan("md")`
        width: 40%;
      `};

      li {
        width: 50%;

        ${media.greaterThan("xs")`
          width: auto;
        `};
      }
    }

    &:last-of-type {
      ${media.greaterThan("md")`
        width: 60%;
        justify-content: flex-end;
      `};

      li {
        width: calc(100% / 3);

        ${media.greaterThan("xs")`
          width: auto;
        `};
      }
    }

    li {
      position: relative;

      &:not(:last-of-type) {
        ${media.greaterThan("xs")`
          margin-right: 30px;
        `};
      }

      button {
        width: 100%;
        font-family: ${({ theme }) => theme.fonts.headerFont};
        text-transform: capitalize;
        padding: 19px 0;
        color: ${({ theme }) => theme.colors.textColor};

        ${media.greaterThan("xs")`
          font-size: 20px;
          text-align: left;
        `};

        ${({ theme }) => media.greaterThan("md")`
          font-size: 26px;
          padding: 0;
          line-height: 1.2;
        `};

        span {
          font-family: ${({ theme }) => theme.fonts.headerFont};
          font-size: 26px;
          display: none;
        }
      }

      /* &:after {
        content: "";
        width: 100%;
        height: 1px;
        background: ${({ theme }) => theme.colors.blue_v5};
        position: absolute;
        left: 0;
        bottom: 0;

        ${({ theme }) => media.greaterThan("md")`
          background: ${theme.colors.blue_v4};
        `};
      } */

      &.active {
        button {
          font-weight: bold;
        }

        /* &:after {
          height: 2px;

          ${media.greaterThan("md")`
            height: 1px;
          `};
        } */
      }
    }
  }
`;
