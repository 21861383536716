import React from "react";
import isEmpty from "lodash/isEmpty";
import Mailchimp from "react-mailchimp-form";

import { Container, Row } from "components/common/Grid";
import Link from "components/elements/Link";
import SocialLink from "components/elements/SocialLink";
import Markdown from "components/elements/Markdown";
import Image from "components/elements/Image";

import Button from "./Button";
import Wrapper from "./Wrapper";
import Menu from "./Menu";
import Copyright from "./Copyright";
import Lead from "./Lead";
import Newsletter from "./Newsletter";

const FooterComponent = ({ data }) => {
  const {
    copyright_content,
    copyright_title,
    lead_content,
    lead_title,
    menu = [],
    mailchimp_newsletter_url,
    menu_title,
    socials = [],
    qr_code,
    cta_button = [],
  } = data || {};

  const validatedMenu = !isEmpty(menu) ? menu : [];

  return (
    <Wrapper>
      <Container>
        <Row>
          <Menu width={[1, 1, 1, 4 / 12, 2 / 9]}>
            <h3 css={[letterSpacing]}>{menu_title}</h3>
            {mailchimp_newsletter_url && (
              <Newsletter action={mailchimp_newsletter_url} />
            )}
            <ul>
              {validatedMenu.map((item) => (
                <li key={item._uid}>
                  <Link {...item} />
                </li>
              ))}
            </ul>
            <ul className={validatedMenu.length ? "" : "single"}>
              {!isEmpty(socials) &&
                socials.map((item) => (
                  <li key={item._uid}>
                    <SocialLink {...item} />
                  </li>
                ))}
            </ul>
            {!isEmpty(qr_code) && (
              <Image
                data={qr_code}
                fixed
                options={{ width: 150, height: 150 }}
              />
            )}
            {!isEmpty(cta_button) &&
              cta_button.map((item) => (
                <Button key={item._uid}>
                  <Link {...item} />
                </Button>
              ))}
          </Menu>
          <Lead
            width={[1, 1, 1, 4 / 12, 3 / 9]}
            ml={[0, 0, 0, 0, `${(1 / 9) * 100}%`]}
          >
            <h3 css={letterSpacing}>{lead_title}</h3>
            {!isEmpty(lead_content) && <Markdown>{lead_content}</Markdown>}
          </Lead>
          <Copyright
            width={[1, 1, 1, 4 / 12, 2 / 9]}
            ml={[0, 0, 0, 0, `${(1 / 9) * 100}%`]}
          >
            <h3 css={letterSpacing}>{copyright_title}</h3>
            {!isEmpty(copyright_content) && (
              <Markdown>{copyright_content}</Markdown>
            )}
          </Copyright>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default FooterComponent;

const letterSpacing = {
  letterSpacing: "0.2px",
};
