// paste all font files to this folder and import them to this file
import WhitneyLightEot from "./Whitney-Light.eot";
import WhitneyLightTtf from "./Whitney-Light.ttf";
import WhitneyLightWoff from "./Whitney-Light.woff";
import WhitneyLightWoff2 from "./Whitney-Light.woff2";
import WhitneySemiboldEot from "./Whitney-Semibold.eot";
import WhitneySemiboldTtf from "./Whitney-Semibold.ttf";
import WhitneySemiboldWoff from "./Whitney-Semibold.woff";
import WhitneySemiboldWoff2 from "./Whitney-Semibold.woff2";
import WhitneyBoldEot from "./Whitney-Bold.eot";
import WhitneyBoldTtf from "./Whitney-Bold.ttf";
import WhitneyBoldWoff from "./Whitney-Bold.woff";
import WhitneyBoldWoff2 from "./Whitney-Bold.woff2";
import GothamLightEot from "./Gotham-Light.eot";
import GothamLightTtf from "./Gotham-Light.ttf";
import GothamLightWoff from "./Gotham-Light.woff";
import GothamLightWoff2 from "./Gotham-Light.woff2";
import GothamBoldEot from "./GothamRounded-Bold.eot";
import GothamBoldTtf from "./GothamRounded-Bold.ttf";
import GothamBoldWoff from "./GothamRounded-Bold.woff";
import GothamBoldWoff2 from "./GothamRounded-Bold.woff2";

export default {
  // all imported fonts
  WhitneyLightEot,
  WhitneyLightTtf,
  WhitneyLightWoff,
  WhitneyLightWoff2,
  WhitneySemiboldEot,
  WhitneySemiboldTtf,
  WhitneySemiboldWoff,
  WhitneySemiboldWoff2,
  WhitneyBoldEot,
  WhitneyBoldTtf,
  WhitneyBoldWoff,
  WhitneyBoldWoff2,
  GothamLightEot,
  GothamLightTtf,
  GothamLightWoff,
  GothamLightWoff2,
  GothamBoldEot,
  GothamBoldTtf,
  GothamBoldWoff,
  GothamBoldWoff2,
};
