import React, { useState, useEffect } from "react";
import cloneDeep from "lodash/cloneDeep";
import posed from "react-pose";

import useMatchMedia from "utils/hooks/useMatchMedia";

import Image from "components/elements/Image";

import StyledImages from "../StyledImages";

const Box = posed.div({
  hidden: {
    opacity: 0,
    transition: ({ duration }) => ({ duration }),
  },
  visible: {
    opacity: 1,
    transition: ({ duration }) => ({ duration }),
  },
});

export default function FadeAnimation({ images, duration, frequency }) {
  const [animatedElement, setAnimatedElement] = useState(null);
  const [newArr, setNewArr] = useState(images);
  const [counter, setCounter] = useState(0);

  const isLargeScreen = useMatchMedia("(min-width: 992px)");
  const parsedDuration = duration ? parseInt(duration, 10) : 1500;
  const parsedFrequency = frequency ? parseInt(frequency, 10) : 3000;

  function fadeAnimation() {
    if (counter < 0) return;
    let tempArr = cloneDeep(newArr);
    let id = counter < 5 ? tempArr[counter]._uid : images[counter]._uid;
    const imagesIndex =
      5 + counter < images.length ? 5 + counter : 5 + counter - images.length;
    const tempArrIndex = counter <= 4 ? counter : counter % 5;
    setAnimatedElement(id);
    setTimeout(function () {
      tempArr[tempArrIndex] = images[imagesIndex];
      if (counter < images.length - 1) {
        setNewArr(tempArr);
        setCounter(counter + 1);
      } else {
        setNewArr(images.slice(0, 5));
        setCounter(0);
      }
    }, parsedFrequency);
  }

  useEffect(() => {
    if (isLargeScreen) {
      fadeAnimation();
    } else {
      setCounter(0);
      setAnimatedElement(null);
      setNewArr(images.slice(0, 5));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, isLargeScreen]);

  return (
    <StyledImages>
      {newArr.map((item) => (
        <Box
          id={item._uid}
          key={item._uid}
          pose={animatedElement === item._uid ? "hidden" : "visible"}
          duration={parsedDuration}
        >
          <Image
            fluid
            data={item.asset}
            fadeIn
            options={{
              maxWidth: 600,
              maxHeight: 600,
              useBase64: false,
              quality: 90,
            }}
          />
        </Box>
      ))}
    </StyledImages>
  );
}
