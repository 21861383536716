import styled from "@emotion/styled";

import { Column } from "components/common/Grid";

import media from "utils/media";

const Title = styled(Column)`
  margin-bottom: 43px;

  ${media.greaterThan("md")`
    margin-bottom: 56px;
  `};

  h2 {
    line-height: 1;
  }

  .lead {
    margin: 20px 0;

    ${media.greaterThan("md")`
      margin: 35px 0;
    `};
  }

  p {
    ${media.greaterThan("md")`
      max-width: 860px;
    `};
  }

  .lead.zh {
    ${media.lessThan("xs")`
      font-size: 16px;
    `};
  }
`;

export default Title;
