import React, { useState } from "react";
import styled from "@emotion/styled";
import isEmpty from "lodash/isEmpty";
import flatten from "lodash/flatten";

import useMatchMedia from "utils/hooks/useMatchMedia";

import { Container, Row, Column } from "components/common/Grid";
import { useStateValue } from "components/common/GlobalState";
import getTranslations from "utils/getTranslations";

import Section from "./Section";
import PortfolioGroup from "./PortfolioGroup";
import Navigation from "./Navigation";

export default function MultiPortfolio({ data }) {
  const [globalState = {}] = useStateValue({});
  const [status, setStatus] = useState(null);
  const [category, setCategory] = useState("all");
  const isSmallScreen = useMatchMedia("(max-width: 991px)");
  const {
    heading,
    vertex_usa = [],
    vertex_usa_title,
    vertex_sea = [],
    vertex_sea_title,
    vertex_china = [],
    vertex_china_title,
    vertex_israel = [],
    vertex_israel_title,
    vertex_hc = [],
    vertex_hc_title,
    vertex_growth = [],
    vertex_growth_title,
  } = data || {};

  const categoryNames =
    getTranslations(globalState.lang, "projectCategory") || {};

  function getAllCategories(array) {
    const allCategories =
      array &&
      array.map((item) => {
        return item.reduce((p, { category }) => [...p, category], ["all"]);
      });

    const flattenAllCategories = flatten(allCategories);
    return Array.from(new Set(flattenAllCategories));
  }

  const categories = getAllCategories([
    vertex_china,
    vertex_sea,
    vertex_usa,
    vertex_israel,
    vertex_hc,
    vertex_growth,
  ]);

  const AllProjects = [
    {
      title: vertex_china_title,
      data: vertex_china,
    },
    {
      title: vertex_israel_title,
      data: vertex_israel,
    },
    {
      title: vertex_sea_title,
      data: vertex_sea,
    },
    {
      title: vertex_usa_title,
      data: vertex_usa,
    },
    {
      title: vertex_hc_title,
      data: vertex_hc,
    },
    {
      title: vertex_growth_title,
      data: vertex_growth,
    },
  ];

  return (
    <Section>
      <Container>
        <Row>
          <Title>
            <h1>{heading}</h1>
          </Title>
        </Row>
      </Container>
      <Navigation
        categoryNames={categoryNames}
        categories={categories}
        setCategory={setCategory}
        setStatus={setStatus}
        activeCategory={category}
        activeStatus={status}
      />
      {AllProjects.map(
        ({ title, data }) =>
          !isEmpty(data) && (
            <PortfolioGroup
              title={title}
              projects={data}
              category={category}
              status={status}
              small={isSmallScreen}
            />
          )
      )}
    </Section>
  );
}

const Title = styled(Column)`
  h1 {
    line-height: 1.1;
    margin: 0;
  }
`;
