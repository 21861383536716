import styled from "@emotion/styled";

import media from "utils/media";

const StyledTitle = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px 20px;

  ${media.greaterThan("xs")`
    padding: 40px;
  `};

  ${media.greaterThan("md")`
    padding: 25px 40px;
    height: 242px;
  `};

  ${media.greaterThan("lg")`
    padding: 25px 80px 30px 100px;
  `};

  nav {
    display: none;

    ${media.greaterThan("md")`
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    `};

    button {
      width: 40px;
      height: 40px;
      background-color: ${({ theme }) => theme.colors.blue_v2};
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        display: block;
        width: 30px;
        fill: ${({ theme }) => theme.colors.textColor};
      }
    }

    & > div {
      width: 150px;
      display: flex;
      justify-content: space-between;
    }
  }

  span {
    line-height: 1;
    font-size: 26px;
    font-family: ${({ theme }) => theme.fonts.headerFont};
    color: ${({ theme }) => theme.colors.gray_v2};
  }

  h2 {
    margin-top: 10px;
    font-size: 30px;
    line-height: 1;

    ${media.greaterThan("xs")`
      font-size: 42px;
    `};
  }

  ul {
    margin: 27px 0 0 0;
    width: 100%;
    display: flex;
    align-items: center;

    li {
      width: 24px;
      height: 24px;

      & + li {
        margin-left: 40px;
      }

      a {
        height: 100%;

        svg {
          fill: ${({ theme }) => theme.colors.textColor};
        }
      }
    }
  }
`;

export default StyledTitle;
