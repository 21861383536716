import styled from "@emotion/styled";

import { Column } from "components/common/Grid";
import Link from "components/elements/Link";

import media from "utils/media";

export const Wrapper = styled(Column)`
  text-align: center;

  .gatsby-image-wrapper {
    margin-bottom: 34px;

    ${media.greaterThan("md")`
      max-height: 400px;
    `};

    ${media.greaterThan("lg")`
      max-height: 500px;
    `};
  }

  h1,
  h2,
  h3,
  h4,
  p {
    display: block;
    margin: 30px auto;
  }

  h1,
  h2 {
    ${media.greaterThan("sm")`
      max-width: 90%;
    `};

    ${media.greaterThan("md")`
      max-width: 80%;
    `};

    ${media.greaterThan("lg")`
      max-width: 70%;
    `};
  }

  h3,
  h4,
  p {
    ${media.greaterThan("sm")`
      max-width: 60%;
    `};

    ${media.greaterThan("md")`
      max-width: 50%;
    `};
  }
`;

export const StyledLink = styled(Link)`
  padding-bottom: 2px;
  font-family: ${({ theme }) => theme.fonts.headerFont};
  font-weight: bold;
  line-height: 1;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-bottom: 1px solid ${({ theme }) => theme.colors.textColor};
`;
