import { ZH, EN, JP, KR } from "lang";

export default function (lang, component) {
  if (!lang || !component) {
    return null;
  }

  switch (lang) {
    case "en":
      return EN[component] ? EN[component] : "Wrong component name";
    case "zh":
      return ZH[component] ? ZH[component] : "Wrong component name";
    case "jp":
      return JP[component] ? JP[component] : "Wrong component name";
    case "kr":
      return KR[component] ? KR[component] : "Wrong component name";
    default:
      return {};
  }
}
