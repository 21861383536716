import smoothscroll from "smoothscroll-polyfill";

export default function scrollTo(element, offSet = 0, timeout = null) {
  smoothscroll.polyfill();
  const elemPos = element
    ? element.getBoundingClientRect().top + window.pageYOffset
    : 0;
  if (timeout) {
    setTimeout(() => {
      window.scroll({ top: elemPos + offSet, left: 0, behavior: "smooth" });
    }, timeout);
  } else {
    window.scroll({ top: elemPos + offSet, left: 0, behavior: "smooth" });
  }
}
