import styled from "@emotion/styled";

import media from "utils/media";

const Section = styled.section`
  padding-top: 70px;
  padding-bottom: 70px;

  ${media.greaterThan("xs")`
    padding-top: 100px;
    padding-bottom: 100px;
  `};

  ${media.greaterThan("md")`
    padding-top: 110px;
    padding-bottom: 110px;
  `};
`;

export default Section;
