import React from "react";
import isEmpty from "lodash/isEmpty";

import { Container, Row } from "components/common/Grid";
import Image from "components/elements/Image";
import Markdown from "components/elements/Markdown";

import Section from "./Section";
import { Wrapper, StyledLink } from "./Content";

export default function NotFound({ data }) {
  const { image, message, back_link } = data;
  return (
    <Section>
      <Container>
        <Row>
          <Wrapper>
            {!isEmpty(image) && <Image fluid data={image} />}
            {!isEmpty(message) && <Markdown>{message}</Markdown>}
            {!isEmpty(back_link) && !isEmpty(back_link[0]) && (
              <StyledLink {...back_link[0]} />
            )}
          </Wrapper>
        </Row>
      </Container>
    </Section>
  );
}
