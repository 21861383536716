import styled from "@emotion/styled";

import { Column } from "components/common/Grid";

import media from "utils/media";

const Title = styled(Column)`
  h2 {
    font-size: 56px;
    line-height: 1;
    margin-bottom: 50px;

    ${media.greaterThan("sm")`
      margin-bottom: 40px;
      font-size: 64px;
    `};

    span {
      color: ${({ theme }) => theme.colors.gray_v3};

      ${media.greaterThan("sm")`
        display: block;
        margin-top: 20px;
      `};
    }
  }

  div:last-of-type {
    display: none;

    ${media.greaterThan("sm")`
      display: block;
    `};
  }
`;

export default Title;
