import React, { useState } from "react";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import * as clipboard from "clipboard-polyfill";

import { ReactComponent as ShareIcon } from "assets/svg/share.svg";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";
import { ReactComponent as LinkIcon } from "assets/svg/link.svg";
import { ReactComponent as FacebookIcon } from "assets/svg/facebook-ico.svg";
import { ReactComponent as TwitterIcon } from "assets/svg/twitter-ico.svg";

import SocialsWrapper from "./Wrapper";
import SuccessMsg from "./SuccessMsg";
import theme from "utils/theme";

export default function Socials({ path }) {
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  function handleOpen() {
    setOpen(!open);
  }

  const copyLink = () => {
    clipboard.writeText(path).then(
      () => {
        console.log("COPIED: ", path);
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
      },
      (err) => {
        console.log("Not COPIED because of error: ", err);
      }
    );
  };

  return (
    <SocialsWrapper open={open} theme={theme}>
      <div>
        <button
          onClick={handleOpen}
          title={open ? "Close" : "Open"}
          aria-label="Social sharing buttons"
        >
          {open ? <CloseIcon /> : <ShareIcon />}
        </button>
        <TwitterShareButton
          className={"react-share-button"}
          url={path}
          additionalProps={{ "aria-label": "Twitter", title: "Twitter" }}
        >
          <TwitterIcon />
        </TwitterShareButton>
        <FacebookShareButton
          className={"react-share-button"}
          url={path}
          additionalProps={{ "aria-label": "Facebook", title: "Facebook" }}
        >
          <FacebookIcon />
        </FacebookShareButton>
        <button onClick={copyLink} aria-label="Copy link" title="Copy link">
          <LinkIcon />
        </button>
        <SuccessMsg copied={copied}>Copied</SuccessMsg>
      </div>
    </SocialsWrapper>
  );
}
