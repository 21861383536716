import styled from "@emotion/styled";

import { Column } from "components/common/Grid";
import media from "utils/media";

export const RecentNews = styled(Column)`
  ${media.greaterThan("md")`
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  `};

  ${media.greaterThan("lg")`
    margin-left: -50px;
    margin-right: -50px;
    width: calc(100% + 100px);
  `};

  h2 {
    width: 100%;
    margin-bottom: 40px;

    ${media.greaterThan("sm")`
      margin-bottom: 0;
    `};

    ${media.greaterThan("md")`
      margin-bottom: 60px;
      padding: 0 10px;
    `};

    ${media.greaterThan("md")`
      padding: 0 25px;
    `};
  }
`;
