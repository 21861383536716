import React, { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import throttle from "lodash/throttle";

import { Container, Row, Column } from "components/common/Grid";
import Link from "components/elements/Link";
import Dropdown from "components/elements/Dropdown";

import Wrapper from "./Wrapper";
import Logo from "./Logo";
import Hamburger from "./Hamburger";
import { MainNav, MainNavElement } from "./MainNav";
import {
  HamburgerNav,
  List,
  ListItem,
  MainListItem,
  ListSubItem,
  ListItemLink,
} from "./HamburgerNav";
import LangSwitcher from "./LangSwitcher";

export default function HeaderComponent({
  data,
  alternates,
  languages,
  activeLang,
  pathname,
  theme,
}) {
  const [menuOpen, setMenu] = useState(false);
  const [fixed, setFixed] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  useEffect(() => {
    const updateScrollPosition = () => {
      const scrollPosition =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      if (scrollPosition > 74 && !fixed) {
        setFixed(true);
      } else if (scrollPosition < 74 && fixed) {
        setFixed(false);
      }
    };

    updateScrollPosition();

    const handleOnScroll = throttle(updateScrollPosition, 50);

    window.addEventListener("scroll", handleOnScroll);

    return () => {
      return window.removeEventListener("scroll", handleOnScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixed]);

  const { main_nav, hamburger_nav } = data || [];

  return (
    <Wrapper fixed={fixed} open={menuOpen}>
      <Container>
        <Row>
          <Column css={flex}>
            <Logo
              open={menuOpen}
              fixed={fixed}
              url={
                activeLang === process.env.GATSBY_DEFAULT_LANG
                  ? "/"
                  : `/${activeLang}/`
              }
              theme={theme}
            />
            <div css={flex}>
              <MainNav fixed={fixed}>
                <ul>
                  {main_nav &&
                    main_nav.map((item) => {
                      return item && item.component === "element_link" ? (
                        <MainNavElement
                          key={item._uid}
                          onMouseEnter={() => setActiveItem(item._uid)}
                          onMouseLeave={() => setActiveItem(null)}
                          hover={
                            activeItem === item._uid
                              ? "active"
                              : activeItem !== null
                              ? "inactive"
                              : "default"
                          }
                        >
                          <Link {...item} />
                        </MainNavElement>
                      ) : item.component === "element_dropdown" ? (
                        <MainNavElement
                          key={item._uid}
                          onMouseEnter={() => setActiveItem(item._uid)}
                          onMouseLeave={() => setActiveItem(null)}
                          hover={
                            activeItem === item._uid
                              ? "active"
                              : activeItem !== null
                              ? "inactive"
                              : "default"
                          }
                        >
                          <Dropdown {...item} fixed={fixed} />
                        </MainNavElement>
                      ) : null;
                    })}
                </ul>
              </MainNav>
              <Hamburger
                open={menuOpen}
                fixed={fixed}
                onClick={() => setMenu(!menuOpen)}
                hiden={hamburger_nav.length === 0}
              />
            </div>
          </Column>
          <HamburgerNav open={menuOpen}>
            <Container css={h100}>
              <Row css={h100}>
                <Column css={[paddingTop, h100]}>
                  <List>
                    {!isEmpty(main_nav) &&
                      main_nav.map((item) => {
                        return item.component === "element_dropdown" ? (
                          <MainListItem key={item._uid}>
                            <ListSubItem {...item} fixed={fixed} />
                          </MainListItem>
                        ) : (
                          <MainListItem
                            key={item._uid}
                            onClick={() => setMenu(!menuOpen)}
                          >
                            <ListItemLink {...item} />
                          </MainListItem>
                        );
                      })}
                    {!isEmpty(hamburger_nav) &&
                      hamburger_nav.map((item) => {
                        return item.component === "element_dropdown" ? (
                          <ListItem key={item._uid}>
                            <ListSubItem {...item} mobile />
                          </ListItem>
                        ) : (
                          <ListItem key={item._uid}>
                            <ListItemLink {...item} />
                          </ListItem>
                        );
                      })}
                    {languages.length > 1 && (
                      <LangSwitcher
                        languages={languages}
                        activeLang={activeLang}
                        alternates={alternates}
                        pathname={pathname}
                      />
                    )}
                  </List>
                </Column>
              </Row>
            </Container>
          </HamburgerNav>
        </Row>
      </Container>
    </Wrapper>
  );
}

const flex = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const paddingTop = {
  paddingTop: "53px",
  "@media (orientation: landscape) and (max-height: 590px)": {
    paddingTop: "50px",
  },
};

const h100 = {
  height: "100%",
};
