import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import isEmpty from "lodash/isEmpty";

import { ReactComponent as GlobalPlatformMap } from "assets/svg/global-platform.svg";

import Info from "./Info";

export default function Map({ descriptions }) {
  const wrapperRef = useRef(null);
  const [state, setState] = useState({
    vertex: "default",
    description: "Choose country from the map",
  });

  useEffect(() => {
    if (wrapperRef) {
      const array = Array.from(
        wrapperRef.current.querySelectorAll("[data-space]")
      );
      array.forEach((item) =>
        item.addEventListener("click", handleOnClick, true)
      );

      return () => {
        array.forEach((item) =>
          item.removeEventListener("click", handleOnClick)
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleOnClick(e) {
    if (!isEmpty(e.target.dataset)) {
      const { vertex } = e.target.dataset;
      const description = descriptions[vertex];
      setState((prevState) => ({ ...prevState, vertex, description }));
    }
  }

  return (
    <Wrapper ref={wrapperRef}>
      <Info vertex={state.vertex} description={state.description} />
      <GlobalPlatformMap />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  margin: 40px 0;
`;
