import styled from "@emotion/styled";

import media from "utils/media";

const Section = styled.section`
  padding-top: 110px;
  padding-bottom: 92px;

  ${media.greaterThan("md")`
    padding-top: 80px;
    padding-bottom: 80px;
  `};
`;

export default Section;
