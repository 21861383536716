import styled from "@emotion/styled";

import media from "utils/media";

const Section = styled.section`
  padding-top: 50px;
  padding-bottom: 50px;

  ${media.greaterThan("md")`
    padding-top: 80px;
    padding-bottom: 20px;
  `};
`;

export default Section;
