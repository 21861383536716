import React, { useState, useEffect } from "react";

import { Spring } from "react-spring";
import styled from "@emotion/styled";

import media from "utils/media";
import useMatchMedia from "utils/hooks/useMatchMedia";

import Image from "components/elements/Image";

export default function FlipAnimation({ images, duration, frequency }) {
  const [animate, setAnimate] = useState(false);

  const isLargeScreen = useMatchMedia("(min-width: 992px)");
  const parsedDuration = duration ? parseInt(duration, 10) : 1500;
  const parsedFrequency = frequency ? parseInt(frequency, 10) : 3000;

  const createConfig = (i) => ({
    mass: 5,
    tension: 500,
    friction: 80,
    delay: i * 100,
    duration: parsedDuration,
  });

  useEffect(() => {
    let timeout = null;
    if (isLargeScreen) {
      timeout = setTimeout(function () {
        setAnimate((state) => !state);
      }, parsedFrequency);
    }
    return () => timeout && clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animate, isLargeScreen]);

  return (
    <StyledImages>
      {images.slice(0, 10).map((item, i) =>
        (i + 1) % 2 === 0 ? (
          <div key={`item_${i}`}>
            <Spring
              from={{
                opacity: animate ? 0 : 1,
                transform: `perspective(0px) rotateY(${animate ? 180 : 0}deg)`,
              }}
              to={{
                opacity: animate ? 0 : 1,
                transform: `perspective(0px) rotateY(${animate ? 180 : 0}deg)`,
              }}
              config={createConfig(i)}
            >
              {(props) => {
                return (
                  <Image
                    fluid
                    data={images[i].asset}
                    fadeIn
                    options={{
                      maxWidth: 600,
                      useBase64: false,
                      quality: 90,
                    }}
                    style={props}
                  />
                );
              }}
            </Spring>
            <Spring
              from={{
                opacity: animate ? 1 : 0,
                transform: `perspective(600px) rotateY(${
                  animate ? 180 : 0
                }deg)`,
              }}
              to={{
                opacity: animate ? 1 : 0,
                transform: `perspective(600px) rotateY(${
                  animate ? 180 : 0
                }deg)`,
              }}
              config={createConfig(i)}
            >
              {(props) => {
                return (
                  <Image
                    fluid
                    data={images[i - 1].asset}
                    fadeIn
                    options={{
                      maxWidth: 600,
                      maxHeight: 600,
                      useBase64: false,
                      quality: 90,
                    }}
                    style={props}
                  />
                );
              }}
            </Spring>
          </div>
        ) : null
      )}
    </StyledImages>
  );
}

const StyledImages = styled.div`
  width: 100%;
  display: flex;
  padding: 0;

  ${media.greaterThan("xs")`
    width: 100vw;
    margin-left: calc(-100vw / 2 + 33.75rem / 2 );
    margin-right: calc(-100vw / 2 + 33.75rem / 2 );
  `};

  ${media.greaterThan("sm")`
    margin-left: calc(-100vw / 2 + 45rem / 2 );
    margin-right: calc(-100vw / 2 + 45rem / 2 );
  `};

  ${media.greaterThan("md")`
    width: 55vw;
    margin-left: auto;
    margin-right: calc(-100vw / 2 + 60rem / 2 );
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: auto auto auto;
    -ms-grid-rows: 1fr 1fr 1fr;
    grid-template-rows: auto;
  `};

  ${media.greaterThan("lg")`
    width: 70%;
    margin-right: calc(-100vw / 2 + 77rem / 2 );
  `};

  ${media.greaterThan("1920px")`
    margin-right: -350px;
  `};

  & > div {
    display: none;
    flex: 1;
    position: relative;
    width: 100%;
    transform-style: preserve-3d;
    perspective: 500px;
    min-height: 50vw;

    ${media.greaterThan("xs")`
      min-height: calc(100vw / 3);
    `};

    ${media.greaterThan("sm")`
      min-height: 25vw;
    `};

    ${media.greaterThan("md")`
      min-height: calc(55vw / 3);
    `};

    ${media.greaterThan("lg")`
      min-height: 287px;
    `};

    .gatsby-image-wrapper {
      position: absolute !important;
      will-change: transform, opacity;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:nth-of-type(1) {
      display: block;
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    &:nth-of-type(2) {
      display: block;
      -ms-grid-row: 1;
      -ms-grid-column: 2;
    }

    &:nth-of-type(3) {
      ${media.greaterThan("xs")`
        display: block;
      `};

      ${media.greaterThan("md")`
        -ms-grid-row: 2;
        -ms-grid-column: 2;
        grid-column-start: 2;
      `};
    }

    &:nth-of-type(4) {
      ${media.greaterThan("sm")`
        display: block;
      `};

      ${media.greaterThan("md")`
        -ms-grid-row: 2;
        -ms-grid-column: 3;
      `};
    }

    &:nth-of-type(5) {
      ${media.greaterThan("md")`
        display: block;
        -ms-grid-row: 3;
        -ms-grid-column: 2;
        grid-column-start: 2;
      `};
    }
  }
`;
