import React from "react";
import isEmpty from "lodash/isEmpty";

import { Container, Row } from "components/common/Grid";
import Link from "components/elements/Link";

import Section from "./Section";
import Content from "./Content";

export default function RelatedLinks({ data }) {
  const { links, background, heading } = data || {};

  return (
    <Section bg={background}>
      <Container>
        <Row>
          <Content>
            <h2 className="h1">{heading}</h2>
            <div>
              {!isEmpty(links) &&
                links.map((item) => <Link {...item} className="primary" />)}
            </div>
          </Content>
        </Row>
      </Container>
    </Section>
  );
}
