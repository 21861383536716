import styled from "@emotion/styled";

import { Column } from "components/common/Grid";

import media from "utils/media";

const Lead = styled(Column)`
  margin-bottom: 66px;

  ${media.greaterThan("md")`
    margin-bottom: 0;
  `};

  h3 {
    margin-bottom: 11px;
    letter-spacing: 0.2px;

    ${media.greaterThan("md")`
      margin-bottom: 17px;
    `};
  }

  p {
    letter-spacing: 0.1px;
    color: ${({ theme }) => theme.colors.blue_v2};
  }
`;

export default Lead;
