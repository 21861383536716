import styled from "@emotion/styled";
import { Column } from "components/common/Grid";

import media from "utils/media";

const NewsList = styled(Column)`
  height: ${({ height }) => (height ? `${height}px` : "auto")};

  & > div {
    ${media.greaterThan("sm")`
      display: none;
    `};
  }

  & + a {
    margin-top: 34px;
  }
`;

export default NewsList;
