import styled from "@emotion/styled";

import media from "utils/media";
import ImageComponent from "../Image";
import { EntryDate, ReadMoreCaption } from "./Footer";

export const News = styled.div`
  margin-bottom: 86px;
  cursor: pointer;
  transition: ${(props) => (props.isNewsGrid ? "0.3s" : "0.2s")} ease-in;
  box-shadow: ${(props) =>
    props.isNewsGrid ? 0 : "0 12px 20px -3px rgba(0, 41, 76, 0.2)"};
  background-color: ${(props) =>
    props.$isHighlighted ? "rgba(218, 242, 249, 1)" : ""};

  ${media.greaterThan("sm")`
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding: 30px;
  `};

  ${media.greaterThan("md")`
    width: calc(100% / 3 - 20px);
    margin: 0 10px;
    padding: 30px 20px;
    text-align: left;
  `};

  ${media.greaterThan("lg")`
    width: calc(100% / 3 - 50px);
    margin: 0 25px;
    padding: 30px 35px 20px 35px;
  `};

  &:hover {
    ${({ isNewsGrid, theme }) =>
      media.greaterThan("md")`
      box-shadow: ${
        isNewsGrid
          ? "rgba(0, 41, 76, 0.2) 0px 12px 20px -3px"
          : `0 12px 33px -3px ${theme.colors.brand}`
      };
    `};

    ${ReadMoreCaption} {
      opacity: 1;
    }
  }

  & > h3 {
    margin: ${(props) => (props.isNewsGrid ? " 0 0 25px 0" : "20px 0 30px 0")};
    line-height: 1.25;
    font-size: 24px;
    font-weight: bold;

    ${media.greaterThan("md")`
        font-size: 26px;
        font-weight: 300;
    `};
  }

  & > div {
    p {
      margin-bottom: 20px;

      ${media.greaterThan("sm")`
        width: 90%;
        margin: 20px auto;
      `};

      ${media.greaterThan("md")`
        width: 100%;
        margin: 20px 0;
      `};

      ${media.greaterThan("md")`
        margin: 15px 0;
      `};

      &:first-of-type {
        margin: 0 auto;
      }
    }

    & > ${EntryDate} {
      font-size: 16px;
      font-family: ${({ theme }) => theme.fonts.headerFont};
      opacity: 0.5;
      text-transform: capitalize;
    }
  }
`;

export const Image = styled(ImageComponent)`
  margin: ${(props) => (props.isNewsGrid ? " 0 0 40px 0" : "0")};
`;
