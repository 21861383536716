import styled from "@emotion/styled";

import media from "utils/media";

const Section = styled.section`
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;

  ${media.greaterThan("sm")`
    text-align: left;
  `};

  ${media.greaterThan("md")`
    padding-top: 60px;
    padding-bottom: 110px;
  `};
`;

export default Section;
