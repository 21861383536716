import React from "react";
import isEmpty from "lodash/isEmpty";

import Image from "components/elements/Image";
import Markdown from "components/elements/Markdown";
import { Container, Row, Column } from "components/common/Grid";

import Section from "./Section";
import Content from "./Content";

export default function LongTestimonial({ data }) {
  const {
    heading,
    content,
    author_name,
    author_photo,
    author_role,
    author_title,
  } = data;
  return (
    <Section>
      <Container>
        <Row>
          <Column>
            <h2 className="h1" style={{ lineHeight: 1 }}>
              {heading}
            </h2>
          </Column>
          <Column>
            <Content>
              <div className="author">
                {!isEmpty(author_photo) && (
                  <Image
                    fluid
                    data={author_photo}
                    options={{ maxWidth: 480, quality: 90, useBase64: false }}
                  />
                )}
                <div className="bio">
                  <p className="name">{author_name}</p>
                  <p className="title">{author_title}</p>
                  <p className="role">{author_role}</p>
                </div>
              </div>
              {!isEmpty(content) && (
                <Markdown className="content">{content}</Markdown>
              )}
            </Content>
          </Column>
        </Row>
      </Container>
    </Section>
  );
}
