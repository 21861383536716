import styled from "@emotion/styled";

import { Column } from "components/common/Grid";

import media from "utils/media";

const Content = styled(Column)`
  ${media.greaterThan("md")`
    max-width: 710px;
    margin: 0 auto;
    padding: 0;
  `};

  h4 {
    margin: 30px 0;
  }
  p {
    & + p {
      margin-top: 20px;
    }
  }
`;

export default Content;
