import React from "react";
import isEmpty from "lodash/isEmpty";
import { getFluidGatsbyImage } from "gatsby-storyblok-image";

import useMatchMedia from "utils/hooks/useMatchMedia";
import { Container, Row } from "components/common/Grid";

import Section from "./Section";
import Title from "./Title";
import MembersSlider from "./MembersSlider";
import MembersTable from "./MembersTable";

export default function Team({ data }) {
  const {
    heading,
    lead,
    members,
    showMore_label,
    members_offset,
    members_table,
    link,
    section_id,
  } = data || {};

  const isSmallScreen = useMatchMedia("(max-width: 991px)");
  const validatedMembers = !isEmpty(members) ? members : [];

  validatedMembers.forEach((member) => {
    // prebuild all members images
    getFluidGatsbyImage(member.image, {
      quality: 80,
      smartCrop: false,
      maxWidth: 500,
      downloadLocal: true,
    });

    // prebuild for modals
    getFluidGatsbyImage(member.image, {
      quality: 80,
      smartCrop: false,
      maxWidth: 935,
      downloadLocal: true,
    });
  });

  return (
    <Section id={section_id}>
      <Container>
        <Row>
          <Title>
            <h2 className="h1">{heading}</h2>
            <p className={`lead`}>{lead}</p>
          </Title>
          {isSmallScreen ? (
            <MembersSlider members={validatedMembers} link={link} />
          ) : (
            <MembersTable
              members={validatedMembers}
              label={showMore_label}
              membersOffset={members_offset}
              linear={members_table}
              link={link}
            />
          )}
        </Row>
      </Container>
    </Section>
  );
}
