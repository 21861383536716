import styled from "@emotion/styled";

import media from "utils/media";

const Content = styled.div`
  padding: 113px 0;
  text-align: center;

  ${media.greaterThan("md")`
    padding: 140px 0;
    margin: 0 auto;
    max-width: 1010px;
  `};

  h1 {
    ${media.lessThan("xs")`
      font-size: 42px;
    `};
  }

  h2 {
    font-size: 26px;

    ${media.greaterThan("sm")`
      font-size: 32px;
    `};

    ${media.greaterThan("md")`
      line-height: 1.14;
      font-size: 42px;
    `};
  }

  h3 {
    ${media.lessThan("xs")`
      font-size: 18px;
    `};
  }
`;

export default Content;
