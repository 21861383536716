import React from "react";
import styled from "@emotion/styled";
import Swiper from "react-id-swiper";
import { Pagination, Navigation } from "swiper/dist/js/swiper.esm";

import Link from "components/elements/Link";
import media from "utils/media";
import useMemberModal from "utils/hooks/useMemberModal";

import Member from "./Member";
import Modal from "./Modal";

import { ReactComponent as ArrowLeft } from "assets/svg/arrow_left.svg";
import { ReactComponent as ArrowRight } from "assets/svg/arrow_right.svg";

export default function SliderComponent({ members, link }) {
  const [
    open,
    member,
    handleOpenModal,
    handleCloseModal,
    nextMember,
    prevMember,
  ] = useMemberModal(members);

  const params = {
    slidesPerView: 4,
    spaceBetween: 30,
    modules: [Pagination, Navigation],
    pagination: {
      el: ".swiper-pagination",
      type: "fraction",
    },
    navigation: {
      nextEl: ".swiper-next",
      prevEl: ".swiper-prev",
    },
    renderPrevButton: () => (
      <button className="swiper-prev" aria-label="Swipe back">
        <ArrowLeft />
      </button>
    ),
    renderNextButton: () => (
      <button className="swiper-next" aria-label="Swipe next">
        <ArrowRight />
      </button>
    ),
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      576: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
    },
  };
  return (
    <StyledSlider>
      <Swiper {...params}>
        {members.map((item) => (
          <Member key={item._uid} data={item} onClick={handleOpenModal} />
        ))}
      </Swiper>
      {link && link[0] && (
        <StyledLink>
          <Link {...link[0]} className="primary" />
        </StyledLink>
      )}
      {members && members.length && (
        <Modal
          member={member}
          open={open}
          close={handleCloseModal}
          next={nextMember}
          prev={prevMember}
          onClose={handleCloseModal}
          center
          showCloseIcon={false}
          blockScroll={false}
        />
      )}
    </StyledSlider>
  );
}

const StyledSlider = styled.div`
  width: 100%;

  ${media.greaterThan("xs")`
    width: 100vw;
    margin-left: calc(-100vw / 2 + 33.75rem / 2);
    margin-right: calc(-100vw / 2 + 33.75rem / 2);
  `};

  ${media.greaterThan("sm")`
    margin-left: calc(-100vw / 2 + 45rem / 2);
    margin-right: calc(-100vw / 2 + 45rem / 2);
  `};

  .swiper-container {
    padding-bottom: 62px;
  }

  .swiper-pagination {
    bottom: 6px;
    font-family: ${({ theme }) => theme.fonts.headerFont};
    color: ${({ theme }) => theme.colors.textColor};

    .swiper-pagination-current {
      font-weight: bold;
    }
  }

  .swiper-next,
  .swiper-prev {
    display: block;
    position: absolute;
    bottom: 0px;
    width: 36px;
    z-index: 10;

    svg {
      display: block;
    }
  }

  .swiper-next {
    right: 26px;
  }

  .swiper-prev {
    left: 26px;
  }
`;

const StyledLink = styled.div`
  width: 100%;
  margin: 50px auto 0;
  text-align: center;
`;
