export default {
  // Colors
  colors: {
    brand: "#124E91",
    textColor: "#00294c",
    darkTextColor: "#051624",
    white: "#fff",
    black: "#000",
    blue_v1: "#007bf8",
    blue_v2: "#e0f3fa",
    blue_v3: "#F9FDFE",
    blue_v4: "#D2DFF4",
    blue_v5: "#043fbe",
    blue_v6: "#55b8e2",
    blue_v7: "#F0F8FB",
    blue_v8: "#1E4585",
    gray_v1: "#fafafa",
    gray_v2: "rgba(0, 41, 76, 0.5)",
    gray_v3: "#d8d8d8",
    headerColor: "#124E91",
    headerLogo: "#114C8D",
  },
  fontSizes: {
    testimonialMobile: "15px",
    testimonialTablet: "22px",
  },
  fonts: {
    headerFont: "WhitneyNew, sans-serif",
    bodyFont: "Gotham, sans-serif",
  },
  // GRID
  space: [0, 5, 10, 15, 26, 40],
  breakpoints: ["36rem", "48rem", "62rem", "80rem"],
};
