import styled from "@emotion/styled";

import media from "utils/media";

const Section = styled.section`
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: ${({ bg }) => bg || "#FFF"};

  ${media.greaterThan("md")`
    padding-bottom: 120px;
  `};
`;

export default Section;
