import React, { useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import isEmpty from "lodash/isEmpty";

import useChildrensHeight from "utils/hooks/useChildrensHeight";

import { Container, Row } from "components/common/Grid";
import Link from "components/elements/Link";
import Markdown from "components/elements/Markdown";

import Section from "./Section";
import PostTile from "./Tile";
import NewsList from "./NewsList";
import Title from "./Title";
import SeeAllLink from "./SeeAllLink";

export default function NewsSection({ data, lang }) {
  const {
    heading,
    news_amount: newsAmount,
    readMore_label: readMoreLabel,
    seeAll_link: seeAllLink,
  } = data;
  const postsData = useStaticQuery(newsSectionQuery);
  const listOfNews = useRef();
  const height = useChildrensHeight(listOfNews);
  const {
    posts: { edges },
  } = postsData;

  const postsList = !isEmpty(edges)
    ? edges
        .filter(({ node }) => node.full_slug.indexOf(`${lang}/`) >= 0)
        .slice(0, newsAmount)
    : [];
  const offsetedHeight = height + 44 * (postsList.length - 1);

  return (
    <Section>
      <Container>
        <Row>
          <Title width={[1, 1, 6 / 12, 5 / 12, 4 / 12]}>
            <Markdown>{heading}</Markdown>
            {!isEmpty(seeAllLink) && !isEmpty(seeAllLink[0]) && (
              <SeeAllLink>
                <Link {...seeAllLink[0]} />
              </SeeAllLink>
            )}
          </Title>
          <NewsList
            width={[1, 1, 6 / 12, 7 / 12, 8 / 12]}
            ref={listOfNews}
            height={offsetedHeight}
          >
            {postsList.map(({ node }) => {
              const newsUrlPosition = node.full_slug.indexOf(`news/`);
              const formattedSlug = newsUrlPosition
                ? node.full_slug.substring(newsUrlPosition + 5)
                : node.full_slug;

              return (
                <PostTile
                  key={node.uuid}
                  title={node.field_post_title_string}
                  date={node.field_post_date_string}
                  isExternal={node.field_external_post_boolean}
                  externalUrl={node.field_external_link_string}
                  readMoreLabel={readMoreLabel}
                  formattedSlug={formattedSlug}
                />
              );
            })}
          </NewsList>
        </Row>
      </Container>
    </Section>
  );
}

export const newsSectionQuery = graphql`
  {
    posts: allStoryblokEntry(
      limit: 20
      filter: { field_component: { eq: "common_post" } }
      sort: { order: DESC, fields: [field_post_date_string] }
    ) {
      edges {
        node {
          full_slug
          uuid
          field_post_date_string(formatString: "DD MMM YYYY")
          field_post_title_string
          field_external_link_string
          field_external_post_boolean
        }
      }
    }
  }
`;
