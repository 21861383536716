import styled from "@emotion/styled";

import media from "utils/media";

const Section = styled.section`
  padding-top: 73px;
  padding-bottom: 110px;

  ${media.greaterThan("sm")`
    padding-top: 120px;
    padding-bottom: 80px;
  `};
`;

export default Section;
