import React from "react";
import styled from "@emotion/styled";
import Image from "components/elements/Image";

import media from "utils/media";

export default function ImageComponent({
  data,
  isAnimation = false,
  words = [],
}) {
  return (
    <StyledImage>
      <Image data={data} fluid options={{ maxWidth: 1300 }} />
      {isAnimation && (
        <AnimationWrapper>
          <div>
            {words.map((word, index) => (
              <span key={word._uid} className={`word_${index + 1}`}>
                {word.text}
              </span>
            ))}
          </div>
        </AnimationWrapper>
      )}
    </StyledImage>
  );
}

const StyledImage = styled.div`
  margin-left: -25vw;
  height: calc(100vh - 300px);
  min-height: 268px;
  max-height: 600px;
  position: relative;

  ${media.greaterThan("md")`
    min-height: 460px;
    margin-left: -34vw;
    max-width: 70vw;
  `};

  ${media.greaterThan("1500px")`
    max-width: 65vw;
  `};

  .gatsby-image-wrapper {
    height: 100%;
    position: static !important;
  }
`;

const AnimationWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 100%;
    height: 100%;
    position: relative;

    ${media.greaterThan("xs")`
      width: 520px;
      height: 440px;
      margin-left: 18px;
    `};

    ${media.greaterThan("sm")`
      width: 600px;
      height: 520px;
    `};

    ${media.greaterThan("lg")`
      width: 725px;
      margin-left: -5px;
    `};
  }
  span {
    text-transform: uppercase;
    color: white;
    font-size: 3vw;
    font-weight: bold;
    display: inline-block;
    position: absolute;
    z-index: 1;
    line-height: 1;
    transition: 0.2s;
    animation-duration: 1s;
    animation-fill-mode: both;
    opacity: 1;

    ${media.greaterThan("xs")`
      font-size: 18px;
    `};

    ${media.greaterThan("sm")`
      font-size: 20px;
    `};

    ${media.greaterThan("lg")`
      font-size: 23px;
    `};

    &.word_1 {
      top: 45%;
      left: 42%;
      transform: rotate(-90deg);
      transform-origin: top left;
      animation-name: fadeInUp;
      animation-delay: 0.7s;

      ${media.greaterThan("xs")`
        top: 42%;
        left: 39%;
      `};

      ${media.greaterThan("lg")`
        top: 41%;
      `};
    }

    &.word_2 {
      top: 51%;
      left: 48%;
      transform: rotate(-90deg);
      transform-origin: top left;
      font-size: 5vw;
      animation-name: fadeInUp;
      animation-delay: 0.4s;

      ${media.greaterThan("xs")`
        font-size: 29px;
        left: 45%;
      `};

      ${media.greaterThan("sm")`
        font-size: 32px;
      `};

      ${media.greaterThan("lg")`
        font-size: 38px;
        top: 51%;
      `};
    }

    &.word_3 {
      top: 45%;
      left: 56%;
      transform: rotate(-90deg);
      transform-origin: top left;
      font-size: 5vw;
      animation-name: fadeInUp;
      animation-delay: 0.3s;

      ${media.greaterThan("xs")`
        top: 42%;
        left: 53%;
        font-size: 29px;
      `};

      ${media.greaterThan("sm")`
        font-size: 32px;
      `};

      ${media.greaterThan("lg")`
        font-size: 38px;
        top: 41%;
      `};
    }

    &.word_4 {
      top: 43%;
      left: 65%;
      animation-name: fadeInRight;
      animation-delay: 0.8s;

      ${media.greaterThan("xs")`
        top: 38%;
        left: 62%;
      `};

      ${media.greaterThan("lg")`
        top: 37%;
      `};
    }

    &.word_5 {
      top: 48%;
      left: 56%;
      font-size: 5vw;
      animation-name: fadeInRight;
      animation-delay: 0.1s;

      ${media.greaterThan("xs")`
        font-size: 29px;
        top: 45%;
        left: 53%;
      `};

      ${media.greaterThan("sm")`
        font-size: 32px;
      `};

      ${media.greaterThan("lg")`
        font-size: 38px;
        top: 44%;
      `};
    }

    &.word_6 {
      top: 53%;
      left: 48%;
      font-size: 5vw;
      animation-name: fadeInRight;
      animation-delay: 0.4s;

      ${media.greaterThan("xs")`
        font-size: 29px;
        left: 45%;
      `};

      ${media.greaterThan("sm")`
        font-size: 32px;
      `};

      ${media.greaterThan("lg")`
        font-size: 38px;
        top: 54%;
      `};
    }

    &.word_7 {
      top: 59%;
      left: 56%;
      animation-name: fadeInRight;
      animation-delay: 0.2s;

      ${media.greaterThan("xs")`
        top: 62%;
        left: 53%;
      `};

      ${media.greaterThan("lg")`
        top: 63%;
      `};
    }

    &.word_8 {
      top: 55%;
      left: 48%;
      transform: rotate(90deg);
      transform-origin: bottom left;
      font-size: 5vw;
      animation-name: fadeInDown;
      animation-delay: 0.5s;

      ${media.greaterThan("xs")`
        font-size: 29px;
        left: 45%;
      `};

      ${media.greaterThan("sm")`
        font-size: 32px;
      `};

      ${media.greaterThan("lg")`
        font-size: 38px;
        top: 56%;
      `};
    }

    &.word_9 {
      top: 52%;
      left: 42%;
      transform: rotate(90deg);
      transform-origin: bottom left;
      animation-name: fadeInDown;
      animation-delay: 0.3s;

      ${media.greaterThan("xs")`
        top: 50%;
        left: 39%;
      `};

      ${media.greaterThan("lg")`
        top: 51%;
      `};
    }

    &.word_10 {
      top: 54%;
      left: 19%;
      animation-name: fadeInLeft;
      animation-delay: 0.4s;

      ${media.greaterThan("xs")`
        left: 16%;
      `};

      ${media.greaterThan("lg")`
        top: 55%;
      `};
    }

    &.word_11 {
      top: 48%;
      left: 8%;
      font-size: 5vw;
      animation-name: fadeInLeft;
      animation-delay: 0.8s;

      ${media.greaterThan("xs")`
        font-size: 29px;
        top: 45%;
        left: 5%;
      `};

      ${media.greaterThan("sm")`
        font-size: 32px;
      `};

      ${media.greaterThan("lg")`
        font-size: 38px;
      `};
    }
  }

  @keyframes fadeInDown {
    from {
      opacity: 0;
      margin-top: 100px;
    }

    to {
      opacity: 1;
      margin-top: 0;
    }
  }

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(200px, 0, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      margin-top: -100px;
    }

    to {
      opacity: 1;
      margin-top: 0px;
    }
  }
`;
