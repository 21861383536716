export default {
  langLabel: "中文",
  projectCategory: {
    all: "所有",
    active: "活性",
    exited: "退出",
    internet: "互联网",
    technology: "高科技",
    consumer: "大消费",
    healthcare: "医疗健康",
    deeptech: "创新科技",
    digitalEconomy: "新数字经济",
    fintech: "fintech",
    enterprise: "enterprise",
  },
};
