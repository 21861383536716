import React from "react";
import CookieBanner from "react-cookie-banner";

import Cookies from "./Cookies";
import CookieLang from "./CookieLang";

export default function CookieComponent({ cookie = {} }) {
  const { component } = cookie;

  switch (component) {
    case "element_cookieLang":
      return (
        <CookieBanner
          cookie="user-has-chosen-lang"
          dismissOnScroll={false}
          cookieExpiration={{ hours: 1 }}
        >
          {(onAccept) => <CookieLang data={cookie} onAccept={onAccept} />}
        </CookieBanner>
      );
    case "element_cookieBanner":
      return (
        <CookieBanner
          cookie="user-has-accepted-cookies"
          dismissOnScroll={false}
        >
          {(onAccept) => <Cookies data={cookie} onAccept={onAccept} />}
        </CookieBanner>
      );
    default:
      return null;
  }
}
