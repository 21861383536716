import React, { useState } from "react";
import styled from "@emotion/styled";

import { Row, Column } from "components/common/Grid";
import Link from "components/elements/Link";

import useMemberModal from "utils/hooks/useMemberModal";

import Member from "./Member";
import Modal from "./Modal";

export default function MembersTable({
  members,
  label,
  membersOffset = 6,
  linear,
  link,
}) {
  const membersLength = members.length;

  const [offset, setOffset] = useState(membersOffset);
  const [
    open,
    member,
    handleOpenModal,
    handleCloseModal,
    nextMember,
    prevMember,
  ] = useMemberModal(members);

  function handleShowMore() {
    offset < membersLength
      ? setOffset(membersLength)
      : setOffset(membersOffset);
  }

  const parsedLabel = label.replace("0", `${members.length - offset}`);
  return (
    <StyledTable>
      <Row>
        {members
          .map((item) => (
            <Member
              key={item._uid}
              data={item}
              onClick={handleOpenModal}
              linear={linear}
            />
          ))
          .slice(0, offset)}
        {link && link[0] && (
          <StyledLink>
            <Link {...link[0]} className="primary" />
          </StyledLink>
        )}
      </Row>
      {offset < membersLength && (
        <button type="button" onClick={handleShowMore} aria-label="Show more">
          {parsedLabel}
        </button>
      )}
      {members && members.length && (
        <Modal
          member={member}
          open={open}
          close={handleCloseModal}
          next={nextMember}
          prev={prevMember}
          onClose={handleCloseModal}
          center
          showCloseIcon={false}
          blockScroll={false}
        />
      )}
    </StyledTable>
  );
}

const StyledTable = styled(Column)`
  & > button {
    display: block;
    margin: 51px auto 0;
    padding-bottom: 18px;
    color: ${({ theme }) => theme.colors.textColor};
    font-family: ${({ theme }) => theme.fonts.headerFont};
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 2px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.textColor};
  }
`;

const StyledLink = styled.div`
  width: 33.3333%;
  padding-bottom: 26px;
  padding-left: 13px;
  padding-right: 13px;
  flex: 0 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
