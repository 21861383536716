import React from "react";
import isEmpty from "lodash/isEmpty";

import useMatchMedia from "utils/hooks/useMatchMedia";

import { Container, Row, Column } from "components/common/Grid";

import Section from "./Section";
import Title from "./Title";
import Slider from "./Slider";

export default function Testimonials({ data }) {
  const {
    heading,
    lead,
    slider_autoplay,
    slider_delay,
    testimonials,
    component_name,
  } = data || {};
  const isMobileScreen = useMatchMedia("(max-width: 576px)");

  const id = !isEmpty(component_name)
    ? component_name
    : heading.toLowerCase().split(" ").join("-");

  return (
    <Section id={id}>
      <Container>
        <Row>
          <Column>
            <Title>
              <h2 className="h1">{heading}</h2>
              <p className="lead">{lead}</p>
            </Title>
            {!isEmpty(testimonials) && (
              <Slider
                testimonials={testimonials}
                autoplay={slider_autoplay}
                delay={slider_delay}
                mobile={isMobileScreen}
              />
            )}
          </Column>
        </Row>
      </Container>
    </Section>
  );
}
