import React from "react";
import styled from "@emotion/styled";

import media from "utils/media";

export default function Feature({ title, description, icon }) {
  return (
    <StyledFeature>
      <img src={icon} alt={title} />
      <h3>{title}</h3>
      <p>{description}</p>
    </StyledFeature>
  );
}

const StyledFeature = styled.div`
  text-align: center;

  ${media.greaterThan("xs")`
    width: 400px;
    margin: 0 auto;
  `};

  ${media.greaterThan("sm")`
    width: 50%;
    margin-bottom: 70px;
    padding: 20px;
  `};

  ${media.greaterThan("md")`
    margin-bottom: 0;
  `};

  ${media.greaterThan("lg")`
    width: 25%;
    padding: 35px;
  `};

  &:not(:last-of-type) {
    ${media.lessThan("sm")`
      margin-bottom: 70px;
    `};
  }

  &:not(:first-of-type) {
    ${media.lessThan("sm")`
      padding-top: 70px;
      border-top: 1px solid #00294C;
    `};

    ${media.greaterThan("lg")`
      border-left: 1px solid #00294C;
    `};
  }

  img {
    max-width: 130px;

    ${media.greaterThan("lg")`
      max-width: none;
      height: 95px;
    `};
  }

  h3 {
    color: ${({ theme }) => theme.colors.blue_v6};
    font-size: 22px;
    line-height: 1;
    margin: 30px 0;
    font-weight: 500;
  }

  p {
    font-size: 26px;
    line-height: 1.2;
    letter-spacing: 0.2;
  }
`;
