import styled from "@emotion/styled";

import media from "utils/media";

const Wrapper = styled.div`
  .swiper-container {
  }

  .swiper-pagination {
    bottom: 6px;
    font-family: ${({ theme }) => theme.fonts.headerFont};
    color: ${({ theme }) => theme.colors.textColor};

    ${({ theme }) => media.greaterThan("sm")`
      bottom: 8px;
      width: 500px;
      right: 0;
      left: auto;
      text-align: left;

      &:before {
        content: '';
        position: absolute;
        left: 15%;
        bottom: 11px;
        width: 260px;
        height: 2px;
        background-color: ${theme.colors.blue_v2};
      }
    `};

    ${media.greaterThan("md")`
      right: auto;
      left: 0;
      bottom: auto;
      top: 600px;
      width: 350px;

      &:before {
        width: 150px;
      }
    `};

    ${media.greaterThan("lg")`
      width: 400px;
      top: 650px;

      &:before {
        width: 225px;
      }
    `};

    .swiper-pagination-current {
      font-weight: bold;
    }
  }

  .swiper-next,
  .swiper-prev {
    display: block;
    position: absolute;
    bottom: 0px;
    width: 36px;
    z-index: 10;

    ${media.greaterThan("sm")`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      opacity: 0.7;
      background-color: #e0f3fa;
    `};

    ${media.greaterThan("md")`
      bottom: auto;
      top: 592px;
    `};

    ${media.greaterThan("lg")`
      top: 642px;
    `};

    svg {
      display: block;

      ${media.greaterThan("sm")`
        width: 70%;
        height: 70%;
      `};
    }
  }

  .swiper-next {
    right: -12px;

    ${media.greaterThan("sm")`
      right: 0;
    `};

    ${media.greaterThan("md")`
      right: auto;
      left: 300px;
    `};

    ${media.greaterThan("lg")`
      left: 370px;
    `};
  }

  .swiper-prev {
    left: -12px;

    ${media.greaterThan("sm")`
      left: auto;
      right: 80px;
    `};

    ${media.greaterThan("md")`
      right: auto;
      left: 220px;
    `};

    ${media.greaterThan("lg")`
      left: 300px;
    `};
  }
`;

export default Wrapper;
