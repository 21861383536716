import React, { useRef, useState } from "react";
import isEmpty from "lodash/isEmpty";
import styled from "@emotion/styled";

import useOnScreen from "utils/hooks/useOnScreen";
import useScrollPosition from "utils/hooks/useScrollPosition";

import { Container, Row } from "components/common/Grid";
import DownloadLink from "components/elements/DownloadLink";
import Markdown from "components/elements/Markdown";

import Section from "./Section";
import Wrapper from "./Wrapper";

export default function DownloadSection({ data }) {
  const ref = useRef(null);
  const [bottom, setBottom] = useState(null);
  const { background, heading, content, download_links, fixed_position } =
    data || {};
  const onScreen = useOnScreen(ref, "0px");

  useScrollPosition(
    ({ currPos }) => setBottom(currPos.bottom),
    [],
    ref,
    false,
    300
  );

  const handleShow = () => setShow((prev) => !prev);

  return (
    <Section ref={ref}>
      {fixed_position ? (
        <StyledDiv bg={background} isOnScreen={onScreen} bottom={bottom}>
          <Container>
            <Row>
              <Wrapper fixed={!onScreen}>
                <h2 className="h1">{heading}</h2>
                <div className="download_links">
                  {!isEmpty(download_links) &&
                    download_links.map((item) => (
                      <DownloadLink key={item.title} {...item} />
                    ))}
                </div>
                {!isEmpty(content) && (
                  <div className="content">
                    <Markdown>{content}</Markdown>
                  </div>
                )}
              </Wrapper>
            </Row>
          </Container>
        </StyledDiv>
      ) : (
        <Container>
          <Row>
            <Wrapper>
              <h2 className="h1">{heading}</h2>
              <div className="download_links">
                {!isEmpty(download_links) &&
                  download_links.map((item) => (
                    <DownloadLink key={item.title} {...item} />
                  ))}
              </div>
              {!isEmpty(content) && (
                <div className="content">
                  <Markdown>{content}</Markdown>
                </div>
              )}
            </Wrapper>
          </Row>
        </Container>
      )}
    </Section>
  );
}

const StyledDiv = styled.div`
  background-color: ${({ bg }) => bg || "#FFF"};
  position: ${({ isOnScreen, bottom }) =>
    isOnScreen || bottom < 400 ? "static" : "fixed"};
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 90;
`;
