import styled from "@emotion/styled";

import media from "utils/media";

const Lead = styled.div`
  padding: 50px 0;
  text-align: center;

  ${media.greaterThan("lg")`
    padding: 90px 0;
  `};
`;

export default Lead;
