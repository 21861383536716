import styled from "@emotion/styled";

import { Column } from "components/common/Grid";

import media from "utils/media";

const Content = styled(Column)`
  margin-bottom: 21px;

  ${media.greaterThan("xs")`
    margin-bottom: 40px;
  `};

  ${media.greaterThan("md")`
    margin-bottom: 0;
    position: absolute;
    top: 18%;
    left: 0;
  `};

  ${media.greaterThan("lg")`
    position: absolute;
    top: 24%;
    left: 0;
  `};

  h2 {
    margin-bottom: 15px;
  }

  .lead {
    margin-bottom: 18px;
  }
`;

export default Content;
