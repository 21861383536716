import styled from "@emotion/styled";

import { Row } from "components/common/Grid";

import media from "utils/media";

const Number = styled(Row)`
  text-align: center;
  justify-content: space-between;

  div {
    width: 100%;
    padding: 0 26px;

    ${media.greaterThan("sm")`
      width: 50%;
      margin-bottom: 50px;
    `};

    ${({ cols }) => media.greaterThan("md")`
      width: calc(100% / ${cols});
      margin-bottom: 0;
    `};

    & + div {
      margin-top: 60px;

      ${media.greaterThan("sm")`
        margin-top: 0;
      `};
    }
  }

  h2 {
    margin-bottom: 15px;
    font-size: 42px;
    font-weight: bold;
    line-height: 1;

    ${media.greaterThan("xs")`
      font-size: 48px;
    `};

    ${media.greaterThan("md")`
        margin-top: 35px;
      `};
  }

  p {
    max-width: 50%;
    margin: 0 auto;
    font-family: ${({ theme }) => theme.fonts.headerFont};
    letter-spacing: 0.1px;
    line-height: 1;

    ${media.greaterThan("xs")`
      font-size: 20px;
    `};

    ${media.greaterThan("md")`
      max-width: 100%;
    `};

    & + h2 {
      margin-top: 15px;
    }
  }
`;

export default Number;
