import styled from "@emotion/styled";

import media from "utils/media";

const Info = styled.div`
  margin: -235px auto 40px;
  width: 100%;
  max-width: 350px;

  ${media.greaterThan("md")`
    width: 30%;
    max-width: none;
    height: 100%;
    margin: 0;
  `};

  ${media.greaterThan("lg")`
    width: 40%;
  `};

  .gatsby-image-wrapper {
    max-height: 350px;

    ${media.greaterThan("md")`
      width: 300px;
      max-height: none;
      height: 300px;
      margin-top: -40px;
      margin-bottom: 20px;
      margin-right: 0;
      margin-left: -40px;
    `};

    ${media.greaterThan("lg")`
      margin-top: -74px;
      width: 500px;
      height: 500px;
      margin-left: -190px;
    `};
  }

  .bio {
    margin-top: 23px;

    ${media.greaterThan("md")`
      margin-top: 0;
      width: 260px;
    `};

    ${media.greaterThan("lg")`
      width: 310px;
    `};

    .name {
      line-height: 24px;
      font-weight: bold;

      ${media.greaterThan("md")`
        font-size: 18px;
      `};
    }

    .title {
      ${media.greaterThan("md")`
        line-height: 24px;
      `};
    }

    .role {
      line-height: 1.3;

      ${media.greaterThan("md")`
        font-size: 16px;
        margin-top: 6px;
      `};
    }
  }
`;

export default Info;
