import styled from "@emotion/styled";

import { Column } from "components/common/Grid";

import media from "utils/media";

const Content = styled(Column)`
  width: 100%;
  margin-bottom: 80px;
  position: relative;

  ${media.greaterThan("md")`
    padding: 0 130px;
    margin-bottom: 100px;
  `};

  ${media.greaterThan("lg")`
    padding: 0 266px;
  `};

  ul {
    list-style: disc;
  }

  & > div {
    &:after {
      content: "";
      display: block;
      clear: both;
    }

    h2 {
      line-height: 1.38;
      margin: 70px 0;

      ${media.greaterThan("md")`
        margin: 40px 0;
      `};
    }

    h3 {
      font-size: 20px;
      margin: 30px 0;

      ${media.greaterThan("md")`
        font-size: 26px;
        margin: 50px 0;
      `};
    }

    & > p {
      margin-bottom: 30px;

      ${media.greaterThan("md")`
        font-size: 20px;
        margin-bottom: 40px;
      `};
    }

    & > p:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export default Content;
