import styled from "@emotion/styled";

import media from "utils/media";

const Section = styled.section`
  padding: 50px 0;

  ${media.greaterThan("sm")`
    padding-top: 30px;
    padding-bottom: 50px;
  `};

  ${media.greaterThan("md")`
    padding-top: 80px;
    padding-bottom: 80px;
  `};
`;

export default Section;
