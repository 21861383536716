import React, { useEffect } from "react";
import { useCountUp } from "react-countup";

export default function AnimatedReact({
  value = 0,
  before = "",
  after = "",
  onScreen = false,
}) {
  const parsedValue = value && parseInt(value, 10);
  const { countUp, start } = useCountUp({
    start: 0,
    end: parsedValue,
    delay: 1000,
    duration: 5,
  });

  useEffect(() => {
    if (onScreen) {
      start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScreen]);
  return (
    <span>
      {before}
      {countUp}
      {after}
    </span>
  );
}
