import styled from "@emotion/styled";

import media from "utils/media";

const SeeAllLink = styled.div`
  text-align: center;
  margin-top: 50px;

  ${media.greaterThan("sm")`
    text-align: left;
    margin-top: 0;
  `};

  a {
    display: inline-block;
    padding-bottom: 5px;
    font-family: ${({ theme }) => theme.fonts.headerFont};
    font-weight: bold;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-bottom: solid 1px ${({ theme }) => theme.colors.textColor};
  }
`;

export default SeeAllLink;
