import styled from "@emotion/styled";

const Wrapper = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  padding-top: 17px;
  padding-bottom: 17px;

  transition: background 0.3s ease;
  background: ${({ fixed }) => (fixed ? "white" : "transparent")};
`;

export default Wrapper;
