import React from "react";
import Markdown from "markdown-to-jsx";

import DownloadLink from "components/elements/DownloadLink";
import ContentImage from "components/elements/ContentImage";
import Blockquote from "components/elements/Blockquote";
import AnimatedNumber from "components/elements/AnimatedNumber";

export default function MarkdownComponent({ onScreen, ...rest }) {
  return (
    <Markdown
      options={{
        forceWrapper: true,
        forceBlock: true,
        overrides: {
          DownloadLink: {
            component: DownloadLink,
          },
          Image: {
            component: ContentImage,
          },
          Blockquote: {
            component: Blockquote,
          },
          AnimatedNumber: {
            component: AnimatedNumber,
            props: {
              onScreen,
            },
          },
        },
      }}
      {...rest}
    />
  );
}
