import React from "react";
import { StaticQuery, withPrefix, graphql } from "gatsby";
import { Helmet } from "react-helmet";

export default function Seo({
  seoData,
  pathname = "/",
  ogType,
  lang,
  siteName,
}) {
  return (
    <StaticQuery
      query={graphql`
        query SeoQuery {
          site {
            siteMetadata {
              name
              siteUrl
              title
              description
              seo {
                separator
                og {
                  fbAppId
                  type
                  image
                }
                twitter {
                  creator
                  card
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const {
          title: sbPageTitle,
          description: sbPageDescription,
          og_image,
          og_title,
          og_description,
        } = seoData;

        const {
          name,
          siteUrl,
          title,
          description,
          seo: { separator, og, twitter },
        } = data.site.siteMetadata;

        const pageTitle = sbPageTitle || title;
        const pageDescription = sbPageDescription || description;
        return (
          <Helmet>
            <title>
              {pageTitle
                ? pathname !== "/"
                  ? `${pageTitle} ${separator} ${siteName || name}`
                  : pageTitle
                : title}
            </title>
            <meta name="description" content={pageDescription} />
            <meta
              name="image"
              content={og_image || siteUrl + withPrefix(og.image)}
            />

            {/* OpenGraph tags */}
            <meta property="fb:app_id" content={og.fbAppId} />
            <meta property="og:url" content={siteUrl + pathname} />
            <meta property="og:type" content={ogType || og.type} />
            <meta property="og:title" content={og_title || og.title} />
            <meta
              property="og:description"
              content={og_description || pageDescription}
            />
            <meta
              property="og:image"
              content={og_image || siteUrl + withPrefix(og.image)}
            />

            {/* Twitter Card tags */}
            {twitter && twitter.card && (
              <meta name="twitter:card" content={twitter.card} />
            )}
            {twitter && twitter.creator && (
              <meta name="twitter:creator" content={twitter.creator} />
            )}
            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={pageDescription} />
            <meta
              name="twitter:image"
              content={og_image || siteUrl + withPrefix(og.image)}
            />
            <body className={lang || null} />
          </Helmet>
        );
      }}
    />
  );
}
