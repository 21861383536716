import React from "react";
import styled from "@emotion/styled";

import media from "utils/media";

import Image from "components/elements/Image";

export default function ContentImage({ big, url, alt, small }) {
  return (
    <ImageWrapper className={big ? "big" : small ? "small" : "normal"}>
      <Image
        fluid
        data={{
          alt_tag: alt,
          dominantColor: "#5e504a",
          image: url,
        }}
        options={{
          maxWidth: small ? 400 : 1000,
        }}
      />
    </ImageWrapper>
  );
}

export const ImageWrapper = styled.div`
  &.small {
    width: 100%;
    max-height: 200px;
    margin: 0;
    margin-bottom: 20px;
    display: none;

    ${media.greaterThan("sm")`
      max-height: 300px;
      display: block;
    `};

    ${media.greaterThan("lg")`
      margin-bottom: 40px;
    `};

    .gatsby-image-wrapper {
      ${media.greaterThan("sm")`
        max-height: 300px;
      `};
    }
  }

  &.normal,
  &.big {
    margin-left: -26px;
    margin-right: -26px;
    margin-bottom: 78px;
    margin-top: 30px;
  }

  &.normal {
    ${media.greaterThan("xs")`
      margin-left: 0;
      margin-right: 0;
    `};

    ${media.greaterThan("md")`
      margin-left: -30px;
      margin-right: -30px;
      margin-bottom: 40px;
      max-width: 860px;
    `};

    ${media.greaterThan("lg")`
      margin-left: -80px;
      margin-right: -80px;
    `};

    .gatsby-image-wrapper {
      ${media.greaterThan("xs")`
        max-height: 300px;
      `};

      ${media.greaterThan("sm")`
        max-height: 400px;
      `};

      ${media.greaterThan("md")`
        max-height: 540px;
      `};
    }
  }

  &.big {
    ${media.greaterThan("xs")`
      margin-left: calc(-100vw / 2 + 33.75rem / 2 - 26px ) ;
      margin-right: calc(-100vw / 2 + 33.75rem / 2 - 26px );
    `};

    ${media.greaterThan("sm")`
      margin-left: calc(-100vw / 2 + 45rem / 2 - 26px ) ;
      margin-right: calc(-100vw / 2 + 45rem / 2 - 26px );
    `};

    ${media.greaterThan("md")`
      margin-left: -104px;
      margin-right: -104px;
      margin-bottom: 78px;
      max-width: 100vw;
    `};

    ${media.greaterThan("lg")`
      margin-left: -240px;
      margin-right: -240px;
    `};

    .gatsby-image-wrapper {
      ${media.greaterThan("xs")`
        max-height: 400px;
      `};

      ${media.greaterThan("sm")`
        max-height: 500px;
      `};

      ${media.greaterThan("md")`
        max-height: 600px;
      `};
    }
  }
`;
