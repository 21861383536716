import styled from "@emotion/styled";
import { css } from "@emotion/react";

import media from "utils/media";

import quoteIco from "assets/svg/quote-mark.svg";

const Wrapper = styled.blockquote`
  position: relative;
  width: calc(100% + 2 * 26px);
  margin: 80px -26px 65px -26px;
  padding: 50px 26px;
  background-color: ${({ theme }) => theme.colors.blue_v2};

  ${media.greaterThan("xs")`
    width: 100vw;
    margin-left: calc(-100vw / 2 + 33.75rem / 2 - 26px);
    margin-right: calc(-100vw / 2 + 33.75rem / 2 - 26px);
    padding-left: calc(100vw / 2 - 33.75rem / 2 + 26px);
    padding-right: calc(100vw / 2 - 33.75rem / 2 + 26px);
  `};

  ${media.greaterThan("sm")`
    margin-left: calc(-100vw / 2 + 45rem / 2 - 26px);
    margin-right: calc(-100vw / 2 + 45rem / 2 - 26px);
    padding-left: calc(100vw / 2 - 45rem / 2 + 26px);
    padding-right: calc(100vw / 2 - 45rem / 2 + 26px);
  `};

  ${({ fullWidth }) => media.greaterThan("md")`
    ${
      fullWidth
        ? css({
            width: "100vw",
            float: "none",
            marginLeft: "calc(-100vw / 2 + 60rem / 2 - 130px)",
            marginRight: "calc(-100vw / 2 + 60rem / 2 - 130px)",
            marginTop: "80px",
            paddingLeft: "calc(100vw / 2 - 60rem / 2 + 130px)",
            paddingRight: "calc(100vw / 2 - 60rem / 2 + 130px)",
          })
        : css({
            width: "370px",
            float: "right",
            marginLeft: "50px",
            marginRight: "-80px",
            marginTop: "0",
            paddingLeft: "30px",
            paddingRight: "30px",
          })
    };
  `};

  ${({ fullWidth }) => media.greaterThan("lg")`
    ${
      fullWidth
        ? css({
            width: "100vw",
            marginLeft: "calc(-100vw / 2 + 77rem / 2 - 266px)",
            marginRight: "calc(-100vw / 2 + 77rem / 2 - 266px)",
            paddingLeft: "calc(100vw / 2 - 77rem / 2 + 266px)",
            paddingRight: "calc(100vw / 2 - 77rem / 2 + 266px)",
          })
        : css({
            width: "420px",
            marginLeft: "100px",
            marginRight: "-140px",
          })
    };
  `};

  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: 2;
    width: 50px;
    height: 50px;
    background-image: url(${quoteIco});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:before {
    top: -15px;
    left: 26px;
    transform: rotate(180deg);

    ${media.greaterThan("xs")`
      left: calc(100vw / 2 - 33.75rem / 2 + 26px);
    `};

    ${media.greaterThan("sm")`
      left: calc(100vw / 2 - 45rem / 2 + 26px);
    `};

    ${({ fullWidth }) => media.greaterThan("md")`
      left: ${fullWidth ? "calc(100vw / 2 - 60rem / 2 + 130px)" : "15px"};
    `};

    ${({ fullWidth }) => media.greaterThan("lg")`
      left: ${fullWidth ? "calc(100vw / 2 - 77rem / 2 + 266px)" : "15px"};
    `};
  }

  &:after {
    bottom: -15px;
    right: 26px;

    ${media.greaterThan("xs")`
      right: calc(100vw / 2 - 33.75rem / 2 + 26px);
    `};

    ${media.greaterThan("sm")`
      right: calc(100vw / 2 - 45rem / 2 + 26px);
    `};

    ${({ fullWidth }) => media.greaterThan("md")`
      right: ${fullWidth ? "calc(100vw / 2 - 60rem / 2 + 130px)" : "15px"};
    `};

    ${({ fullWidth }) => media.greaterThan("lg")`
      right: ${fullWidth ? "calc(100vw / 2 - 77rem / 2 + 266px)" : "15px"};
    `};
  }
`;

export default Wrapper;
