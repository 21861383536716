import styled from "@emotion/styled";

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.5s ease;
  pointer-events: none;

  .gatsby-image-wrapper {
    height: 100%;
    position: static !important;
  }
`;

export default ImageWrapper;
