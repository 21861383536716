import styled from "@emotion/styled";

import media from "utils/media";

const StyledImage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  max-height: 400px;

  ${media.greaterThan("xs")`
    max-height: 500px;
  `};

  ${media.greaterThan("sm")`
    max-height: 600px;
  `};

  ${({ isAnimation }) => media.greaterThan("md")`
    max-height: ${isAnimation ? "none" : "600px"};
  `};

  ${({ isAnimation }) => media.greaterThan("lg")`
    max-height: ${isAnimation ? "none" : "800px"};
  `};

  ${({ isAnimation }) => media.greaterThan("1600px")`
    max-height: ${isAnimation ? "none" : "900px"};
  `};

  ${({ isAnimation }) => media.greaterThan("1920px")`
    max-height: ${isAnimation ? "none" : "1000px"};
  `};

  .gatsby-image-wrapper {
    height: 100%;
    position: static !important;
  }
`;

export default StyledImage;
