import styled from "@emotion/styled";

import { Column } from "components/common/Grid";

import media from "utils/media";

const Hero = styled(Column)`
  margin-bottom: 100px;

  ${media.greaterThan("lg")`
    margin-bottom: 94px;
  `};

  & > h2 {
    line-height: 1;
    margin-bottom: 50px;
  }
`;

export default Hero;
