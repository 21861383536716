import Modal from "react-responsive-modal";
import React from "react";
import styled from "@emotion/styled";

import media from "utils/media";

export default function ModalComponent(props) {
  return (
    <Modal {...props} classNames={classNamesModal}>
      <StyledModal>{props.children}</StyledModal>
    </Modal>
  );
}

const classNamesModal = {
  overlay: "portfolio-overlay",
  modal: "portfolio-modal",
  transitionEnter: "portfolio-tr-enter",
  transitionEnterActive: "portfolio-tr-enter-active",
};

const StyledModal = styled.div`
  color: ${({ theme }) => theme.colors.white};

  nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 134px;

    a {
      display: block;
      width: 30px;
      height: 30px;
      &:focus {
        outline: none;
      }
    }

    button {
      width: 30px;
      text-align: right;

      img {
        max-width: 25px;
      }
    }
  }

  h2 {
    font-size: 58px;
    line-height: 1;
    font-weight: bold;
    margin-bottom: 50px;
  }

  p {
    ${media.greaterThan("xs")`
      width: 80%;
    `};
  }

  & > a:last-of-type {
    display: block;
    margin-top: 54px;
    font-family: ${({ theme }) => theme.fonts.headerFont};
    font-weight: bold;
    line-height: 1;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
`;
