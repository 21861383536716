import React, { forwardRef } from "react";
import { Box } from "@rebass/grid/emotion";

const Column = forwardRef((props, ref) => <Box ref={ref} {...props} />);

Column.defaultProps = {
  px: 4,
  width: [1],
};

export default Column;
