import React, { useState, useRef, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { navigate } from "gatsby";

import styled from "@emotion/styled";

import useMatchMedia from "utils/hooks/useMatchMedia";
import getTranslations from "utils/getTranslations";

import { Container, Row } from "components/common/Grid";
import Image from "components/elements/Image";
import Markdown from "components/elements/Markdown";
import { useStateValue } from "components/common/GlobalState";

import Section from "./Section";
import Hero from "./Hero";
import Lead from "./Lead";
import Navigation from "./Navigation";
import ProjectsTitle from "./ProjectsTitle";
import Project from "./Project";
import Slider from "./Slider";
import { getFluidGatsbyImage } from "gatsby-storyblok-image";

const { GATSBY_SORT_CATEGORIES = false } = process.env;

const getAllCategories = (array, show_all) => {
  const allCategories =
    array && array.reduce((p, { category }) => [...p, category], []);

  if (show_all) {
    GATSBY_SORT_CATEGORIES
      ? allCategories.unshift("all")
      : allCategories.push("all");
  }

  return Array.from(new Set(allCategories));
};

const pushToTheEnd = (array, category) => {
  const newArray = array.filter((item) => item !== category);
  newArray.push(category);

  return newArray;
};

export default function Portfolio({ data }) {
  const {
    testimonials,
    testimonials_autoplay,
    testimonials_delay,
    projects_heading,
    default_category,
    show_all,
    projects,
    projects_showLabel,
    projects_hideLabel,
    heading,
    lead_isVisible,
    lead,
    small_image,
    default_offset = 12,
  } = data || {};

  const [globalState = {}] = useStateValue({});
  const isSmallScreen = useMatchMedia("(max-width: 991px)");
  const isMobileScreen = useMatchMedia("(max-width: 576px)");
  const allCategories = getAllCategories(projects, show_all);
  const [categories, setCategories] = useState(allCategories);
  const [category, setCategory] = useState();
  const [offset, setOffset] = useState(default_offset);

  const sortedCategories = useRef(pushToTheEnd(allCategories, "exited"));
  const parsedDefaultCategory = useRef(
    !default_category || default_category === "" ? "all" : default_category
  );

  useEffect(() => {
    if (GATSBY_SORT_CATEGORIES && categories !== sortedCategories.current) {
      setCategories(sortedCategories.current);
    }

    // set initial category
    setCategory(parsedDefaultCategory.current);
  }, [sortedCategories, parsedDefaultCategory]);

  const DEFAULT_LANG = process.env.GATSBY_DEFAULT_LANG || "en";

  const categoryNames =
    getTranslations(globalState.lang || DEFAULT_LANG, "projectCategory") || {};

  function handleCategory(category) {
    setCategory(category);
  }

  function showLess() {
    setOffset(default_offset);
    navigate("/#projects");
  }

  function showMore(newOffset) {
    setOffset(newOffset);
  }

  function handleOffset() {
    offset < projects.length ? showMore(projects.length) : showLess();
  }

  projects &&
    projects.forEach((project) => {
      getFluidGatsbyImage(project.logo_black, {
        quality: 100,
        smartCrop: false,
        maxWidth: 200,
        downloadLocal: true,
      });
      getFluidGatsbyImage(project.logo_white, {
        quality: 100,
        smartCrop: false,
        maxWidth: 200,
        downloadLocal: true,
      });
    });

  return (
    <Section id="portfolio">
      <Container>
        <Row>
          <Hero>
            <h2 className="h1">{heading}</h2>
            {!isEmpty(testimonials) && (
              <Slider
                testimonials={testimonials}
                autoplay={testimonials_autoplay}
                delay={testimonials_delay}
                mobile={isMobileScreen}
              />
            )}
          </Hero>
          {lead_isVisible && (
            <Lead>
              {!isEmpty(lead) && (
                <div>
                  <Markdown>{lead}</Markdown>
                </div>
              )}
              <Image fluid data={small_image} />
            </Lead>
          )}
          {projects_heading && (
            <ProjectsTitle>
              <h2 className="h1">{projects_heading}</h2>
            </ProjectsTitle>
          )}
        </Row>
      </Container>
      <div id="projects">
        {categories && categories.length > 1 && (
          <Navigation>
            <ul>
              {categories.map((item, i) => {
                const projectsWithCategory =
                  projects && item !== "all"
                    ? projects.filter((el) => el.category === item).length
                    : projects.length;
                return (
                  <li
                    key={`portfolio__category__${item}_${i}`}
                    className={category === item ? "active" : ""}
                  >
                    <button
                      onClick={() => handleCategory(item)}
                      aria-label={`Tab with ${item} projects`}
                    >
                      {categoryNames[item]}
                      <span>({projectsWithCategory})</span>
                    </button>
                  </li>
                );
              })}
            </ul>
          </Navigation>
        )}
        <div css={Wrapper}>
          {!isEmpty(projects) && category !== "all"
            ? projects
                .filter((item) => item.category === category)
                .map((item) => (
                  <Project key={item._uid} {...item} small={isSmallScreen} />
                ))
                .slice(0, offset)
            : projects
                .map((item) => (
                  <Project key={item._uid} {...item} small={isSmallScreen} />
                ))
                .slice(0, offset)}
        </div>
      </div>
      <ToggleButton>
        {!isEmpty(projects) &&
          ((category === "all" && projects.length > default_offset) ||
            projects.filter((item) => item.category === category).length >
              default_offset) && (
            <button
              type="button"
              onClick={handleOffset}
              aria-label="Toggle projects"
            >
              {offset === default_offset
                ? projects_showLabel
                : projects_hideLabel}
            </button>
          )}
      </ToggleButton>
    </Section>
  );
}

const Wrapper = (theme) => ({
  width: "100%",
  backgroundColor: theme.colors.gray_v1,
  display: "flex",
  flexWrap: "wrap",
});

const ToggleButton = styled.div`
  height: 90px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  button {
    display: block;
    padding-bottom: 18px;
    color: ${({ theme }) => theme.colors.textColor};
    font-family: ${({ theme }) => theme.fonts.headerFont};
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 2px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.textColor};
  }
`;
