import { css } from "@emotion/react";

function getSizeFromBreakpoint(breakpointValue, breakpoints = {}) {
  if (breakpoints[breakpointValue]) {
    return breakpoints[breakpointValue];
  } else if (parseInt(breakpointValue)) {
    return breakpointValue;
  } else {
    console.error(
      "Media-Query: No valid breakpoint or size specified for media."
    );
    return "0";
  }
}

function generateMedia(breakpoints) {
  const lessThan =
    (breakpoint) =>
    (...args) =>
      css`
        @media (max-width: ${getSizeFromBreakpoint(breakpoint, breakpoints)}) {
          ${css(...args)}
        }
      `;

  const greaterThan =
    (breakpoint) =>
    (...args) =>
      css`
        @media (min-width: ${getSizeFromBreakpoint(breakpoint, breakpoints)}) {
          ${css(...args)}
        }
      `;

  const between =
    (firstBreakpoint, secondBreakpoint) =>
    (...args) =>
      css`
        @media (min-width: ${getSizeFromBreakpoint(
            firstBreakpoint,
            breakpoints
          )}) and (max-width: ${getSizeFromBreakpoint(
            secondBreakpoint,
            breakpoints
          )}) {
          ${css(...args)}
        }
      `;

  return Object.assign({
    lessThan,
    greaterThan,
    between,
  });
}

const breakpoints = {
  xs: "36rem",
  sm: "48rem",
  md: "62rem",
  lg: "80rem",
};

const media = generateMedia(breakpoints);

export default media;

// easy way

// const breakpoints = {
//   xs: 36,
//   sm: 48,
//   md: 62,
//   lg: 80
// };
// const media = n => {
//   const bpArray = Object.keys(breakpoints).map(key => [key, breakpoints[key]]);

//   const [result] = bpArray.reduce((acc, [name, size]) => {
//     if (n === name) return [...acc, `@media (min-width: ${size}rem)`];
//     return acc;
//   }, []);

//   return result;
// };
