import styled from "@emotion/styled";
import media from "utils/media";
import Link from "components/elements/Link";

const TileLink = styled(Link)`
  & {
    display: block;
    border-bottom: 1px solid #b1c3eb;
    padding: 10px 10px 0 10px;
    margin-left: -10px;
    margin-right: -10px;
    transition: 0.3s ease;

    ${media.greaterThan("md")`
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      margin: 0 20px;
    `};

    ${media.greaterThan("lg")`
      margin: 0 40px;
    `};

    h3 {
      line-height: 1;
      margin-bottom: 30px;

      ${media.greaterThan("md")`
        margin-bottom: 0;
        order: 2;
        margin-left: 20px;
        flex: 1;
      `};

      ${media.greaterThan("lg")`
        margin-left: 40px;
      `};
    }

    span {
      display: inline-block;
      margin-bottom: 25px;
      opacity: 0.3;

      ${media.greaterThan("md")`
        order: 1;
        margin-bottom: 0;
      `};
    }

    p {
      display: none;

      ${({ theme }) => media.greaterThan("md")`
        display: block;
        order: 3;
        opacity: 0;
        transition: 0.3s ease;
        font-size: 16px;
        font-family: ${theme.fonts.headerFont};
        font-weight: bold;
        letter-spacing: 2px;
        text-transform: uppercase;
      `};
    }

    &:hover {
      box-shadow: 0 12px 20px -3px rgba(0, 41, 76, 0.2);
      border-bottom: none;

      ${media.greaterThan("md")`
        padding: 30px 20px 25px;
        margin: 0;

        p {
          opacity: 0.3;
        }

        &:nth-of-type(n + 2) {
          margin-top: 44px;
        }
      `};

      ${media.greaterThan("lg")`
        padding: 50px 40px 25px;
        margin: 0;
      `};
    }
  }
`;

export default TileLink;
