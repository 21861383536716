import React from "react";

import Image from "components/elements/Image";

import StyledImages from "./StyledImages";

export default function Images({ images }) {
  return (
    <StyledImages>
      {images.map((item) => (
        <div key={item._uid}>
          <Image
            fluid
            data={item.asset}
            options={{
              maxWidth: 300,
              maxHeight: 300,
              useBase64: false,
              quality: 90,
            }}
          />
        </div>
      ))}
    </StyledImages>
  );
}
