import styled from "@emotion/styled";

import media from "utils/media";

const Button = styled.button`
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.blue_v2};

  display: block;
  text-align: start;
  text-decoration: underline;
  margin-top: 11px;

  ${media.greaterThan("md")`
    margin-top: 25px;
  `};
`;

export default Button;
