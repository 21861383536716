import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";

import media from "utils/media";

import ColoredLogo from "assets/img/logo.png";
import WhiteLogo from "assets/img/vtac-white.png";

export default function Logo({ open, fixed, url, theme }) {
  return (
    <Wrapper to={url} open={open} fixed={fixed ? 1 : 0} aria-label="Home">
      <MainLogo
        src={theme === "white" || open ? WhiteLogo : ColoredLogo}
        alt="logo white"
      />
      <AdditionalLogo src={ColoredLogo} alt="logo colored" />
    </Wrapper>
  );
}

const MainLogo = styled.img``;
const AdditionalLogo = styled.img``;

const Wrapper = styled(Link)`
  display: block;
  width: 100px;
  position: relative;
  z-index: 102;

  ${media.greaterThan("xs")`
    width: 120px;
  `};

  ${media.greaterThan("sm")`
    width: 140px;
  `};

  ${media.greaterThan("md")`
    width: 160px;
  `};

  ${AdditionalLogo} {
    display: ${({ fixed, open }) => (fixed && !open ? "block" : "none")};
  }

  ${MainLogo} {
    display: ${({ fixed, open }) =>
      open ? "block" : fixed ? "none" : "block"};
  }
`;
