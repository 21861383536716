import React from "react";
import styled from "@emotion/styled";

import media from "utils/media";

import { ReactComponent as LogoDefault } from "assets/svg/logo-vertex.svg";
import { ReactComponent as LogoUsa } from "assets/svg/logo_usa.svg";
import { ReactComponent as LogoIsrael } from "assets/svg/logo_israel.svg";
import { ReactComponent as LogoSea } from "assets/svg/logo_sea.svg";
import { ReactComponent as LogoChina } from "assets/svg/logo_china.svg";
import { ReactComponent as LogoGrowth } from "assets/svg/logo_growth.svg";
import { ReactComponent as LogoHealthCare } from "assets/svg/logo_healthcare.svg";

const logos = {
  default: LogoDefault,
  usa: LogoUsa,
  israel: LogoIsrael,
  india: LogoSea,
  china: LogoChina,
  singapore: LogoSea,
  indonesia: LogoSea,
  healthcare: LogoHealthCare,
  growth: LogoGrowth,
};

export default function Info({ description, vertex }) {
  return (
    <StyledInfo visible={vertex}>
      <Logo vertex={vertex} />
      <Description vertex={vertex}>{description}</Description>
    </StyledInfo>
  );
}

const StyledInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 130px;
  padding: 20px;
  background-color: #007bf8;
  color: white;
  transition: height 0.3s;
  overflow: hidden;

  ${media.greaterThan("sm")`
    height:100px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0,123,248, 0.9);
  `};

  ${media.greaterThan("md")`
    width: 70%;
  `};

  ${media.greaterThan("lg")`
    width: 50%;
  `};
`;

const Description = styled.p`
  width: 50%;
  line-height: 1.1;

  ${media.greaterThan("sm")`
    width: 70%;
    padding-left: 20px;
  `};
`;

const Logo = ({ vertex }) => {
  const element = logos[vertex];

  const StyledLogo = styled(element)`
    display: block;
    width: 40%;
    height: 100%;
    padding: 20px 10px 20px 0;
    fill: white;
    border-right: 2px solid white;
    opacity: ${vertex ? 1 : 0};
    transition: 0.3s ease-in;
    transition-delay: 0.4s;

    ${media.greaterThan("sm")`
      width: 30%;
      padding: 0 20px 0 0;
      border-right: 1px solid white;
    `};
  `;

  return <StyledLogo />;
};
