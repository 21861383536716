import styled from "@emotion/styled";

import { Column } from "components/common/Grid";

import media from "utils/media";

const Title = styled(Column)`
  margin-bottom: 43px;

  ${media.greaterThan("md")`
    margin-bottom: 56px;
  `};

  h2 {
    line-height: 1;
    margin-bottom: 20px;

    ${media.greaterThan("md")`
      margin-bottom: 10px;
    `};
  }

  p {
    margin: 0;

    ${media.greaterThan("md")`
      max-width: 860px;
    `};
  }
`;

export default Title;
