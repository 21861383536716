import styled from "@emotion/styled";

import { Column } from "components/common/Grid";
import media from "utils/media";

const Content = styled(Column)`
  h2 {
    text-align: center;
    margin-bottom: 50px;

    ${media.greaterThan("sm")`
      margin-bottom: 80px;
    `};

    ${media.greaterThan("md")`
      margin-bottom: 95px;
    `};
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    ${media.greaterThan("sm")`
      flex-direction: row;
      justify-content: space-between;
    `};

    a {
      padding-bottom: 5px;

      & + a {
        margin-top: 50px;

        ${media.greaterThan("sm")`
          margin-top: 0;
        `};
      }
    }
  }
`;

export default Content;
