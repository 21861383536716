import React from "react";
import isEmpty from "lodash/isEmpty";

import styled from "@emotion/styled";

import media from "utils/media";

import Link from "components/elements/Link";

const Cookies = ({ onAccept, data }) => {
  const { message, learn_more_label, accept_label } = data || {};
  return (
    <Banner>
      {!isEmpty(message) && (
        <div dangerouslySetInnerHTML={{ __html: message }} />
      )}
      <Link type="internal" to="/legal">
        {learn_more_label}
      </Link>
      <button onClick={onAccept}>{accept_label}</button>
    </Banner>
  );
};

export default Cookies;

const Banner = styled.div`
  position: fixed;
  bottom: 10vh;
  left: 10%;
  width: 80%;
  padding: 14px 27px 18px;
  background-color: #124e91;
  color: #fff;
  letter-spacing: 0.11px;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  ${media.greaterThan("sm")`
    max-width: 490px;
    padding: 34px 47px 28px;
  `};

  div {
    margin-bottom: 25px;
    flex-basis: 100%;
  }

  button {
    width: 100%;
    max-width: 206px;
    border: solid 1px #ffffff;
    font-family: "WhitneyNew";
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    line-height: 40px;
    color: #fff;

    ${media.greaterThan("sm")`
      width: 206px;
      line-height: 54px;
    `};
  }

  a + button {
    margin-top: 10px;

    ${media.greaterThan("sm")`
      margin-top: 0;
    `};
  }

  a,
  button {
    text-transform: uppercase;
  }
`;
