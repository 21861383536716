export default {
  langLabel: "KR",
  projectCategory: {
    all: "전체",
    active: "액티브",
    biotech: "바이오테크",
    medtech: "메드테크",
    internet: "인터넷",
    technology: "기술",
    consumer: "소비자",
    healthcare: "헬스케어",
    deeptech: "딥테크",
    digitalEconomy: "신디지털 경제",
    fintech: "핀테크",
    enterprise: "기업",
    exited: "회수",
  },
};
