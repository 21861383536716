import styled from "@emotion/styled";

import media from "utils/media";

const Navigation = styled.div`
  width: 100%;
  position: sticky;
  top: 60px;
  z-index: 99;

  ${media.greaterThan("md")`
    top: 77px;
  `};

  ul {
    margin: 0;
    display: flex;

    li {
      position: relative;
      width: calc(100% / 3);

      button {
        width: 100%;
        height: 100%;
        font-family: WhitneyNew, ${({ theme }) => theme.fonts.headerFont};
        text-transform: capitalize;
        text-align: center;
        padding: 19px 0;
        background: ${({ theme }) => theme.colors.white};
        transition: background 0.2s ease-in;

        ${media.greaterThan("xs")`
          font-size: 20px;
        `};

        ${({ theme }) => media.greaterThan("md")`
          font-size: 26px;
          background: ${theme.colors.blue_v3};
        `};

        span {
          font-family: WhitneyNew, ${({ theme }) => theme.fonts.headerFont};
          font-size: 26px;
          display: none;
        }
      }

      &:after {
        content: "";
        width: 100%;
        height: 1px;
        background: ${({ theme }) => theme.colors.blue_v5};
        position: absolute;
        left: 0;
        bottom: 0;

        ${({ theme }) => media.greaterThan("md")`
          background: ${theme.colors.blue_v4};
        `};
      }

      &.active {
        button {
          font-weight: 500;

          ${({ theme }) => media.greaterThan("md")`
            background: ${theme.colors.blue_v2};
          `};

          /* span {
            ${media.greaterThan("md")`
              display: inline-block;
            `};
          } */
        }

        &:after {
          height: 2px;

          ${media.greaterThan("md")`
            height: 1px;
          `};
        }
      }
    }
  }
`;

export default Navigation;
