import React, { useRef, useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";

import useChildrensHeight from "utils/hooks/useChildrensHeight";

import Link from "components/elements/Link";
import { Wrapper, Title, List, MobileList, MobileWrapper } from "./styles";

export default function ({ title, links, fixed, mobile }) {
  const menuRef = useRef(null);
  const [open, setOpen] = useState(false);
  const offset = (links && links.length - 1) || 0;
  const menuHeight = useChildrensHeight(menuRef);

  useEffect(() => {
    if (open) {
      document.addEventListener("click", handleOpen);
    }

    return () => {
      document.removeEventListener("click", handleOpen);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function handleOpen(e) {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      return setOpen(!open);
    }
  }

  return mobile ? (
    <MobileWrapper
      className={open ? "open" : ""}
      height={menuHeight}
      offset={offset}
      onClick={handleOpen}
    >
      <Title mobile={mobile}>
        <span>{title}</span>
      </Title>
      <MobileList>
        <ul ref={menuRef}>
          {!isEmpty(links) &&
            links.map((item) => (
              <li key={item._uid}>
                <Link {...item} />
              </li>
            ))}
        </ul>
      </MobileList>
    </MobileWrapper>
  ) : (
    <Wrapper
      className={open ? "open" : ""}
      height={menuHeight}
      offset={offset}
      onClick={handleOpen}
    >
      <Title mobile={mobile}>
        <span>{title}</span>
      </Title>
      <List fixed={fixed}>
        <ul ref={menuRef}>
          {!isEmpty(links) &&
            links.map((item) => (
              <li key={item._uid}>
                <Link {...item} />
              </li>
            ))}
        </ul>
      </List>
    </Wrapper>
  );
}
