import React from "react";
import isEmpty from "lodash/isEmpty";
import styled from "@emotion/styled";

import media from "utils/media";

import Image from "components/elements/Image";
import Link from "components/elements/Link";
import { Container, Row, Column } from "components/common/Grid";

import Section from "./Section";
import Wrapper from "./Wrapper";
import Quote from "./Quote";
import Info from "./Info";

export default function SingleTestimonial({ data }) {
  const {
    heading,
    author_name,
    author_photo,
    author_role,
    author_title,
    background,
    component_name,
    link,
    quote,
  } = data || {};

  const id = !isEmpty(component_name)
    ? component_name
    : heading.toLowerCase().split(" ").join("-");

  return (
    <Section id={id}>
      <Container>
        <Row>
          <Column>
            <StyledHeading className="h1">{heading}</StyledHeading>
          </Column>
          <Wrapper bgColor={background}>
            <Info>
              {!isEmpty(author_photo.image) && (
                <Image fluid data={author_photo} />
              )}
              <div className="bio">
                <p className="name">{author_name}</p>
                <p className="title">{author_title}</p>
                <p className="role">{author_role}</p>
              </div>
            </Info>
            <Quote>
              <p className="lead">{quote}</p>
              {!isEmpty(link) && !isEmpty(link[0]) && (
                <Link {...link[0]} className="primary" />
              )}
            </Quote>
          </Wrapper>
        </Row>
      </Container>
    </Section>
  );
}

const StyledHeading = styled.h2`
  margin-bottom: 235px;
  line-height: 1;

  ${media.greaterThan("md")`
    margin-bottom: 30px;
  `};
`;
