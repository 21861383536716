import { css } from "@emotion/react";

import fonts from "assets/fonts";

import theme from "utils/theme";
import themeChina from "utils/themeChina";
import media from "utils/media";

const globalStyles = css`
  @font-face {
    font-family: "WhitneyNew";
    src: url(${fonts.WhitneyLightWoff2}) format("woff2"),
      url(${fonts.WhitneyLightWoff}) format("woff"),
      url(${fonts.WhitneyLightTtf}) format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "WhitneyNew";
    src: local("WhitneyNew"), url(${fonts.WhitneySemiboldWoff2}) format("woff2"),
      url(${fonts.WhitneySemiboldWoff}) format("woff"),
      url(${fonts.WhitneySemiboldTtf}) format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "WhitneyNew";
    src: url(${fonts.WhitneyBoldWoff2}) format("woff2"),
      url(${fonts.WhitneyBoldWoff}) format("woff"),
      url(${fonts.WhitneyBoldTtf}) format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "Gotham";
    src: local("GothamLight"), url(${fonts.GothamLightWoff2}) format("woff2"),
      url(${fonts.GothamLightWoff}) format("woff"),
      url(${fonts.GothamLightTtf}) format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Gotham";
    src: url(${fonts.GothamBoldWoff2}) format("woff2"),
      url(${fonts.GothamBoldWoff}) format("woff"),
      url(${fonts.GothamBoldTtf}) format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    color: ${theme.colors.textColor};
    font-weight: 300;
  }

  .zh {
    // font-family: ${themeChina.fonts.bodyFont};

    // h1,
    // h2,
    // h3,
    // h4,
    // h5 {
    //   font-family: ${themeChina.fonts.headerFont};
    // }
  }

  button {
    appearance: none;
    margin: 0;
    padding: 0;
    outline: 0;
    background: none;
    border: 0;
    cursor: pointer;
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  a.primary {
    display: inline-block;
    padding-bottom: 18px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 2px;
    color: ${theme.colors.textColor};
    font-family: ${theme.fonts.headerFont};
    border-bottom: 2px solid ${theme.colors.textColor};
  }

  a.button {
    display: inline-block;
    padding: 26px 18px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 2px;
    color: ${theme.colors.white};
    font-family: ${theme.fonts.headerFont};
    border: 1px solid ${theme.colors.white};
  }

  .react-responsive-modal-container {
    overflow: hidden;
  }

  .portfolio-overlay {
    opacity: 0.9;
    background-color: #007bf8;
    padding: 0;

    &.portfolio-tr-enter .portfolio-modal {
      transform: translateY(-25%);
    }

    &.portfolio-tr-enter-active .portfolio-modal {
      transform: translateY(0);
    }
  }
  .portfolio-modal {
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 47px 26px 0;
    transition: transform 0.3s ease-in-out;
    box-shadow: none;
  }

  .filter-overlay {
    transition: transform 0.3s ease-in-out;

    &.filter-tr-enter .filter-modal {
      transition: transform 0.3s ease-in-out;
      transform: translateX(-25%);
    }

    &.filter-tr-enter-active .filter-modal {
      transform: translateX(0);
      transition: transform 0.3s ease-in-out;
    }
  }

  .filter-modal {
    height: 100vh;
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    width: 100%;
    background: white;
    z-index: 200;
    transition: transform 0.3s ease-in-out;
    margin: auto;
    padding: 0;
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch !important;
    display: block;

    ${media.greaterThan("sm")`
      height: 800px;
      width: 500px;
  `};
  }
`;

export default globalStyles;
