import React from "react";
import styled from "@emotion/styled";

import media from "utils/media";

import Markdown from "components/elements/Markdown";

import { ReactComponent as ArrowIco } from "assets/svg/arrow-down-bg.svg";

export default function ScrollBtn({ label = "", onClick }) {
  return (
    <StyledScroll onClick={onClick} aria-label="Scroll to begin">
      <Markdown>{label}</Markdown>
      <div>
        <ArrowIco />
      </div>
    </StyledScroll>
  );
}

const StyledScroll = styled.button`
  display: block;
  color: ${({ theme }) => theme.colors.white};
  text-align: right;
  display: flex;
  justify-content: flex-end;
  margin: 36px 0 0 auto;

  ${media.greaterThan("md")`
    position: absolute;
    right: calc(100vw / 2 - 60rem / 2 + 26px);
    bottom: 98px;
    margin: 0;
    flex-direction: column;
  `};

  ${media.greaterThan("lg")`
    right: calc(100vw / 2 - 77rem / 2 + 26px);
  `};

  div:first-of-type {
    p {
      font-size: 26px;
      line-height: 1.5;
      letter-spacing: 0.2px;
    }
  }

  div:last-of-type {
    align-self: flex-end;
    margin-left: 21px;

    ${media.greaterThan("md")`
      margin: 28px 0 0;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    `};
  }
`;
