import styled from "@emotion/styled";

import quoteIco from "assets/svg/quote-mark.svg";

import media from "utils/media";

const Quote = styled.div`
  position: relative;
  width: 100%;
  padding: 50px 0;
  text-align: center;

  ${media.greaterThan("md")`
    width: 587px;
    padding: 75px 0 50px 30px;
  `};

  ${media.greaterThan("lg")`
    padding: 75px 0 50px 0;
  `};

  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: 2;
    width: 50px;
    height: 50px;
    background-image: url(${quoteIco});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    ${media.greaterThan("md")`
      width: 65px;
      height: 65px;
    `};
  }

  &:before {
    top: -10px;
    left: 0px;
    transform: rotate(180deg);

    ${media.greaterThan("md")`
      top: -25px;
      left: 50px;
    `};

    ${media.greaterThan("lg")`
      left: 0;
    `};
  }

  &:after {
    bottom: -10px;
    right: 0px;

    ${media.greaterThan("md")`
      bottom: -65px;
    `};
  }

  p {
    font-family: ${({ theme }) => theme.fonts.headerFont};
    font-style: italic;

    ${media.between("md", "lg")`
      font-size: 20px;
    `};
  }

  a {
    margin-top: 50px;
  }
`;

export default Quote;
