import styled from "@emotion/styled";

import { Column } from "components/common/Grid";

import media from "utils/media";

const Content = styled(Column)`
  margin-bottom: ${({ vertical }) => (vertical ? "0" : "57px")};

  ${media.greaterThan("sm")`
    margin-bottom: 0;
    flex: 1;
  `};

  p {
    margin-bottom: 26px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  h3 {
    font-family: ${({ theme }) => theme.fonts.bodyFont};
    font-weight: bold;
    line-height: 1;
    margin-bottom: 25px;
  }

  p + h3 {
    margin-top: 40px;
  }

  .download {
    margin-top: 65px;

    ${media.greaterThan("sm")`
      margin-top: 40px;
    `};
  }
`;

export default Content;
