import styled from "@emotion/styled";

import media from "utils/media";

const Section = styled.section`
  padding-top: 50px;
  padding-bottom: 50px;

  ${media.greaterThan("md")`
    padding-top: 80px;
    padding-bottom: 80px;
  `};

  & + & {
    padding-top: 0;
  }
`;

export default Section;
